import { CloseIcon, Dialog, Loader, Text } from "@fluentui/react-northstar";
import { Approval } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import collectionPlaceholderImage from "../../Assets/images/svg/collectionPlaceholderImage.svg";
import noPermission from "../../Assets/images/svg/no-permission.svg";
import userAvatar from "../../Assets/images/svg/userAvatar.svg";
import unpublished_icon from "../../Assets/images/unpublished_icon.png";
import verify from "../../Assets/images/verify.png";
import ConfirmDialog from "../Assignment/Components/ConfirmDialog/ConfirmDialog";
import FilterCollection from "./FilterCollection";
import LibraryCarousel from "./LibraryCarousel";
import { CollectionImagePreview } from "./collectionImagePreview";
import "./collections.css";
import RejectCollectionDialog from "./rejectCollectionDialog";
import { useFilter } from "./contexts";

const END_POINT = process.env.REACT_APP_EP_URL;

export const Collection = (props) => {
  const [collection, setCollection] = useState([]);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState({});
  const [openRejectCollectionDialog, setOpenRejectCollectionDialog] =
    useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [previewCollectionImageDialog, setPreviewCollectionImageDialog] =
    useState(false);
  const [currentLibraryId, setCurrentLibraryId] = useState("");
  const [previewCollectionImage, setPreviewCollectionImage] = useState("");

  const [rejectCollection, setRejectCollection] = useState({
    id: "",
    reason: "",
  });

  const [conformationModal, setConformationModal] = useState(false);
  const [id, setId] = useState(null);

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const { filterDataWithKeys } = useFilter();

  //creating a ref instead of state because it is not update the data or not able to pass the data to the child component

  const filterDataRef = useRef(filterDataWithKeys);

  // Update the ref whenever filterDataWithKeys changes
  useEffect(() => {
    filterDataRef.current = filterDataWithKeys;
  }, [filterDataWithKeys]);

  const isFilterApply = useRef(false);
  const searchRef = useRef("");

  const sortRef = useRef(1);
  const containerRef = useRef(null);
  const limit = 10;

  useEffect(() => {
    setHasMore(totalCount > collection?.length);
  }, [collection, totalCount]);

  useEffect(() => {
    var svgs = document.getElementsByTagName("svg");

    // Iterate through the SVG elements and remove the height attribute
    for (var i = 0; i < svgs.length; i++) {
      svgs[i].removeAttribute("height");
    }
  }, [previewCollectionImageDialog]);

  const getCollection = (slug) => {
    axios
      .get(
        `${END_POINT}/api/library/status-wise-collection${slug}&status=ALL&limit=${limit}&offset=${0}&sort=${
          sortRef.current
        }`
      )
      .then((res) => {
        setCollection(res.data.result);
        setTotalCount(res.data.totalCount);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err?.response?.data?.message);
        console.error(err, err.response);
      });
  };

  useEffect(() => {
    const user = props.user;
    if (user && user.slug) {
      getCollection(user.slug);
    }
  }, [props.user?.slug]);

  const handleApproveLibrary = async (id) => {
    setId(id);
    setConformationModal(true);
  };

  const approveLibrary = async () => {
    setConformationModal(false);
    setLoading((oldState) => ({ ...oldState, [id]: true }));
    try {
      const response = await fetch(
        `${END_POINT}/api/library/approve-collection/${id}`,
        {
          method: "PUT",
        }
      );
      const data = await response.json();
      //change status of collection

      setCollection((oldState) => {
        const index = oldState.findIndex((item) => item.id === id);
        oldState[index].status = "Published";
        return [...oldState];
      });
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } catch (error) {
      console.log(error);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    }
  };

  const handleUnPublishLibrary = async (id) => {
    setLoading((oldState) => ({ ...oldState, [id]: true }));
    try {
      const response = await fetch(
        `${END_POINT}/api/library/unpublish-collection/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setCollection((oldState) => {
        const index = oldState.findIndex((item) => item.id === id);
        oldState[index].status = "Unpublished";
        return [...oldState];
      });
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } catch (error) {
      console.log(error);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    }
  };

  const handleClose = () => {
    setOpenRejectCollectionDialog(false);
  };

  const reasonInputChangeHandler = (reasonText, id) => {
    setRejectCollection({ ...rejectCollection, reason: reasonText });
  };

  const handleReject = async (id) => {
    try {
      const response = await fetch(
        `${END_POINT}/api/library/reject-collection?id=${rejectCollection.id}&rejectReason=${rejectCollection.reason}`,
        {
          method: "PUT",
        }
      );
      setCollection((oldState) => {
        const index = oldState.findIndex(
          (item) => item.id === rejectCollection.id
        );
        oldState[index].status = "Rejected";
        return [...oldState];
      });
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } catch (error) {
      console.log(error);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } finally {
      setOpenRejectCollectionDialog(false);
    }
  };

  const rejectHandlerLibrary = async (id) => {
    setRejectCollection({ ...rejectCollection, id });
    setOpenRejectCollectionDialog(true);
  };

  const fetchMoreCollections = useCallback(
    async (page = 0) => {
      try {
        const currentData = filterDataRef.current;

        const sort = sortRef.current;
        const query = isFilterApply.current
          ? `&age=${currentData.age}&author=${currentData.author}&tags=${currentData.tags}`
          : "";

        const response = await axios.get(
          `${END_POINT}/api/library/status-wise-collection${props.user.slug}&status=ALL&limit=${limit}&offset=${page}&search=${searchRef.current}&sort=${sort}${query}`
        );
        setPage(page);
        if (page === 0 && containerRef.current) {
          containerRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
        setCollection((prev) => {
          if (page === 0) return response.data.result;
          return [...prev, ...response.data.result];
        });
        setLoad(false);
        setTotalCount(response.data.totalCount);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    },
    [sortRef, filterDataRef, isFilterApply, searchRef]
  );

  const debouncedSearch = useCallback(
    _.debounce(() => {
      fetchMoreCollections();
    }, 500),
    []
  );

  return load ? (
    <Loader size="medium" style={{ paddingTop: "50px" }} />
  ) : (
    <div>
      {isModelOpen && (
        <ConfirmDialog
          isConfirmDelete={isModelOpen}
          setisConfirmDelete={setIsModelOpen}
          cancelButton="cancel"
          confirmButton="unpublish"
          content="Are you sure you want to unpublish library?"
          header="Unpublish library"
          onConfirm={() => {
            setIsModelOpen(false);
            handleUnPublishLibrary(currentLibraryId);
          }}
          onCancel={() => {
            setIsModelOpen(false);
          }}
        />
      )}

      {conformationModal && (
        <Dialog
          headerAction={{
            icon: <CloseIcon />,
            title: "Close",
            onClick: () => {
              setConformationModal(false);
            },
          }}
          header={<Text weight={"medium"}>Are you sure?</Text>}
          content={"Are you sure you want to perform this action?"}
          open={conformationModal}
          onConfirm={approveLibrary}
          confirmButton={"Okay"}
        />
      )}

      {/* Preview collection image dialog */}
      <Dialog
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setPreviewCollectionImageDialog(false);
          },
        }}
        header={<Text weight={"regular"}>Preview collection Image</Text>}
        content={
          <div style={{ width: "inherit" }}>
            {Array.isArray(previewCollectionImage) ? (
              previewCollectionImage.length > 0 &&
              previewCollectionImage.length > 1 ? (
                <LibraryCarousel
                  images={previewCollectionImage}
                  index={1}
                  name="dialog"
                />
              ) : (
                <CollectionImagePreview
                  previewURL={previewCollectionImage[0]}
                  index={1}
                />
              )
            ) : (
              <CollectionImagePreview
                previewURL={previewCollectionImage}
                index={1}
              />
            )}
          </div>
        }
        id="preview-dialog"
        className="preview-dialog"
        open={previewCollectionImageDialog}
      />

      <FilterCollection
        setSearch={(search) => {
          searchRef.current = search;
          debouncedSearch();
        }}
        isFilterApply={isFilterApply.current}
        setFilterApply={(val) => {
          isFilterApply.current = val;
        }}
        onFetchCollections={fetchMoreCollections}
        sort={sortRef.current}
        setSort={(sortIndex) => {
          sortRef.current = sortIndex;
          fetchMoreCollections();
        }}
        slug={props.user.slug}
      />

      {collection.length > 0 ? (
        <div
          className="btsp collection-main"
          style={{
            height: props.isTabletOrMobileView
              ? "calc(100vh - 190px)"
              : "calc(100vh - 350px)",
            overflowX: "auto",
          }}
          ref={containerRef}
        >
          <InfiniteScroll
            pageStart={0}
            hasMore={hasMore}
            initialLoad={false}
            threshold={250}
            loadMore={async () => {
              if (!hasMore) return;
              await fetchMoreCollections(page + 1);
            }}
            useWindow={false}
            getScrollParent={() => document.querySelector(".collection-main")}
            loader={<Loader key={0} size="small" />}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(245px, 1fr))",
              alignItems: "start",
              gap: 16,
            }}
          >
            {collection?.map((collection, index) => {
              return (
                <div
                  className="collection-card p-4"
                  style={{ maxWidth: "300px", width: "100%", height: "460px" }}
                >
                  <div className="header d-flex justify-content-between mb-2 gap-2">
                    <h5 className="collection-title fs-5 d-inline-block fw-bolder mb-0">
                      {collection.name}
                    </h5>
                    {(collection.status === "Published" ||
                      collection.status === "Unpublished") && (
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            collection.status === "Published"
                              ? verify
                              : unpublished_icon
                          }
                          alt=""
                          style={{
                            height: 18,
                            width: 18,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <div
                      className="img-div"
                      onClick={() => {
                        setPreviewCollectionImageDialog(true);
                        setPreviewCollectionImage(
                          collection.preview
                            ? collection.preview
                            : collectionPlaceholderImage
                        );
                      }}
                      style={{ width: "inherit" }}
                    >
                      {Array.isArray(collection.preview) ? (
                        collection.preview.length > 0 &&
                        collection.preview.length > 1 ? (
                          <LibraryCarousel
                            images={collection.preview}
                            index={index}
                            name="card"
                          />
                        ) : (
                          <CollectionImagePreview
                            previewURL={collection.preview[0]}
                            index={index}
                          />
                        )
                      ) : (
                        <CollectionImagePreview
                          previewURL={collection.preview}
                          index={index}
                        />
                      )}
                    </div>
                    <div className="collection-info d-flex mt-2">
                      <div className="user-info d-flex">
                        <div className="user-image">
                          <img
                            src={userAvatar}
                            className="w-100 h-auto"
                            alt="user-image"
                          />
                        </div>
                        <div className="username ms-2">
                          {collection.author?.name}
                        </div>
                      </div>
                      {/* <div className="date text-nowrap">{collection.created}</div> */}
                      <div className="date text-left">
                        {moment(collection.createdAt).format("DD-MM-YY")}
                      </div>
                    </div>
                    <div className="mt-3 text-left collection-name">
                      {collection.description.length > 65
                        ? collection.description.substr(0, 65) + "..."
                        : collection.description}
                    </div>
                  </div>

                  <div className="footer">
                    <div className="btns d-flex gap-2 justify-content-end">
                      <LoadingButton
                        loading={loading[collection.id] || false}
                        sx={{
                          backgroundColor:
                            collection.status === "Published" ||
                            collection.status === "Unpublished"
                              ? "#F25767"
                              : collection.status === "Rejected"
                              ? "#F25767"
                              : "#585A96",
                        }}
                        loadingPosition="start"
                        startIcon={
                          collection.status === "Published" ||
                          collection.status === "Unpublished" ? (
                            <img
                              src={noPermission}
                              style={{ marginRight: "5px" }}
                            />
                          ) : (
                            <Approval />
                          )
                        }
                        variant="contained"
                        onClick={() => {
                          collection.status === "Under review" &&
                            handleApproveLibrary(collection.id);

                          if (collection.status === "Published") {
                            setCurrentLibraryId(collection.id);
                            setIsModelOpen(true);
                          }
                        }}
                      >
                        {collection.status === "Under review"
                          ? "Approve"
                          : collection.status === "Published"
                          ? "UnPublish"
                          : collection.status}
                      </LoadingButton>
                      {/* <a
                  className="addToCollection bg-white px-2 py-1 cursor-pointer text-decoration-none mx-2"
                  href={`https://wb-lib-approval-view.azurewebsites.net?element=${collection.source}`}
                  target="_blank"
                >
                  Link
                </a> */}

                      {collection.status === "Under review" && (
                        <button
                          className="addToCollection bg-white px-2 py-1 ml-2 cursor-pointer text-decoration-none"
                          onClick={() => rejectHandlerLibrary(collection.id)}
                        >
                          Reject
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
          <RejectCollectionDialog
            open={openRejectCollectionDialog}
            handleClose={handleClose}
            reasonInputChangeHandler={reasonInputChangeHandler}
            handleReject={handleReject}
          />
        </div>
      ) : (
        <p
          style={{
            justifyContent: "center",
            display: "flex",
            margin: "20px",
            fontSize: "17px",
          }}
        >
          No Collection Available
        </p>
      )}
    </div>
  );
};
