import {
  Button,
  CloseIcon,
  Dialog,
  Flex,
  Text,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import React from "react";
import { useLearningFromWebWithAi } from "../../../../../context/LearningFromWebWithAiContext";
import "./DeleteConfirmDialog.scss";

const DeleteConfirmDialog = () => {
  const { deletingChatId, setDeletingChatId, deleteChat, isDeletingChat } =
    useLearningFromWebWithAi();
  return (
    <Dialog
      className="smallDlg confirm-delete-chat-dialog"
      open={!!deletingChatId}
      content={
        <Flex column>
          <Text content="Are you sure you want to delete this chat?" />
          <Flex hAlign="end" gap="gap.smaller">
            <Button
              text
              primary
              disabled={isDeletingChat}
              content="cancel"
              onClick={() => setDeletingChatId("")}
            />
            <Button
              icon={<TrashCanIcon />}
              primary
              content="Delete"
              disabled={isDeletingChat}
              loading={isDeletingChat}
              onClick={() => {
                deleteChat(deletingChatId);
              }}
            />
          </Flex>
        </Flex>
      }
      header={
        <Flex gap="gap.small" vAlign="center" hAlign="center" fill>
          <TrashCanIcon outline color="red" />
          <Text content="Delete" weight="semibold" />
        </Flex>
      }
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: () => {
          setDeletingChatId("");
        },
        disabled: isDeletingChat,
      }}
    />
  );
};

export default DeleteConfirmDialog;
