import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  IconButton,
  Button,
  Divider,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SubChannelPasswordsModal } from "../SubChannelPasswords";
import { ChannelPasswordModal } from "../ChannelPassword/ChannelPasswordModal";
import { useChannelModal } from "../../../context/ChannelModalContext";
import { createChannel } from "../../../Assets/api/api";

export const OnDemandChannelsModal = ({ user }) => {
  const initialState = {
    channelName: "",
    isPublic: true,
    subChannelsCount: 0,
    subChannelPrefix: "",
    subChannelsPublic: true,
    errors: {},
  };

  const [channelName, setChannelName] = useState(initialState.channelName);
  const [isPublic, setIsPublic] = useState(initialState.isPublic);
  const [subChannelsCount, setSubChannelsCount] = useState(
    initialState.subChannelsCount
  );
  const [subChannels, setSubChannels] = useState([]);
  const [subChannelPrefix, setSubChannelPrefix] = useState(
    initialState.subChannelPrefix
  );
  const [subChannelsPublic, setSubChannelsPublic] = useState(
    initialState.subChannelsPublic
  );
  const [errors, setErrors] = useState(initialState.errors);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [password, setPassword] = useState("");

  const { modals, closeModal, switchModals, setChannelData } =
    useChannelModal();

  const resetState = () => {
    setChannelName(initialState.channelName);
    setIsPublic(initialState.isPublic);
    setSubChannelsCount(initialState.subChannelsCount);
    setSubChannelPrefix(initialState.subChannelPrefix);
    setSubChannelsPublic(initialState.subChannelsPublic);
    setErrors(initialState.errors);
    setLoading(false);
    setApiError("");
  };

  const handleClose = () => {
    closeModal("onDemand");
    resetState();
  };

  const validate = () => {
    let tempErrors = {};
    if (!channelName || channelName.length < 3)
      tempErrors.channelName =
        "Channel Name must be at least 3 characters long.";
    if (subChannelsCount >= 1 && !subChannelPrefix.trim())
      tempErrors.subChannelPrefix = "Sub-channel prefix is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleGenerate = async () => {
    console.log(subChannelsPublic, subChannels);
    if (!validate()) return;
    const payload = {
      channel: {
        name: channelName,
        privacy: isPublic ? "public" : "private",
        totalSubChannels: subChannelsCount,
        ...(isPublic ? {} : { password: password }), // Conditionally add password only if isPublic is false
      },
      subChannels: subChannels.map((ch) => ({
        name: ch.name,
        privacy: subChannelsPublic ? "public" : "private",
        ...(subChannelsPublic ? {} : { password: ch.password }), // Conditionally add password only if subChannelsPublic is false
      })),
    };
    try {
      setLoading(true);
      setApiError("");

      // API call to create channel
      await createChannel(user.slug || "ieidbhnzsflrqtef", payload);

      setChannelData([channelName, ...subChannels.map((ch) => ch.name)]);
      switchModals("onDemand", "directoryChannel");
    } catch (error) {
      console.error("API Error:", error);
      setApiError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={modals.onDemand}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "0.75rem",
            maxWidth: "28rem",
            width: "100%",
          },
        }}
      >
        <DialogTitle sx={{ borderBottom: "2px solid #494B83" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">On-demand Channels</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ "&.MuiDialogContent-root": { padding: 3 } }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <TextField
              label="Channel Name"
              value={channelName}
              size="small"
              onChange={(e) => setChannelName(e.target.value)}
              error={!!errors.channelName}
              helperText={errors.channelName}
              fullWidth
            />

            <FormControl>
              <FormLabel>Privacy</FormLabel>
              <RadioGroup
                value={isPublic ? "public" : "private"}
                onClick={(e) => {
                  setIsPublic(e.target.value === "public");
                  if (e.target.value === "private") {
                    if (channelName) {
                      switchModals("onDemand", "channelPassword");
                    } else {
                      setErrors({ channelName: "Channel Name is required." });
                    }
                  }
                }}
                sx={{ flexDirection: "row", gap: 2 }}
              >
                <FormControlLabel
                  value="public"
                  control={<Radio />}
                  label="Public"
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Private"
                />
              </RadioGroup>
              <Typography variant="caption" color="text.secondary">
                Private channels must have a password.
              </Typography>
            </FormControl>

            <Divider sx={{ borderColor: "#494B83" }} />
            <Typography variant="h6">Sub-channels</Typography>

            <TextField
              label="Number of Sub-channels (Up to 10)"
              type="number"
              value={subChannelsCount}
              size="small"
              onChange={(e) =>
                setSubChannelsCount(
                  Math.max(0, Math.min(10, parseInt(e.target.value) || 0))
                )
              }
              fullWidth
            />

            <TextField
              label="Prefix (e.g. Team)"
              value={subChannelPrefix}
              onChange={(e) => setSubChannelPrefix(e.target.value)}
              error={!!errors.subChannelPrefix}
              helperText={errors.subChannelPrefix}
              fullWidth
              size="small"
            />

            <FormControl>
              <FormLabel>Sub-channels Privacy</FormLabel>
              <RadioGroup
                value={subChannelsPublic ? "public" : "private"}
                onClick={(e) => {
                  setSubChannelsPublic(e.target.value === "public");
                  if (e.target.value === "private") {
                    if (subChannelsCount > 0) {
                      switchModals("onDemand", "subChannelPassword");
                      // setShowSubChannelPasswords(true);
                    }
                  } else {
                    setSubChannels((prev) =>
                      prev.map((subChannel) => ({
                        ...subChannel,
                        password: "",
                      }))
                    );
                  }
                }}
                sx={{ flexDirection: "row", gap: 2 }}
              >
                <FormControlLabel
                  value="public"
                  control={<Radio />}
                  label="Public"
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Private"
                />
              </RadioGroup>
            </FormControl>

            {apiError && (
              <Typography color="error" variant="body2">
                {apiError}
              </Typography>
            )}
          </Box>
        </DialogContent>

        <Box
          sx={{
            display: "flex",
            borderTop: "2px solid #494B83",
            flexDirection: "column",
            gap: 1,
            p: 3,
          }}
        >
          <Button
            onClick={handleGenerate}
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{
              backgroundColor: "#494B83",
              color: "white",
              "&:hover": { backgroundColor: "#3a3c69" },
            }}
          >
            {loading ? "Generating..." : "Generate"}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "#494B83",
              color: "white",
              "&:hover": { backgroundColor: "#3a3c69" },
            }}
          >
            Close
          </Button>
        </Box>
      </Dialog>

      <SubChannelPasswordsModal
        subChannelsCount={subChannelsCount}
        subChannelPrefix={subChannelPrefix}
        subChannels={subChannels}
        setSubChannels={setSubChannels}
      />
      <ChannelPasswordModal
        channelName={channelName}
        password={password}
        setPassword={setPassword}
      />
    </>
  );
};
