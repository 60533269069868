import { Button, Flex, Text, TextArea } from '@fluentui/react-northstar'
import { Tabs } from 'antd';
import React from 'react'

function Help() {
    return (
        <Flex column gap="gap.medium" >
            <h1
                style={{ color: "#6264a7", fontWeight: "bold", margin: 0 }}
            >
                Get Help To Find Best Tutor For You
            </h1>

            <Tabs
                defaultActiveKey="1"
                style={{ marginTop: "20px", backgroundColor: "white", padding: "20px", borderRadius: "10px" }}
                type="card"
                items={[
                    {
                        label: `Get Recommendation`,
                        key: "1",
                        children: <Flex column gap="gap.medium">
                            <TextArea
                                fluid
                                placeholder="Enter topic you want to learn"
                                aria-label="Meeting summary text area"
                                rows={12}

                            />
                            <Text 
                                size="small"
                                // color={summary.length >= MAX_CHARACTERS ? "red" : "gray"}
                                align="end"
                            >
                                0/500
                            </Text>
                            <Button content="Get Recommendation" primary style={{ width: "fit-content", alignSelf: "flex-end" }} />
                        </Flex>,

                    },

                    {
                        label: `History`,
                        key: "2",
                        children: `Content of tab 2`,
                    }


                ]}
            />
        </Flex>


    )

}


export default Help