import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TextField,
  Typography,
  FormHelperText,
  InputAdornment,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useChannelModal } from "../../../context/ChannelModalContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const ChannelPasswordModal = ({
  channelName,
  password,
  setPassword,
}) => {
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const validatePassword = (password) => {
    if (password.length < 6) return "Password must be at least 6 characters";
    if (!/[A-Z]/.test(password))
      return "Password must contain at least one uppercase letter";
    if (!/[0-9]/.test(password))
      return "Password must contain at least one number";
    return "";
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setError(validatePassword(value));
  };

  const handleSave = () => {
    const validationError = validatePassword(password);
    if (validationError) {
      setError(validationError);
      return;
    }
    setSaved(true);
    setError("");
  };

  const handleDownloadExcel = () => {
    if (saved) {
      const csvContent = `Channel Name,Password\n${channelName},${password}`;
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "channel_password.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };
  const { modals, closeModal, switchModals } = useChannelModal();
  const handleClose = () => {
    closeModal("channelPassword");
    switchModals("channelPassword", "onDemand");
    setSaved(false);
  };
  return (
    <Dialog
      open={modals.channelPassword}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "0.75rem",
          maxWidth: "32rem",
          width: "100%",
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: "2px solid #494B83",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Channel Password</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "1.5rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Channel Name</TableCell>
              <TableCell>Password</TableCell>
              <TableCell align="right">Save</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body2">{channelName}</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  placeholder="Set Password"
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={Boolean(error)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#494B83",
                        borderWidth: "2px",
                      },
                      "&:hover fieldset": { borderColor: "#494B83" },
                      "&.Mui-focused fieldset": { borderColor: "#494B83" },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
              </TableCell>
              <TableCell align="right">
                <Button
                  onClick={handleSave}
                  disabled={saved || !password || Boolean(error)}
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#494B83",
                    color: "white !important",
                    "&:hover": { backgroundColor: "#3a3c69" },
                    "&.Mui-disabled": {
                      backgroundColor: "#494B83",
                      opacity: 0.5,
                    },
                  }}
                >
                  {saved ? "Saved" : "Save"}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>

      <Box
        sx={{
          display: "flex",
          borderTop: "2px solid #494B83",
          flexDirection: "column",
          gap: 1,
          p: 3,
        }}
      >
        <Button
          onClick={handleDownloadExcel}
          fullWidth
          variant="contained"
          disabled={!saved}
          sx={{
            backgroundColor: "#494B83",
            color: "white",
            "&:hover": { backgroundColor: "#3a3c69" },
          }}
        >
          Download Excel List
        </Button>
        <Button
          onClick={handleClose}
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "#494B83",
            color: "white",
            mx: 0,
            "&:hover": { backgroundColor: "#3a3c69" },
          }}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};
