import axios from "axios";
import { createContext, useContext, useState } from "react";
import i18n from "../helper/i18n";
import { useLearningFromWebWithAi } from "./LearningFromWebWithAiContext";

const languageMappings = {
  en: "English",
  "en-GB": "English (UK)",
  "en-US": "English (US)",
  es: "Spanish",
  pt: "Portuguese",
  de: "German",
  fr: "French",
  hi: "Hindi",
  // Add other languages as needed
};

const TutorRecommendations = createContext();
export const useTutorRecommendations = () => {
  return useContext(TutorRecommendations);
};

export const TutorRecommendationsProvider = (props) => {
  // User's language
  const language = languageMappings?.[i18n.language] || "English";

  const { setIsOpenLearningFromWebDialog, getLearningResources } =
    useLearningFromWebWithAi();

  const [isOpenGetRecommendationsDialog, setIsOpenGetRecommendationsDialog] =
    useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [selectedRecommendation, setSelectedRecommendation] = useState(
    JSON.parse(localStorage.getItem("selectedRecommendation") || "[]")
  );
  const [isLoadingRecommendations, setIsLoadingRecommendations] =
    useState(false);
  const [
    isLoadingGenerateRecommendationByTopic,
    setIsLoadingGenerateRecommendationByTopic,
  ] = useState(false);
  const [topic, setTopic] = useState("");

  // generate recommendation by topic
  const generateRecommendationByTopic = async (topic) => {
    try {
      setIsLoadingGenerateRecommendationByTopic(true);
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/tutor/generate-recommendation-by-topic${props.user.slug}&topic=${topic}&language=${language}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingGenerateRecommendationByTopic(false);
      if (res.data?.success) {
        setTopic("");
        const recommendations = res.data?.recommendation?.recommendations || {};
        if (recommendations && recommendations?.recommendedTutors?.length > 0) {
          setSelectedRecommendation(recommendations.recommendedTutors);
          localStorage.setItem(
            "selectedRecommendation",
            JSON.stringify(recommendations.recommendedTutors)
          );
          setIsOpenGetRecommendationsDialog(false);
        } else {
          setIsOpenGetRecommendationsDialog(false); // Close GetRecommendations Dialog
          // Open learning from web dialog if we not found any recommended tutors
          setIsOpenLearningFromWebDialog(true);
          getLearningResources(topic);
        }
      }
    } catch (error) {
      setIsLoadingGenerateRecommendationByTopic(false);
      console.log(error, "error");
    }
  };

  // get all recommendations by user
  const getRecommendations = async () => {
    try {
      setIsLoadingRecommendations(true);

      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/tutor/get-all-recommendations${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingRecommendations(false);
      if (res.data?.success) {
        setRecommendations(res.data.recommendations);
      }
    } catch (error) {
      setIsLoadingRecommendations(false);
      console.log(error, "error");
    }
  };

  // get recommendation by id
  const getRecommendationById = async (id) => {
    try {
      setIsLoadingRecommendations(true);
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/tutor/get-recommendation-by-id/${id}${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingRecommendations(false);
      if (res.data?.success) {
        setRecommendations(res.data.recommendations);
      }
    } catch (error) {
      setIsLoadingRecommendations(false);
      console.log(error, "error");
    }
  };

  // get recommendation by tutor email
  const getRecommendationByTutorEmail = (tutorEmail) => {
    try {
      const currentRecommendations = selectedRecommendation?.length
        ? selectedRecommendation
        : [];
      const recommendation = currentRecommendations?.find(
        (recommendation) => recommendation.email === tutorEmail
      );
      return recommendation ? recommendation : null;
    } catch (error) {
      console.log(error, "error");
      return null;
    }
  };

  return (
    <TutorRecommendations.Provider
      value={{
        isOpenGetRecommendationsDialog,
        setIsOpenGetRecommendationsDialog,
        getRecommendations,
        recommendations,
        isLoadingRecommendations,
        topic,
        setTopic,
        generateRecommendationByTopic,
        isLoadingGenerateRecommendationByTopic,
        getRecommendationById,
        selectedRecommendation,
        setSelectedRecommendation,
        getRecommendationByTutorEmail,
      }}
    >
      {props.children}
    </TutorRecommendations.Provider>
  );
};
