import React, { useState, useRef, useEffect } from "react";
import {
  Provider,
  Flex,
  Text,
  Button,
  Avatar,
  Loader,
  Box,
  ChatMessage,
  TextArea,
  Label,
  Menu,
  Animation,
  Input,
  Divider,
  Header,
  Card,
  Image,
  Grid,
  Tooltip,
  List,
  MenuButton,
} from "@fluentui/react-northstar";
import {
  ChatIcon,
  ClockIcon,
  TrashCanIcon,
  ChevronStartIcon,
  ChevronEndIcon,
  AddIcon,
  SearchIcon,
  MoreIcon,
  PresenceAvailableIcon,
  PlayIcon,
  LocationIcon,
  MenuIcon,
  EditIcon,
  RobotIcon,
} from "@fluentui/react-icons-northstar";
import { useLearningFromWebWithAi } from "../../../../context/LearningFromWebWithAiContext";
import moment from "moment";
import defaultBookThumbnail from "../../../../Assets/images/book-thumbnail.png";
import userSvg from "../../../../Assets/images/svg/user.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SUBJECT_CATEGORIES = [
  { id: "math", name: "Mathematics", icon: "📐" },
  { id: "science", name: "Science", icon: "🔬" },
  { id: "history", name: "History", icon: "📚" },
  { id: "literature", name: "Literature", icon: "📖" },
  { id: "languages", name: "Languages", icon: "🌍" },
];

const SAMPLE_QUESTIONS = [
  {
    subject: "math",
    questions: [
      "Can you explain the quadratic formula step by step?",
      "Help me understand probability concepts",
      "How do I solve systems of equations?",
    ],
  },
  {
    subject: "science",
    questions: [
      "Explain photosynthesis in simple terms",
      "How does gravity work?",
      "What's the difference between mitosis and meiosis?",
    ],
  },
  {
    subject: "history",
    questions: [
      "What were the main causes of World War I?",
      "How did the Renaissance influence modern Western culture?",
      "What impact did the Cold War have on global politics?",
    ],
  },
  {
    subject: "literature",
    questions: [
      "What are the key themes in Shakespeare's plays?",
      "How does literary symbolism contribute to deeper meaning in texts?",
      "Can you compare modernist and postmodernist literature?",
    ],
  },
  {
    subject: "languages",
    questions: [
      "What are effective methods for learning a new language?",
      "How do different languages handle verb conjugation?",
      "What role does cultural context play in language evolution?",
    ],
  },
];

const EmptyState = () => {
  const { getLearningResources } = useLearningFromWebWithAi();

  const [activeSubject, setActiveSubject] = useState("math");

  return (
    <Flex
      column
      hAlign="center"
      vAlign="center"
      gap="gap.medium"
      styles={{ height: "100%", padding: "2rem", textAlign: "center" }}
    >
      <Animation name="pulse">
        <Text size="larger">🎓</Text>
      </Animation>
      <Text size="large" weight="bold">
        Your Personal Learning Assistant
      </Text>
      <Text size="medium" color="grey">
        Ask any question about your studies - I'm here to help you understand!
      </Text>

      {/* Subject Selection */}
      <Flex
        gap="gap.small"
        wrap={true}
        hAlign="center"
        styles={{ marginTop: "1rem", maxWidth: "600px" }}
      >
        {SUBJECT_CATEGORIES.map((subject) => (
          <Button
            key={subject.id}
            text
            content={`${subject.icon} ${subject.name}`}
            onClick={() => setActiveSubject(subject.id)}
            primary={activeSubject === subject.id}
          />
        ))}
      </Flex>

      <Box styles={{ marginTop: "1.5rem", width: "100%", maxWidth: "600px" }}>
        <Text size="small" weight="semibold" color="grey">
          Popular questions in{" "}
          {SUBJECT_CATEGORIES.find((s) => s.id === activeSubject)?.name}:
        </Text>
        <Flex
          column
          gap="gap.smaller"
          hAlign="center"
          styles={{ marginTop: "0.5rem" }}
        >
          {SAMPLE_QUESTIONS.find(
            (s) => s.subject === activeSubject
          )?.questions.map((question, index) => (
            <Tooltip
              key={`sample-question-${index}`}
              content={question}
              trigger={
                <Button
                  text
                  content={question}
                  styles={{
                    textAlign: "center",
                    padding: "8px",
                    ":hover": {
                      backgroundColor: "#f0f7ff",
                    },
                  }}
                  onClick={() => {
                    getLearningResources(question);
                  }}
                />
              }
              zIndex={5}
              position="after"
              mountNode={
                document.getElementsByClassName("learning-from-web-dialog")[0]
              }
            />
          ))}
        </Flex>
      </Box>

      <Box styles={{ marginTop: "2rem" }}>
        <Text size="small" color="grey">
          Study Tips:
        </Text>
        <Flex column gap="gap.smaller" styles={{ marginTop: "0.5rem" }}>
          <Text size="small">• Ask for step-by-step explanations</Text>
          <Text size="small">• Request examples to understand better</Text>
          <Text size="small">• Get help with practice problems</Text>
        </Flex>
      </Box>
    </Flex>
  );
};

function removeWWW(url) {
  return url.replace(/^www\./, "");
}

function extracCharacters(text, char) {
  return text.slice(0, char) + " ...";
}

const RecommendedTutors = ({ tutors }) => {
  const history = useHistory();

  const [showAllTutors, setShowAllTutors] = useState(false);

  const displayedTutors = showAllTutors ? tutors : tutors.slice(0, 3);
  const remainingTutorsCount = tutors.length - displayedTutors.length;
  const remainingTutors = tutors.slice(3);

  const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN.replace(/"/g, "");

  const recommendedTutorTooltipCard = (tutor) => {
    return (
      <Flex column vAlign="center" gap="gap.smaller">
        <Flex gap="gap.smaller" vAlign="center">
          <Avatar
            styles={{ flexShrink: 0 }}
            image={tutor?.image ? tutor?.image + sasToken : userSvg}
            size="medium"
          />

          <Text
            styles={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            content={tutor.name}
            weight="semibold"
          />
        </Flex>
        <Text content={tutor.reason} />
      </Flex>
    );
  };

  const redirectOnProfile = (tutor) => {
    history.push(`/tutor/${tutor.email}/${tutor.role}`);
  };

  return (
    <Box>
      <Header as="h3" content="Recommended Tutors" />
      <Flex
        gap="gap.smaller"
        styles={{
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            height: "10px !important ",
          },
        }}
      >
        {displayedTutors.map((tutor, i) => (
          <Tooltip
            key={i}
            content={recommendedTutorTooltipCard(tutor)}
            styles={{ width: "fit-content" }}
            trigger={
              <Card
                fluid
                styles={{
                  padding: "1rem",
                  transition: "transform 0.2s",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
                  cursor: "pointer",
                  ":hover": { transform: "translateY(-4px)" },
                  height: "auto",
                  flex: 1,
                }}
                onClick={() => {
                  redirectOnProfile(tutor);
                }}
              >
                <Card.Header>
                  <Flex column vAlign="center" gap="gap.smaller">
                    <Flex gap="gap.smaller" vAlign="center">
                      <Avatar
                        styles={{ flexShrink: 0 }}
                        image={tutor?.image ? tutor?.image + sasToken : userSvg}
                        size="medium"
                      />
                      <Text
                        styles={{
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        content={tutor.name}
                        weight="semibold"
                      />
                    </Flex>
                    <Text
                      content={tutor.reason}
                      color="gray"
                      styles={{
                        "-webkit-line-clamp": 2,
                        overflow: "hidden",
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        textAlign: "start",
                      }}
                    />
                  </Flex>
                </Card.Header>
              </Card>
            }
            zIndex={999}
            mountNode={
              document.getElementsByClassName("learning-from-web-dialog")[0]
            }
          />
        ))}
        {remainingTutorsCount > 0 && (
          <Card
            styles={{
              width: "100%",
              transition: "transform 0.2s",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
              cursor: "pointer",
              ":hover": { transform: "translateY(-4px)" },
              height: "auto",
              flex: 1,
            }}
            onClick={() => {
              setShowAllTutors(!showAllTutors);
            }}
          >
            {remainingTutorsCount > 0 && (
              <Flex column space="evenly">
                <Flex gap="gap.smaller">
                  {remainingTutors.map((tutor, i) => (
                    <Avatar
                      key={`remaining-tutor-image-${i}`}
                      styles={{ flexShrink: 0 }}
                      image={tutor?.image ? tutor?.image + sasToken : userSvg}
                      size="medium"
                    />
                  ))}
                </Flex>
                <Text
                  content={
                    showAllTutors
                      ? `-${remainingTutorsCount} Hide`
                      : `+${remainingTutorsCount} Tutors`
                  }
                  size="medium"
                  weight="semibold"
                />
              </Flex>
            )}
          </Card>
        )}
      </Flex>
    </Box>
  );
};

const InstituteCard = ({ institutes }) => {
  const [showAllInstitutes, setShowAllInstitutes] = useState(false);

  const getCategoryBadge = (category) => {
    const formattedCategory =
      category.charAt(0).toUpperCase() + category.slice(1);
    return (
      <Label
        key={category}
        content={formattedCategory}
        size="small"
        styles={{ marginRight: "0.25rem" }}
      />
    );
  };

  const getDirections = (lat, lon, engine) => {
    window.open(
      engine === "google"
        ? `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`
        : `https://www.bing.com/maps?where=${lat},${lon}`,
      "_blank"
    );
  };

  const displayedInstitutes = showAllInstitutes
    ? institutes
    : institutes.slice(0, 2);
  const remainingInstitutes = institutes.slice(2);

  return (
    <Box>
      <Header as="h3" content="Educational Institutes" />
      <Flex
        gap="gap.smaller"
        styles={{
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            height: "10px !important ",
          },
        }}
      >
        {displayedInstitutes.map((institute, i) => {
          return (
            <Card
              key={institute.id}
              compact
              fluid
              styles={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
                borderRadius: "8px",
                transition: "transform 0.2s",
                cursor: "pointer",
                ":hover": { transform: "translateY(-4px)" },
                minWidth: "270px",
                height: "auto",
                flex: 1,
              }}
            >
              <Card.Header styles={{ padding: "0.5rem" }}>
                <Flex space="between" gap="gap.small" vAlign="center">
                  <Flex column>
                    <Text
                      weight="bold"
                      content={
                        institute?.engine === "google"
                          ? institute.name
                          : institute.poi.name
                      }
                      styles={{
                        "-webkit-line-clamp": 2,
                        overflow: "hidden",
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        textAlign: "start",
                      }}
                    />
                  </Flex>
                </Flex>
              </Card.Header>

              <Card.Body styles={{ padding: "0.5rem" }}>
                <Flex column gap="gap.smaller">
                  <Flex align="center" gap="gap.smaller">
                    <LocationIcon />
                    <Text
                      size="small"
                      content={
                        institute?.engine === "google"
                          ? institute?.formattedAddress || ""
                          : `${institute.address.municipality}, ${institute.address.countrySubdivisionName}`
                      }
                      styles={{
                        "-webkit-line-clamp": 2,
                        overflow: "hidden",
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        textAlign: "start",
                      }}
                    />
                  </Flex>
                  {institute?.engine !== "google" && (
                    <Text
                      size="small"
                      content={institute.address.streetName}
                      truncated
                    />
                  )}
                </Flex>
              </Card.Body>

              <Card.Footer styles={{ padding: "0.5rem" }}>
                <Flex gap="gap.smaller" hAlign="end">
                  <Button
                    size="small"
                    content="Directions"
                    icon={<LocationIcon />}
                    iconPosition="before"
                    primary
                    onClick={() => {
                      if (institute?.engine === "google") {
                        getDirections(
                          institute.location.lat,
                          institute.location.lng,
                          "google"
                        );
                      } else {
                        getDirections(
                          institute.position.lat,
                          institute.position.lon,
                          "azure"
                        );
                      }
                    }}
                  />
                </Flex>
              </Card.Footer>
            </Card>
          );
        })}
        {remainingInstitutes.length > 0 && (
          <Card
            key="remaining-institutes"
            styles={{
              padding: "0.5rem",
              width: "100%",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
              borderRadius: "8px",
              transition: "transform 0.2s",
              cursor: "pointer",
              ":hover": { transform: "translateY(-4px)" },
              height: "auto",
              minWidth: "270px",
              flex: 1,
            }}
            onClick={() => {
              setShowAllInstitutes(!showAllInstitutes);
            }}
          >
            <Card.Body styles={{ padding: "0.5rem", height: "100%" }}>
              <Flex column gap="gap.smaller" fill space="around">
                {remainingInstitutes.map((institute, i) => (
                  <Text
                    key={institute.id}
                    content={institute.name}
                    size="small"
                    styles={{
                      "-webkit-line-clamp": 1,
                      overflow: "hidden",
                      display: "-webkit-box",
                      "-webkit-box-orient": "vertical",
                      textAlign: "start",
                    }}
                  />
                ))}
                <Text
                  content={
                    showAllInstitutes
                      ? `-${remainingInstitutes.length} Hide`
                      : `+${remainingInstitutes.length} More`
                  }
                  styles={{
                    textAlign: "center",
                    verticalAlign: "center",
                    width: "100%",
                  }}
                />
              </Flex>
            </Card.Body>
          </Card>
        )}
      </Flex>
    </Box>
  );
};

const VideoSection = ({ videos }) => {
  const [showAllVideos, setShowAllVideos] = useState(false);

  const firstTwoVideos = showAllVideos ? videos : videos.slice(0, 2);
  const remainingVideos = videos.slice(2);
  const remainingCount = remainingVideos.length;

  return (
    <Flex column gap="gap.small">
      <Header as="h3" content="Featured Videos" />
      <Flex
        gap="gap.smaller"
        styles={{
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            height: "10px !important ",
          },
        }}
      >
        {/* First two video cards with overlay */}
        {firstTwoVideos.map((video) => (
          <Card
            key={video.videoId}
            styles={{
              width: "100%",
              padding: 0,
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
              borderRadius: "8px",
              transition: "transform 0.2s",
              cursor: "pointer",
              ":hover": { transform: "translateY(-4px)" },
              height: "auto",
              flex: 1,
              minWidth: "273px",
            }}
          >
            <Card.Body styles={{ padding: 0, margin: 0 }}>
              <Flex
                styles={{
                  position: "relative",
                  ":hover .overlay": { opacity: 1 },
                }}
              >
                <Image
                  src={video.thumbnails.medium.url}
                  alt={video.title}
                  styles={{ width: "100%", borderRadius: "8px" }}
                />
                {/* Overlay with title, channel, and button */}
                <Flex
                  className="overlay"
                  styles={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    opacity: 0,
                    transition: "opacity 0.3s",
                    borderRadius: "8px",
                  }}
                >
                  {/* Title at top left */}
                  <Text
                    content={extracCharacters(video.title, 30)}
                    weight="semibold"
                    styles={{
                      position: "absolute",
                      top: "8px",
                      left: "8px",
                      color: "#fff",
                      maxWidth: "70%",
                    }}
                  />
                  {/* Channel name at bottom left */}
                  <Text
                    content={video.channelTitle}
                    size="small"
                    styles={{
                      position: "absolute",
                      bottom: "8px",
                      left: "8px",
                      color: "#fff",
                      maxWidth: "70%",
                    }}
                  />
                  {/* Icon button at bottom right */}
                  <Button
                    iconOnly
                    icon={<PlayIcon size="small" />}
                    circular
                    secondary
                    size="small"
                    title="Watch"
                    as="a"
                    href={`https://www.youtube.com/watch?v=${video.videoId}`}
                    target="_blank"
                    styles={{
                      position: "absolute",
                      bottom: "8px",
                      right: "8px",
                      ":hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                </Flex>
              </Flex>
            </Card.Body>
          </Card>
        ))}

        {/* Card for remaining videos */}
        {remainingCount > 0 && (
          <Card
            key="remaining-videos"
            styles={{
              padding: 0,
              width: "100%",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
              borderRadius: "8px",
              transition: "transform 0.2s",
              cursor: "pointer",
              ":hover": { transform: "translateY(-4px)" },
              height: "auto",
              flex: 1,
              minWidth: "273px",
            }}
            onClick={() => {
              setShowAllVideos(!showAllVideos);
            }}
          >
            <Card.Body
              styles={{ padding: "0.5rem", margin: 0, height: "100%" }}
            >
              <Flex column gap="gap.small" space="around" fill>
                {/* Row of small thumbnails */}
                <Flex gap="gap.smaller" wrap="nowrap">
                  {remainingVideos.map((video) => (
                    <Image
                      key={video.videoId}
                      src={video.thumbnails.medium.url}
                      alt={video.title}
                      styles={{
                        width: "50px",
                        height: "30px",
                        borderRadius: "4px",
                        objectFit: "cover",
                      }}
                    />
                  ))}
                </Flex>
                {/* Count at the bottom */}
                <Text
                  content={
                    showAllVideos
                      ? `-${remainingCount} Hide`
                      : `+ ${remainingCount} More`
                  }
                  styles={{ textAlign: "center", width: "100%" }}
                />
              </Flex>
            </Card.Body>
          </Card>
        )}
      </Flex>
    </Flex>
  );
};

const BooksSection = ({ books }) => {
  const [showAllBooks, setShowAllBooks] = useState(false);

  const lineClampStyle = {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };

  const firstTwoBooks = showAllBooks ? books : books.slice(0, 2);
  const remainingBooks = books.slice(2);
  const remainingCount = remainingBooks.length;

  return (
    <Flex column gap="gap.small">
      <Header as="h3" content="Featured Books" />
      <Flex
        gap="gap.smaller"
        styles={{
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            height: "10px !important ",
          },
        }}
      >
        {firstTwoBooks.map((book) => (
          <Card
            key={book.id}
            styles={{
              padding: "0.5rem",
              borderRadius: "8px",
              width: "100%",
              transition: "transform 0.2s",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
              cursor: "pointer",
              ":hover": { transform: "translateY(-4px)" },
              flex: 1,
              height: "auto",
              minWidth: "273px",
            }}
            onClick={() => {
              window.open(book.previewLink, "_blank");
            }}
          >
            <Card.Body styles={{ padding: 0 }}>
              <Flex gap="gap.smaller" vAlign="center">
                <div style={{ width: "50px", flexShrink: 0 }}>
                  <Image
                    src={book?.imageLinks?.thumbnail || defaultBookThumbnail}
                    alt={book.title}
                    styles={{
                      width: "50px",
                      height: "75px",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                </div>
                <Flex column gap="gap.smaller" styles={{ flexGrow: 1 }}>
                  <Text weight="semibold" content={book.title} />
                  <Text
                    size="small"
                    content={`Published: ${book.publishedDate}`}
                    color="gray"
                  />
                  <Text
                    content={book.description}
                    style={{ ...lineClampStyle, marginTop: "0.5rem" }}
                  />
                </Flex>
              </Flex>
            </Card.Body>
          </Card>
        ))}
        {remainingCount > 0 && (
          <Card
            key="remaining-books"
            styles={{
              padding: "0.5rem",
              width: "100%",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",

              borderRadius: "8px",
              transition: "transform 0.2s",
              cursor: "pointer",
              ":hover": { transform: "translateY(-4px)" },
              flex: 1,
              height: "auto",
              minWidth: "273px",
            }}
            onClick={() => {
              setShowAllBooks(!showAllBooks);
            }}
          >
            <Card.Body styles={{ padding: "0.5rem", height: "100%" }}>
              <Flex column gap="gap.small" fill space="around">
                {/* Display a row of small thumbnails for remaining books */}
                <Flex gap="gap.smaller" wrap="nowrap">
                  {remainingBooks.map((book) => (
                    <Image
                      key={book.id}
                      src={book?.imageLinks?.thumbnail || defaultBookThumbnail}
                      alt={book.title}
                      styles={{
                        width: "40px",
                        height: "60px",
                        borderRadius: "4px",
                        objectFit: "cover",
                      }}
                    />
                  ))}
                </Flex>
                {/* Display remaining count */}
                <Text
                  content={
                    showAllBooks
                      ? `-${remainingCount} Hide`
                      : `+${remainingCount} More`
                  }
                  styles={{ textAlign: "center", width: "100%" }}
                />
              </Flex>
            </Card.Body>
          </Card>
        )}
      </Flex>
    </Flex>
  );
};

const ResourcesSection = ({ resources }) => {
  const [showAllResources, setShowAllResources] = useState(false);

  const firstThreeResources = showAllResources
    ? resources
    : resources.slice(0, 3);
  const remainingResources = resources.slice(3);

  const resourcesTooltipCard = (resource) => {
    return (
      <Flex column gap="gap.smaller">
        <Text weight="semibold" content={resource.title} />
        <Text content={resource.snippet} />
        <Flex gap="gap.smaller" vAlign="center">
          <img
            src={resource.favicon}
            alt="Favicon"
            style={{ width: "16px", height: "16px" }}
          />
          <Text content={removeWWW(resource.domain)} />
        </Flex>
      </Flex>
    );
  };
  return (
    <Flex column gap="gap.medium">
      <Header as="h3" content="Top Resources" />
      <Box>
        <Flex
          gap="gap.smaller"
          styles={{
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              height: "10px !important ",
            },
          }}
        >
          {firstThreeResources.map((resource, index) => (
            <Tooltip
              key={index}
              content={resourcesTooltipCard(resource)}
              styles={{ width: "fit-content" }}
              trigger={
                <Card
                  styles={{
                    padding: "1rem",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
                    borderRadius: "8px",
                    width: "100%",
                    cursor: "pointer",
                    transition: "transform 0.2s",
                    ":hover": {
                      transform: "translateY(-4px)",
                    },
                    flex: 1,
                    minWidth: "200px",
                  }}
                  onClick={() => {
                    window.open(resource.url, "_blank");
                  }}
                >
                  <Card.Body styles={{ marginBottom: 0 }}>
                    <Flex column gap="gap.smaller">
                      <Text
                        weight="semibold"
                        content={resource.title}
                        styles={{
                          "-webkit-line-clamp": 2,
                          overflow: "hidden",
                          display: "-webkit-box",
                          "-webkit-box-orient": "vertical",
                        }}
                      />
                      <Flex gap="gap.smaller" vAlign="center">
                        <img
                          src={resource.favicon}
                          alt="Favicon"
                          style={{ width: "16px", height: "16px" }}
                        />
                        <Text
                          styles={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          content={removeWWW(resource.domain)}
                          color="brand"
                        />
                      </Flex>
                    </Flex>
                  </Card.Body>
                </Card>
              }
              zIndex={999}
              mountNode={
                document.getElementsByClassName("learning-from-web-dialog")[0]
              }
            />
          ))}
          {remainingResources.length > 0 && (
            <Card
              styles={{
                padding: "1rem",
                boxShadow: "var(--shadow-2)",
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
                width: "100%",
                height: "auto",
                cursor: "pointer",
                transition: "transform 0.2s",
                ":hover": {
                  transform: "translateY(-4px)",
                },
                minWidth: "200px",
                flex: 1,
              }}
              onClick={() => setShowAllResources(!showAllResources)}
            >
              <Card.Body styles={{ marginBottom: 0, height: "100%" }}>
                <Flex column gap="gap.smaller" space="around" fill>
                  <Flex>
                    {remainingResources.map((resource, i) => (
                      <img
                        key={`resource-favocon-${i}`}
                        src={resource.favicon}
                        alt="Favicon"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "5px",
                        }}
                      />
                    ))}
                  </Flex>
                  <Text
                    content={
                      showAllResources
                        ? `Hide`
                        : `+ ${remainingResources.length} resources`
                    }
                  />
                </Flex>
              </Card.Body>
            </Card>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

const RelatedQuestions = ({ questions }) => {
  const { getLearningResources } = useLearningFromWebWithAi();

  return (
    <Box>
      <Header as="h3" content="Related Questions" />
      <Flex column>
        <Divider />
        {questions.map((question, i) => (
          <React.Fragment key={`related-question-${i}`}>
            <Flex
              space="between"
              vAlign="center"
              gap="gap.medium"
              style={{ padding: "10px" }}
            >
              <Tooltip
                content={question}
                trigger={
                  <Text
                    styles={{
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    weight="regular"
                    content={question}
                  />
                }
              />
              <Button
                icon={<AddIcon />}
                primary
                text
                iconOnly
                onClick={() => {
                  getLearningResources(question);
                }}
              />
            </Flex>
            <Divider />
          </React.Fragment>
        ))}
      </Flex>
    </Box>
  );
};

const LearningFromWebDialogContent = () => {
  const {
    activeChatMessages,
    setActiveChatMessages,
    isLoadingChatMessages,
    getLearningResources,
    isLoadingLearningResources,
    learnAiChats,
    isSidebarOpen,
    setSidebarOpen,
    getChatMessages,
    activeChat,
    setActiveChat,
    isLoadingLearnAiChats,
    getLearnAiChats,
    setIsOpenLearningFromWebDialog,
    setDeletingChatId,
    editingChatTitle,
    setEditingChatTitle,
    editingChatId,
    setEditingChatId,
    renameChat,
    inputValue,
    setInputValue,
    searchQuery,
    setSearchQuery,
  } = useLearningFromWebWithAi();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    learnAiChats.length === 0 && getLearnAiChats();
  }, []);

  const renderSidebarHeader = () => (
    <Box styles={styles.sidebarHeader}>
      <Flex space="between" vAlign="center">
        <Text content="Study Sessions" weight="bold" size="large" />
        <Button
          icon={<AddIcon />}
          text
          iconOnly
          title="New Session"
          onClick={() => {
            setActiveChatMessages([]);
            setActiveChat(null);
          }}
        />
      </Flex>
    </Box>
  );

  const getHeaderTitle = () => {
    if (!activeChat) return "New Study Session";
    return activeChat.title;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;
    setInputValue("");
    getLearningResources(inputValue);
  };

  const styles = {
    container: {
      height: "100%",
      backgroundColor: "#f8f9fa",
      display: "flex",
    },
    sidebar: {
      width: isSidebarOpen ? "320px" : "72px",
      backgroundColor: "#ffffff",
      borderRight: "1px solid #e5e7eb",
      transition: "all 0.3s ease",
      overflow: "hidden",
    },
    collapsedSidebar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "1rem 0",
      gap: "1rem",
    },
    sidebarContent: {
      width: "320px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    sidebarHeader: {
      padding: "1.25rem",
      borderBottom: "1px solid #e5e7eb",
      backgroundColor: "#f8fafc",
    },
    searchBox: {
      margin: "1rem",
      position: "relative",
    },
    historyItem: {
      // padding: "0.3rem",
      cursor: "pointer",
      borderLeft: "3px solid transparent",
      transition: "all 0.2s ease",
      ":hover": {
        backgroundColor: "#e5e5f1",
        borderLeftColor: "#6264a7",
      },
    },
    activeHistoryItem: {
      backgroundColor: "#e5e5f1",
      borderLeftColor: "#6264a7",
    },
    mainContent: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    header: {
      padding: "1rem 1.5rem",
      borderBottom: "1px solid #e5e7eb",
      backgroundColor: "#fff",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    },
    chatContainer: {
      flex: 1,
      overflowY: "auto",
      padding: "1.5rem",
      backgroundColor: "#f8fafc",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    messageWrapper: {
      marginBottom: "1rem",
      maxWidth: "50vw",
      width: "100%",
    },
    inputContainer: {
      padding: "1.5rem",
      borderTop: "1px solid #e5e7eb",
      backgroundColor: "#fff",
      boxShadow: "0 -1px 2px rgba(0, 0, 0, 0.05)",
      position: "relative",
    },
    inputWrapper: {
      maxWidth: "800px",
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    unreadBadge: {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#60a5fa",
      marginLeft: "0.5rem",
    },
  };

  const renderCollapsedSidebar = () => (
    <Box styles={styles.collapsedSidebar}>
      <Button
        icon={<AddIcon />}
        circular
        iconOnly
        title="New Chat"
        onClick={() => {
          setActiveChat(null);
          setActiveChatMessages([]);
        }}
      />
      <Button
        icon={<ChatIcon />}
        circular
        iconOnly
        title="Chat History"
        onClick={() => setSidebarOpen(true)}
      />
      <Divider />
      {learnAiChats.slice(0, 5).map((chat, index) => (
        <Avatar
          key={chat.id}
          size="small"
          name={chat.title}
          title={chat.title}
          onClick={() => {
            getChatMessages(index);
          }}
        />
      ))}
    </Box>
  );

  const renderHistoryItem = (chat, index) => (
    <Box
      key={chat.id}
      styles={{
        ...styles.historyItem,
        ...(activeChatMessages?.length > 0 &&
        activeChatMessages[0]?.chatId === chat.id
          ? styles.activeHistoryItem
          : {}),
      }}
    >
      <Flex vAlign="center" space="between" gap="gap.small">
        {editingChatId === chat.id ? (
          <Input
            value={editingChatTitle}
            onChange={(e, data) => setEditingChatTitle(data.value)}
            fluid
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                renameChat(editingChatId, editingChatTitle);
              }
            }}
          />
        ) : (
          <Tooltip
            content={chat?.title || "New Chat"}
            trigger={
              <Text
                styles={{
                  width: "fit-content",
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  padding: "0.3rem",
                }}
                content={chat?.title || "New Chat"}
                weight="regular"
                onClick={() => {
                  getChatMessages(index);
                }}
              />
            }
            zIndex={10}
            mountNode={
              document.getElementsByClassName("learning-from-web-dialog")[0]
            }
            position="after"
          />
        )}
        <MenuButton
          trigger={
            <Button
              iconOnly
              text
              primary
              icon={<MenuIcon size="small" />}
              aria-label="Options"
            />
          }
          menu={[
            {
              content: (
                <Button
                  iconOnly
                  text
                  styles={{ color: "red", ":hover": { color: "red" } }}
                  icon={<TrashCanIcon size="small" />}
                  aria-label="Delete"
                  content="Delete"
                  onClick={() => {
                    setDeletingChatId(chat.id);
                  }}
                />
              ),
            },
            {
              content: (
                <Button
                  iconOnly
                  text
                  primary
                  icon={<EditIcon size="small" />}
                  aria-label="Rename"
                  content="Rename"
                  onClick={() => {
                    setEditingChatTitle(chat.title);
                    setEditingChatId(chat.id);
                  }}
                />
              ),
            },
          ]}
          // on="hover"
        />
      </Flex>
    </Box>
  );

  const formatMessageContent = (message) => {
    if (message.sender === "user") {
      return message.body.topic;
    } else {
      return (
        <Flex column gap="gap.smaller">
          {message?.body?.topResources &&
            message?.body?.topResources?.length > 0 && (
              <>
                <ResourcesSection resources={message.body.topResources} />
                <Divider />
              </>
            )}
          {message?.body?.topResources?.length === 0 && <Divider />}
          {message?.body?.summary && (
            <>
              <div
                className="summary-content"
                dangerouslySetInnerHTML={{
                  __html: message.body.summary,
                }}
              />
              <Divider />
            </>
          )}
          {message?.body?.books && message?.body?.books?.length > 0 && (
            <>
              <BooksSection books={message.body.books} />
              <Divider />
            </>
          )}
          {message?.body?.videos && message?.body?.videos?.length > 0 && (
            <>
              <VideoSection videos={message.body.videos} />
              <Divider />
            </>
          )}
          {message?.body?.nearbyInstitutes &&
            message?.body?.nearbyInstitutes?.length > 0 && (
              <>
                <InstituteCard institutes={message.body.nearbyInstitutes} />
                <Divider />
              </>
            )}
          {message?.body?.recommendedTutors &&
            message?.body?.recommendedTutors?.length > 0 && (
              <>
                <RecommendedTutors tutors={message.body.recommendedTutors} />
                <Divider />
              </>
            )}
          {message?.body?.relatedQuestions &&
            message?.body?.relatedQuestions?.length > 0 && (
              <>
                <RelatedQuestions questions={message.body.relatedQuestions} />
              </>
            )}
        </Flex>
      );
    }
  };

  return (
    <Flex styles={styles.container}>
      {/* Sidebar */}
      <Box styles={styles.sidebar}>
        {isSidebarOpen ? (
          <Box styles={styles.sidebarContent}>
            {renderSidebarHeader()}
            <Box styles={styles.searchBox}>
              <Input
                icon={<SearchIcon />}
                placeholder="Search chats..."
                value={searchQuery}
                onChange={(e, data) => setSearchQuery(data.value)}
                fluid
              />
            </Box>
            <Box styles={{ overflowY: "auto", paddingRight: "16px" }}>
              {isLoadingLearnAiChats ? (
                <Flex vAlign="center" hAlign="center" fill>
                  <Loader />
                </Flex>
              ) : (
                learnAiChats
                  .filter((chat) =>
                    chat.title.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((chat, index) => renderHistoryItem(chat, index))
              )}
            </Box>
          </Box>
        ) : (
          renderCollapsedSidebar()
        )}
      </Box>

      {/* Main Content */}
      <Flex column styles={styles.mainContent}>
        {/* Header */}
        <Box styles={styles.header}>
          <Flex gap="gap.medium" vAlign="center">
            <Flex gap="gap.small" vAlign="center">
              <Button
                icon={isSidebarOpen ? <ChevronStartIcon /> : <ChevronEndIcon />}
                text
                iconOnly
                onClick={() => setSidebarOpen(!isSidebarOpen)}
              />
            </Flex>
            <Tooltip
              content={getHeaderTitle()}
              trigger={
                <Text
                  styles={{
                    width: "fit-content",
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  content={getHeaderTitle()}
                  size="large"
                  weight="bold"
                />
              }
              zIndex={5}
              mountNode={
                document.getElementsByClassName("learning-from-web-dialog")[0]
              }
            />
          </Flex>
        </Box>

        {/* Chat Container */}
        <Box styles={styles.chatContainer}>
          {isLoadingChatMessages ? (
            <Flex
              gap="gap.small"
              hAlign="center"
              vAlign="center"
              style={{ height: "100%" }}
            >
              <Loader size="medium" />{" "}
              <Text
                color="brand"
                weight="semibold"
                size="large"
                content="Retriving messages..."
              />
            </Flex>
          ) : activeChatMessages.length === 0 ? (
            <EmptyState />
          ) : (
            activeChatMessages.map((message) => (
              <Flex
                key={message.id}
                className="message-wrapper"
                styles={styles.messageWrapper}
                hAlign={message.sender === "user" ? "end" : "start"}
              >
                <ChatMessage
                  content={formatMessageContent(message)}
                  timestamp={moment(message.createdAt).format(
                    "MMM DD, YYYY - hh:mm A"
                  )}
                  mine={message.sender === "user"}
                  variables={{
                    backgroundColor:
                      message.sender === "user" ? "#e3f2fd" : "#ffffff",
                    borderRadius: "12px",
                    padding: "12px",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                  }}
                />
              </Flex>
            ))
          )}
          {isLoadingLearningResources && (
            <Box styles={styles.messageWrapper}>
              <Flex gap="gap.small" vAlign="center">
                <Avatar icon={<RobotIcon />} size="medium" />
                <Loader
                  size="small"
                  label="AI is thinking..."
                  labelPosition="end"
                />
              </Flex>
            </Box>
          )}
          <div ref={messagesEndRef} />
        </Box>

        {/* Input Area */}
        <Box styles={styles.inputContainer}>
          <form onSubmit={handleSubmit}>
            <Flex column styles={styles.inputWrapper}>
              <TextArea
                fluid
                placeholder="Ask anything..."
                value={inputValue}
                onChange={(e, data) => setInputValue(data.value)}
                styles={{
                  minHeight: "50px",
                  maxHeight: "200px",
                }}
                disabled={isLoadingLearningResources || isLoadingChatMessages}
                variables={{
                  borderRadius: "8px",
                  backgroundColor: "#f8fafc",
                  borderColor: "#e5e7eb",
                  padding: "12px",
                }}
                resize="none"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
              />
              <Flex gap="gap.small" hAlign="end">
                <Text size="small" color="grey">
                  Press Enter to send, Shift+Enter for new line
                </Text>
                <Button
                  content="Send"
                  primary
                  disabled={
                    !inputValue.trim() ||
                    isLoadingLearningResources ||
                    isLoadingChatMessages
                  }
                  type="submit"
                  styles={{ minWidth: "100px" }}
                  variables={{
                    borderRadius: "8px",
                  }}
                />
              </Flex>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};

export default LearningFromWebDialogContent;
