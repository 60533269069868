import axios from "axios";
import React, { useEffect } from "react";
import { createContext, useContext, useState } from "react";

const LearningFromWebWithAi = createContext();
export const useLearningFromWebWithAi = () => {
  return useContext(LearningFromWebWithAi);
};

export const LearningFromWebWithAiProvider = (props) => {
  const { user } = props;

  const [isOpenLearningFromWebDialog, setIsOpenLearningFromWebDialog] =
    useState(false);
  // learn ai chats
  //  {
  //   "id": 1,
  //   "title": "science",
  //   "createdAt": "2025-02-20T08:58:34.000Z"
  //  } // Include messages when on getChatMessages api call
  const [learnAiChats, setLearnAiChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [isLoadingLearnAiChats, setIsLoadingLearnAiChats] = useState(false);

  // current chat messages
  const [activeChatMessages, setActiveChatMessages] = useState([]);
  const [isLoadingChatMessages, setIsLoadingChatMessages] = useState(false);

  // learning resources
  const [isLoadingLearningResources, setIsLoadingLearningResources] =
    useState(false);

  // Sidebar
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  // delete chat
  const [deletingChatId, setDeletingChatId] = useState("");
  const [isDeletingChat, setIsDeletingChat] = useState(false);

  // edit chat title
  const [editingChatId, setEditingChatId] = useState("");
  const [isEditingChat, setIsEditingChat] = useState("");
  const [editingChatTitle, setEditingChatTitle] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [isFullScreen, setIsFullScreen] = useState(false);

  const getLearningResources = async (topic) => {
    try {
      const temporaryMessageId = Date.now();
      setActiveChatMessages([
        ...activeChatMessages,
        {
          id: temporaryMessageId,
          chatId: activeChat?.id || null,
          sender: "user",
          body: {
            topic: topic,
          },
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
      ]);
      setIsLoadingLearningResources(true);
      let endPoint = `${process.env.REACT_APP_EP_URL}/api/learning-resources/${user.slug}&topic=${topic}`;
      if (activeChat) {
        endPoint = endPoint + `&chatId=${activeChat.id}`;
      }
      const coordinates = JSON.parse(
        localStorage.getItem("coordinates") || "{}"
      );
      if (coordinates?.latitude && coordinates?.longitude) {
        const { latitude, longitude } = coordinates;
        endPoint = endPoint + `&latitude=${latitude}&longitude=${longitude}`;
      }
      const response = await axios.get(endPoint, {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });
      const activeChatMessagesAfterRemovingTemporaryMsg = [
        ...activeChatMessages,
      ].filter((message) => message.id !== temporaryMessageId);
      if (activeChatMessagesAfterRemovingTemporaryMsg?.length > 0) {
        const aiChat = response?.data || {};
        setActiveChatMessages([
          ...activeChatMessagesAfterRemovingTemporaryMsg,
          ...aiChat.LearnAIChatMessages,
        ]);
      } else {
        const aiChat = response?.data || {};
        setActiveChatMessages(aiChat.LearnAIChatMessages);
        const newChat = {
          id: aiChat.id,
          title: aiChat.title,
          createdAt: aiChat.createdAt,
          messages: aiChat.LearnAIChatMessages,
        };
        setActiveChat(newChat);
        setLearnAiChats([newChat, ...learnAiChats]);
      }
      setIsLoadingLearningResources(false);
    } catch (error) {
      console.log(error);
      setIsLoadingLearningResources(false);
    }
  };

  const getLearnAiChats = async () => {
    try {
      setIsLoadingLearnAiChats(true);
      const response = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/learn-ai-chats${user.slug}`,
        {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        }
      );
      setLearnAiChats(response.data);
      setIsLoadingLearnAiChats(false);
    } catch (error) {
      console.log(error);
      setIsLoadingLearnAiChats(false);
    }
  };

  const getChatMessages = async (index) => {
    try {
      let chat = learnAiChats[index];
      // set active chat
      setActiveChat(chat);

      if (chat?.messages && chat?.messages?.length > 0) {
        setActiveChatMessages(chat.messages);
        return;
      }

      setIsLoadingChatMessages(true);
      const response = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/learn-ai-chats/${chat.id}${user.slug}`,
        {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        }
      );
      const messages = response?.data || [];
      // update learn ai chats
      let clonedLearnAiChats = [...learnAiChats];
      clonedLearnAiChats[index]["messages"] = messages;
      setLearnAiChats(clonedLearnAiChats);

      // update chat messages
      setActiveChatMessages(messages);
      setIsLoadingChatMessages(false);
    } catch (error) {
      console.log(error);
      setIsLoadingChatMessages(false);
    }
  };

  const deleteChat = async (id) => {
    try {
      setIsDeletingChat(true);
      await axios.delete(
        `${process.env.REACT_APP_EP_URL}/api/learn-ai-chats/${id}${user.slug}`,
        {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        }
      );
      setLearnAiChats(learnAiChats.filter((chat) => chat.id !== id));

      setDeletingChatId("");
      setActiveChat(null);
      setActiveChatMessages([]);

      setIsDeletingChat(false);
    } catch (error) {
      setIsDeletingChat(false);
    }
  };

  const renameChat = async (id, title) => {
    try {
      setIsEditingChat(true);
      setLearnAiChats(
        learnAiChats.map((chat) => {
          if (chat.id === id) {
            return { ...chat, title };
          }
          return chat;
        })
      );
      await axios.put(
        `${process.env.REACT_APP_EP_URL}/api/learn-ai-chats/${id}${user.slug}`,
        { title },
        {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        }
      );
      setEditingChatTitle("");
      setEditingChatId("");
      setIsEditingChat(false);
    } catch (error) {
      setIsEditingChat(false);
    }
  };

  const handleClose = () => {
    setActiveChatMessages([]);
    setActiveChat(null);
    setSearchQuery("");
    setInputValue("");
    setIsOpenLearningFromWebDialog(false);
    setIsFullScreen(false);
  };

  return (
    <LearningFromWebWithAi.Provider
      value={{
        isOpenLearningFromWebDialog,
        setIsOpenLearningFromWebDialog,
        getLearningResources,
        getLearnAiChats,
        getChatMessages,
        learnAiChats,
        activeChatMessages,
        setActiveChatMessages,
        isLoadingLearnAiChats,
        isLoadingChatMessages,
        isLoadingLearningResources,
        isSidebarOpen,
        setSidebarOpen,
        activeChat,
        setActiveChat,
        deleteChat,
        isDeletingChat,
        deletingChatId,
        setDeletingChatId,
        renameChat,
        editingChatTitle,
        setEditingChatTitle,
        editingChatId,
        setEditingChatId,
        inputValue,
        setInputValue,
        searchQuery,
        setSearchQuery,
        handleClose,
        isFullScreen,
        setIsFullScreen,
      }}
    >
      {props.children}
    </LearningFromWebWithAi.Provider>
  );
};
