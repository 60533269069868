import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
  Checkbox,
  CloseIcon,
  Dialog,
  Flex,
  Loader,
} from "@fluentui/react-northstar";
import { Row, Col, Layout } from "antd";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

import Event from "../../components/widgets/Event";
import Header from "../../components/Header/Header";
import { ResponsiveImage } from "../../components/blocks/Images";
import { EventsNavBar } from "../../components/EventsNavBar";
import EventFilters from "../../components/widgets/EventFilters";
import RegistrationModal from "../../components/widgets/RegistrationModal";
import { useEvent } from "../../context/EventContext";
import EventFilterModal from "../../components/widgets/EventFilterModal";
import UserFilter from "./UserFilter";
import UserColumnFilter from "./UserColumnFilter";
import CustomProgressIndicator from "../../components/Loader/CustomProgressIndicator";

import "./event.css";
import { loginRequest, msalConfig } from "../../App/msal-auth/msalAuth";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";

const url = process.env.REACT_APP_EP_URL;

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onFailure,
    scope:
      "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.events",
    ux_mode: "popup",
    select_account: true,
    // flow: "auth-code",
  });

  return (
    <button
      id="google-login-btn"
      style={{ display: "none" }}
      onClick={googleLogin}
    >
      MeetingDetailsForm
    </button>
  );
};

const DiscoverEvents = (props) => {
  const [accDlg, setAccDlg] = useState(false);
  const [eventData, setEventData] = useState([]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isRegModalVisible, setIsRegModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [isLive, setIsLive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 767 && window.innerWidth <= 991
  );
  const [currentEvent, setCurrentEvent] = useState({});
  const [openEventLoginModal, setOpenEventLoginModal] = useState({
    type: null,
    isOpen: false,
    value: false,
  });

  const filterModalProps = useEvent();

  const { t } = props;

  const translation = t("events");
  const userFilterTranslation = translation.body.userFilter;
  const eventsTranslation = translation.body.discoverEvents;
  const eventTranslation = translation.body.eventForm;

  const {
    appliedFilter,
    globalSearch,
    isSaveFilterModalOpen,
    saveUserFilter,
    setIsValidateFilterName,
    setIsSaveFilterModalOpen,
    filterName,
    setFilterName,
    isValidateFilterName,
    isAudio,
    isVideoConf,
    isInplace,
    handlePortal,
    isFilterApplied,
    selectedColumns,
    selectedDateFilter,
    setSelectedDateFilter,
    startDate,
    endDate,
    selectedCreater,
  } = filterModalProps;

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth > 767 && window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getTags();
    getCategories();
  }, [props]);

  useEffect(() => {
    getEvents();
  }, [
    appliedFilter,
    props,
    globalSearch,
    isLive,
    selectedDateFilter,
    selectedCreater,
  ]);

  const getEvents = () => {
    setIsLoading(true);
    const teacherId = props.user.mail;
    const headers = {
      Authorization: `Bearer ${props.user.accessToken}`,
    };
    let params = {
      isParentMeeting: props.user.role === "Parent" ? true : false,
    };
    if (!!startDate && moment(startDate).isValid()) {
      params.start = moment(startDate).format("MM-DD-YYYY");
    }
    if (!!endDate && moment(endDate).isValid()) {
      params.end = moment(endDate).format("MM-DD-YYYY");
    }

    if (isAudio) {
      params.isAudio = isAudio;
    }
    if (isVideoConf) {
      params.isVideoConf = isVideoConf;
    }
    if (isInplace) {
      params.isInplace = isInplace;
    }
    const selectedTags = filterModalProps.tags
      .filter((t) => !!t.checked)
      .map((t) => t.id);
    const selectedCategories = filterModalProps.categories
      .filter((c) => !!c.checked)
      .map((c) => c.id);

    if (selectedTags.length) {
      params.tags = JSON.stringify(selectedTags);
    }
    if (selectedCategories.length) {
      params.categories = JSON.stringify(selectedCategories);
    }

    if (globalSearch) {
      params.title = globalSearch;
    }
    if (isLive) {
      params.isLive = isLive;
    }

    if (selectedDateFilter) {
      params.dateFilter = selectedDateFilter;
      params.isFutureDateFilter = true;
    }

    if (selectedCreater) {
      params.creater = selectedCreater;
    }

    axios
      .get(`${url}/api/events/discover/${teacherId}/all${props.user.slug}`, {
        headers,
        params,
      })
      .then(({ data: { result } }) => {
        // let formattedData = [];
        // if (result?.length) {
        //   formattedData = result.map((item) => ({
        //     ...item,
        //     startDate:
        //       item.startDate && moment(item.startDate).isValid()
        //         ? moment(item.startDate).local().format("YYYY-MM-DDThh:mm:ss")
        //         : null,
        //     endDate:
        //       item.endDate && moment(item.endDate).isValid()
        //         ? moment(item.endDate).local().format("YYYY-MM-DDThh:mm:ss")
        //         : null,
        //   }));
        // }
        setEventData(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setEventData([]);
        if (err?.response?.status === 400 || err?.response?.status === 409) {
          // setDialog(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateFavourite = (data) => {
    setIsUpdating(true);
    const { id, isFavourite } = data;
    axios
      .post(
        `${url}/api/event-favourite${props.user.slug}`,
        {
          eventId: id,
          teacherId: props.user.mail,
          isFavourite,
        },
        {
          headers: {
            Authorization: `${props.user.tokenType} ${props.user.accessToken}`,
          },
        }
      )
      .then(() => {
        getEvents();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        // console.log("Update Failed");
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const getCategories = () => {
    axios
      .get(`${url}/api/category/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setCategories(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  const getTags = () => {
    axios
      .get(`${url}/api/tag/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setTags(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  const registerEvent = useCallback(
    (meetToken = "", type) => {
      const data = JSON.parse(localStorage.getItem("currentEvent"));
      // setIsUpdating(true);
      if (meetToken) {
        const googleMeetToken = JSON.parse(
          localStorage.getItem("GoogleMeetToken")
        );
        const googleMail = googleMeetToken.profileObj.email;
        const params = {
          eventId: data.id,
          teacherId: props.user.mail,
          status: "ATTENDING",
        };
        axios
          .post(
            `${url}/api/registrations${props.user.slug}&googleMail=${googleMail}`,
            params,
            {
              headers: {
                Authorization: `Bearer ${props.user.accessToken}`,
                MeetToken: `Bearer ${meetToken}`,
              },
            }
          )
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            console.log(err);
          })
          .finally(() => {
            getEvents();
            setIsUpdating(false);
          });
      } else {
        const params = {
          eventId: data.id,
          teacherId: props.user.mail,
          status: "ATTENDING",
        };
        axios
          .post(`${url}/api/registrations${props.user.slug}`, params, {
            headers: {
              Authorization: `Bearer ${props.user.accessToken}`,
            },
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            console.log(err);
          })
          .finally(() => {
            getEvents();
            setIsUpdating(false);
          });
      }
    },
    [currentEvent]
  );

  const cancelRegistration = (data) => {
    setIsUpdating(true);
    const params = {
      status: "CANCELLED",
    };
    axios
      .put(
        `${url}/api/registrations/${data.registrationId}${props.user.slug}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`,
          },
        }
      )
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
      })
      .finally(() => {
        getEvents();
        setIsUpdating(false);
      });
  };

  const googleLoginHandler = () => {
    const googleLoginBtn = document.getElementById("google-login-btn");
    if (googleLoginBtn) {
      googleLoginBtn.click();
    }
  };

  const handleLogin = (type, value) => {
    if (value) {
      if (type === "isMSTeamMeeting") {
        return registerEvent();
        const isMSLogin = props.user.authType === "microsoft";
        if (isMSLogin) {
          registerEvent(props.user.accessToken, "MSTeamMeeting");
          localStorage.setItem("MSTeamsToken", props.user.accessToken);
        } else {
          let token = localStorage.getItem("MSTeamsToken");
          if (token) {
            handleMSLogin();
          } else {
            setOpenEventLoginModal({
              type: "isMSTeamMeeting",
              isOpen: true,
              value,
            });
          }
        }
      } else if (type === "isGoogleMeetMeeting") {
        const isGoogleLogin = props.user.authType === "google";
        if (isGoogleLogin) {
          googleLoginHandler();
        } else {
          let tokenData = localStorage.getItem("GoogleMeetToken");
          let token = null;

          if (tokenData) {
            try {
              token = JSON.parse(tokenData)?.accessToken;
            } catch (error) {}
          }

          if (token) {
            googleLoginHandler();
          } else {
            setOpenEventLoginModal({
              type: "isGoogleMeetMeeting",
              isOpen: true,
              value,
            });
          }
        }
      }
    } else {
      registerEvent();
    }
  };

  // const onSuccess = (res) => {
  //   localStorage.setItem("GoogleMeetToken", JSON.stringify(res));
  //   registerEvent(res.accessToken, "GoogleMeet");
  // };

  const onSuccess = (res) => {
    axios
      .get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${res.access_token}`,
        },
      })
      .then((response) => {
        localStorage.setItem(
          "GoogleMeetToken",
          JSON.stringify({
            profileObj: response.data,
            accessToken: res.access_token,
          })
        );
        registerEvent(res.access_token, "GoogleMeet");
      })
      .catch((err) => console.log(err));
  };

  const onFailure = (res) => {
    if (typeof res?.details === "string") {
      if (res.error === "idpiframe_initialization_failed") {
        // NotificationAlert(eventTranslation.disabledCookiesWarning, "warning");
        NotificationAlert(
          "Cookies are not enabled. Please enable it if you want to create Google Meet.",
          "warning"
        );
      } else {
        NotificationAlert(res.details, "warning");
      }
    }
  };

  const handleMSLogin = (value) => {
    const instance = new PublicClientApplication({ ...msalConfig, cache: {} });
    instance
      .loginPopup(loginRequest)
      .then((data) => {
        localStorage.setItem("MSTeamsToken", JSON.stringify(data));
      })
      .catch((e) => {});
  };

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {/* <div className="App event-form google-login-btn">
          <GoogleLogin
            scope="https://www.googleapis.com/auth/calendar.events"
            buttonText="Sign in with Google"
            onSuccess={onSuccess}
            onError={onFailure}
          />
        </div> */}
        <GoogleLoginButton onSuccess={onSuccess} onFailure={onFailure} />
      </GoogleOAuthProvider>

      <Dialog
        cancelButton={translation.common.cancel}
        confirmButton={eventTranslation.signIn}
        open={openEventLoginModal.isOpen}
        onCancel={() => {
          setOpenEventLoginModal({
            isOpen: false,
            type: null,
            value: false,
          });
        }}
        onConfirm={() => {
          setOpenEventLoginModal({
            ...openEventLoginModal,
            isOpen: false,
          });
          if (openEventLoginModal.type === "isMSTeamMeeting") {
            handleMSLogin({ type: "isMSTeamMeeting", value: true });
          } else if (openEventLoginModal.type === "isGoogleMeetMeeting") {
            googleLoginHandler();
          }
        }}
        closeOnOutsideClick={false}
        content={
          <div>
            <Flex wrap>
              {openEventLoginModal.type === "isMSTeamMeeting"
                ? eventTranslation.MSTeamsMeeting.signInConfirmation
                : eventTranslation.googleMeetMeeting.signInConfirmation}
            </Flex>
          </div>
        }
        header={eventTranslation.signIn}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setOpenEventLoginModal({
              isOpen: false,
              type: null,
              value: false,
            });
          },
        }}
      />

      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton={translation.common.authExpire.button}
        content={
          <p className="refreshMsg">{translation.common.authExpire.message}</p>
        }
        header={translation.common.authExpire.header}
      />
      <EventFilterModal
        {...filterModalProps}
        onHandlePortal={(data) => {
          handlePortal(data);
        }}
      />

      {isMobileView ? (
        <div className="Attendance Whiteboard s-ctr">
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
          />

          <Layout className="discover-event-wrapper">
            <EventsNavBar {...props} isMobileView={isMobileView} />
            <RegistrationModal
              visible={isRegModalVisible}
              onCancel={setIsRegModalVisible}
              data={!!selectedEvent && selectedEvent.attendees}
              user={props.user}
            />
            <Layout
              className="tour-discover-event"
              style={{ alignItems: (isMobileView || isTabletView) && "end" }}
            >
              <CustomProgressIndicator isLoading={isUpdating || isLoading} />
              <div className={isMobileView ? "mobile-wrapper" : "wrapper"}>
                <Flex
                  space="between"
                  wrap
                  hAlign="center"
                  style={{ justifyContent: "space-around" }}
                >
                  <div
                    className="event-title"
                    style={{
                      // marginTop: "8px",
                      marginBottom: "11px",
                      marginLeft: "30px",
                    }}
                  >
                    {eventsTranslation.header}
                  </div>
                  <Flex
                    wrap
                    vAlign="center"
                    gap="gap.medium"
                    style={{
                      rowGap: "5px",
                      marginTop: isMobileView ? "10px" : "",
                    }}
                    hAlign="center"
                    className="tour-discover-event-filter"
                  >
                    <Checkbox
                      label={eventsTranslation.liveFilter}
                      toggle
                      style={{ fontWeight: 600 }}
                      checked={isLive}
                      onClick={() => {
                        setIsLive(!isLive);
                      }}
                    />

                    {/* <UserColumnFilter /> */}
                  </Flex>
                </Flex>
                {isFilterApplied ? <UserFilter /> : null}
                {eventData.length ? (
                  <div className="discover-event-cards-wrapper">
                    <Flex column>
                      <Flex wrap>
                        {eventData.map((data) => (
                          <Flex
                            key={data.id}
                            style={{
                              width: `${100 / selectedColumns}%`,
                              minWidth: "100%",
                              padding: "0 10px",
                              position: "relative",
                            }}
                          >
                            <Event
                              {...props}
                              type={data.isRegistered ? "cancel" : "discover"}
                              data={data}
                              tags={tags}
                              categories={categories}
                              updateFavourite={(data) => updateFavourite(data)}
                              onRegister={() => {
                                localStorage.setItem(
                                  "currentEvent",
                                  JSON.stringify(data)
                                );
                                setCurrentEvent(data);
                                setIsUpdating(true);
                                // if (data.isGoogleMeetMeeting) {
                                //   handleLogin("isGoogleMeetMeeting", true);
                                // } else {
                                //   registerEvent();
                                // }
                                registerEvent();
                              }}
                              onCancelRegistration={() =>
                                cancelRegistration(data)
                              }
                              attendeesModal={(data) => {
                                setSelectedEvent(data);
                                setIsRegModalVisible(true);
                              }}
                              selectedColumns={selectedColumns}
                              loading={{
                                isDeleting: false,
                                isLoading: isUpdating || isLoading,
                              }}
                            />
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </div>
                ) : (
                  !(isUpdating || isLoading) && (
                    <div
                      className="wrapper"
                      style={{ textAlign: "center", marginTop: 82 }}
                    >
                      <ResponsiveImage
                        className="events-pana"
                        src={"/Images/Events/events-pana.svg"}
                      />
                      <h2 style={{ marginTop: 20 }}>
                        {eventsTranslation.noEvents}
                      </h2>
                    </div>
                  )
                )}
              </div>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="Attendance Whiteboard s-ctr">
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
            isDiscoverEventsPage={true}
            events={eventData}
          />
          <Layout className="discover-event-wrapper">
            <EventsNavBar {...props} />
            <RegistrationModal
              visible={isRegModalVisible}
              onCancel={setIsRegModalVisible}
              data={!!selectedEvent && selectedEvent.attendees}
              user={props.user}
            />
            <Layout className="tour-discover-event">
              <CustomProgressIndicator isLoading={isUpdating || isLoading} />
              <div className="wrapper">
                <Flex space="between" wrap hAlign="center">
                  <div
                    className="event-title"
                    style={{ marginBottom: isTabletView && "0px" }}
                  >
                    {eventsTranslation.header}
                  </div>
                  <Flex
                    wrap
                    vAlign="center"
                    gap="gap.medium"
                    style={{
                      rowGap: "5px",
                      marginTop: isMobileView ? "10px" : "",
                    }}
                    hAlign="center"
                    className="tour-discover-event-filter"
                  >
                    <Checkbox
                      label={eventsTranslation.liveFilter}
                      toggle
                      style={{ fontWeight: 600 }}
                      checked={isLive}
                      onClick={() => {
                        setIsLive(!isLive);
                      }}
                    />

                    {isTabletView ? null : <UserColumnFilter />}
                  </Flex>
                </Flex>
                {isFilterApplied ? <UserFilter /> : null}
                {eventData.length ? (
                  <div className="discover-event-cards-wrapper">
                    <Flex column>
                      <Flex wrap>
                        {eventData.map((data) => (
                          <Flex
                            key={data.id}
                            style={{
                              width: isTabletView
                                ? "50%"
                                : `${100 / selectedColumns}%`,
                              minWidth: isTabletView ? "244px" : "260px",
                              padding: "0 10px",
                              position: "relative",
                            }}
                          >
                            <Event
                              {...props}
                              type={data.isRegistered ? "cancel" : "discover"}
                              data={data}
                              tags={tags}
                              categories={categories}
                              updateFavourite={(data) => updateFavourite(data)}
                              onRegister={() => {
                                setCurrentEvent(data);
                                setIsUpdating(true);
                                localStorage.setItem(
                                  "currentEvent",
                                  JSON.stringify(data)
                                );
                                // if (data.isGoogleMeetMeeting) {
                                //   handleLogin("isGoogleMeetMeeting", true);
                                // } else {
                                //   registerEvent();
                                // }
                                registerEvent();
                              }}
                              onCancelRegistration={() =>
                                cancelRegistration(data)
                              }
                              attendeesModal={(data) => {
                                setSelectedEvent(data);
                                setIsRegModalVisible(true);
                              }}
                              selectedColumns={selectedColumns}
                              loading={{
                                isDeleting: false,
                                isLoading: isUpdating || isLoading,
                              }}
                            />
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </div>
                ) : (
                  !(isUpdating || isLoading) && (
                    <div
                      className="wrapper"
                      style={{ textAlign: "center", marginTop: 82 }}
                    >
                      <ResponsiveImage
                        className="events-pana"
                        src={"/Images/Events/events-pana.svg"}
                      />
                      <h2 style={{ marginTop: 20 }}>
                        {eventsTranslation.noEvents}
                      </h2>
                    </div>
                  )
                )}
              </div>
            </Layout>
          </Layout>
        </div>
      )}
    </>
  );
};

export default withTranslation()(DiscoverEvents);
