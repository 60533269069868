import React, { useEffect, useState } from "react";
import { TextField } from "@fluentui/react";
import {
  Button,
  CallVideoIcon,
  Card,
  CardBody,
  Checkbox,
  CloseIcon,
  Dialog,
  Flex,
  Image,
  InfoIcon,
  ItalicIcon,
  PollIcon,
  SplitButton,
  Text,
  Tooltip,
  VolumeIcon,
  Dropdown,
  Segment,
} from "@fluentui/react-northstar";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Icon } from "@fluentui/react/lib/Icon";
import { useMemo } from "react";

import Imgart from "../../../../../Assets/images/subjects/art.png";
import Imgcivil from "../../../../../Assets/images/subjects/civil.png";
import Imghistory from "../../../../../Assets/images/subjects/history.png";
import ImgnaturalScience from "../../../../../Assets/images/subjects/natural-science.png";
import Imgphilosophy from "../../../../../Assets/images/subjects/philosophy.png";
import ImgscienceExact from "../../../../../Assets/images/subjects/science-exact.png";
import Imgtech from "../../../../../Assets/images/subjects/tech.png";
import Imgother from "../../../../../Assets/images/subjects/other.png";
import DefaultGroupImage from "../../../../../Assets/images/group-default.png";
import ChatGPTIcon from "../../../../../Assets/images/svg/chatGpt.svg";

import FeedbackDialog from "../../shared/FeedbackDialog";
import FeedbackMagicBar from "../../shared/MagicBar";

const TYPE_ICONS = {
  ANY: <Icon iconName="PieSingle" />,
  TEXT: <ItalicIcon outline />,
  AUDIO: <VolumeIcon outline />,
  VIDEO: <CallVideoIcon outline />,
  POLL: <PollIcon outline />,
};

const FeedbackQuestions = (props) => {
  const {
    feedbackQuestion,
    setFeedbackQuestion,
    isValidateFields,
    description,
    setDescription,
    feedbackType,
    SHARE_WITH_OPTIONS,
    FEEDBACK_TYPES,
    sharedWith,
    setSharedWith,
    isLoadingData,
    selectedShareWithOption,
    setSelectedShareWithOption,
    groupsData,
    classroomsData,
    selectedDateTime,
    setSelectedDateTime,
    user,
    setUser,
    questions,
    setQuestions,
    isMixMode,
    setIsMixMode,
    initialType,
    typeOfFeedback,
    isMultiItemFeedback,
    questionLimit,
    audioQuestionLimit,
    videoQuestionLimit,
    feedbackData,
    setIsChangedForm,
    t,
    classroomNames,
    filteredClassRooms,
    setFilteredClassrooms,
    handleFilterClassrooms,
    filteredGroups,
    groupNames,
    handleFilterGroups,
  } = props;

  const [tempData, setTempData] = useState(null);
  const [isCancelMixMode, setIsCancelMixMode] = useState(false);
  const [sortedClassRooms, setSortedClassRooms] = useState([]);

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.feedbackForm;
  const feedbackTypesTranslation = translation.body.feedbackTypes;

  const addMoreOptionHandler = ({ question }) => {
    if (question.pollOptions.length < 5) {
      let maxOptionNumber = 1;
      question.pollOptions.forEach((item) => {
        if (item.option > maxOptionNumber) {
          maxOptionNumber = item.option;
        }
      });
      setQuestions((prev) =>
        prev.map((prevQuestion) =>
          question.id === prevQuestion.id
            ? {
                ...prevQuestion,
                pollOptions: [
                  ...prevQuestion.pollOptions,
                  { option: maxOptionNumber + 1, value: "" },
                ],
              }
            : prevQuestion
        )
      );
      setIsChangedForm(true);
    }
  };

  const deleteOptionHandler = ({ id, option }) => {
    setQuestions((prev) =>
      prev.map((prevQuestion) =>
        id === prevQuestion.id
          ? {
              ...prevQuestion,
              pollOptions: prevQuestion.pollOptions.filter(
                (item) => item.option !== option.option
              ),
            }
          : prevQuestion
      )
    );
    setIsChangedForm(true);
  };

  const getDefaultClassroomImage = (title) => {
    if (title) {
      return title?.includes("Art")
        ? Imgart
        : title.includes("Civismo")
        ? Imgcivil
        : title.includes("Hist")
        ? Imghistory
        : title.includes("Science")
        ? ImgnaturalScience
        : title.includes("losof")
        ? Imgphilosophy
        : title.includes("Ciencias Exactas")
        ? ImgscienceExact
        : title.includes("Tec")
        ? Imgtech
        : Imgother;
    } else {
      return Imgother;
    }
  };

  const classroomCard = ({ data }) => {
    return (
      <Card
        key={data.id}
        elevated
        className="fit-content all-feedback-card"
        fluid
        styles={{
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          width: "200px",
          height: "64px",
          margin: "10px",
          borderRadius: "7px",
        }}
      >
        <CardBody className="" style={{ padding: "10px" }}>
          <Flex space="between">
            <Flex>
              <Flex>
                <Image
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    borderRadius: "4px",
                  }}
                  src={
                    data.classroomsImageUrl
                      ? data.classroomsImageUrl + user.SASToken
                      : getDefaultClassroomImage(data.classroomTitle)
                  }
                  onError={(e) => {
                    e.target.src = Imgother;
                  }}
                />
              </Flex>
              <Flex column vAlign="start">
                <Tooltip
                  content={data.classroomTitle}
                  trigger={
                    <div
                      style={{
                        maxWidth: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data.classroomTitle}
                    </div>
                  }
                />
                <Flex hAlign="start">
                  <FeedbackDialog
                    button={
                      <Button
                        style={{
                          padding: 0,
                          minWidth: "70px",
                          fontSize: "12px",
                        }}
                        text
                        disabled={true}
                      >
                        <Text styles={{ fontSize: "12px", color: "black" }}>{`${
                          data.students.length || 0
                        } ${feedbackTranslation.members}`}</Text>
                      </Button>
                    }
                    header={feedbackTranslation.selectStudents}
                    headerAction={{
                      icon: <CloseIcon />,
                      title: commonTranslation.close,
                      onClick: () => {},
                    }}
                    onOpen={() => {
                      setTempData({ ...sharedWith });
                    }}
                    confirmButton={commonTranslation.done}
                    onConfirm={() => {
                      setSharedWith({ ...tempData });
                    }}
                    content={
                      <Flex column>
                        <Flex>
                          <Checkbox
                            checked={
                              tempData?.classrooms.find(
                                (classData) => classData.id === data.id
                              )?.students?.length === data.students?.length
                            }
                            onClick={(e, { checked }) => {
                              setTempData((prevData) => {
                                let classroom = tempData.classrooms.find(
                                  (item) => item.id === data.id
                                );
                                return {
                                  ...prevData,
                                  classrooms: checked
                                    ? classroom
                                      ? [
                                          ...prevData.classrooms.map((item) =>
                                            item.id === data.id
                                              ? {
                                                  id: data.id,
                                                  students: data.students,
                                                }
                                              : item
                                          ),
                                        ]
                                      : [
                                          ...prevData.classrooms,
                                          {
                                            id: data.id,
                                            students: data.students,
                                          },
                                        ]
                                    : [
                                        ...prevData.classrooms.filter(
                                          (item) => item.id !== data.id
                                        ),
                                      ],
                                };
                              });
                            }}
                            label={feedbackTranslation.allStudents}
                          />
                        </Flex>
                        <Flex wrap>
                          {data.students.length
                            ? data.students.map((sData, index) => (
                                <Flex.Item key={index}>
                                  {studentCard({
                                    student: sData,
                                    data,
                                  })}
                                </Flex.Item>
                              ))
                            : null}
                        </Flex>
                      </Flex>
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <Checkbox
                checked={
                  !!sharedWith.classrooms.find(
                    (classData) => classData.id === data.id
                  )
                  // Add below condition after student selection is done from API
                  // .students.length === data.students.length
                }
                onClick={(e, { checked }) => {
                  setSharedWith((prevData) => {
                    let classroom = sharedWith.classrooms.find(
                      (item) => item.id === data.id
                    );
                    return {
                      ...prevData,
                      classrooms: checked
                        ? classroom
                          ? [
                              ...prevData.classrooms.map((item) =>
                                item.id === data.id
                                  ? { id: data.id, students: data.students }
                                  : item
                              ),
                            ]
                          : [
                              ...prevData.classrooms,
                              { id: data.id, students: data.students },
                            ]
                        : [
                            ...prevData.classrooms.filter(
                              (item) => item.id !== data.id
                            ),
                          ],
                    };
                  });
                  setIsChangedForm(true);
                }}
              />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  };

  const groupCard = ({ data }) => {
    return (
      <Card
        elevated
        className="fit-content all-feedback-card"
        fluid
        styles={{
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          width: "200px",
          height: "64px",
          margin: "10px",
          borderRadius: "7px",
        }}
      >
        <CardBody className="" style={{ padding: "10px" }}>
          <Flex space="between">
            <Flex>
              <Flex>
                <Image
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    borderRadius: "4px",
                  }}
                  src={data.groupsImageUrl}
                  onError={(e) => {
                    e.target.src = DefaultGroupImage;
                  }}
                />
              </Flex>
              <Flex column vAlign="start">
                <Tooltip
                  content={data.groupTitle}
                  trigger={
                    <div
                      style={{
                        maxWidth: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data.groupTitle}
                    </div>
                  }
                />

                <Flex hAlign="start">
                  <FeedbackDialog
                    button={
                      <Button
                        style={{
                          padding: 0,
                          minWidth: "70px",
                          fontSize: "12px",
                        }}
                        text
                        disabled={true}
                      >
                        <Text styles={{ fontSize: "12px", color: "black" }}>{`${
                          data.students.length || 0
                        } ${feedbackTranslation.members}`}</Text>
                      </Button>
                    }
                    header={feedbackTranslation.selectMembers}
                    headerAction={{
                      icon: <CloseIcon />,
                      title: commonTranslation.close,
                      onClick: () => {},
                    }}
                    onOpen={() => {
                      setTempData({ ...sharedWith });
                    }}
                    confirmButton={commonTranslation.done}
                    onConfirm={() => {
                      setSharedWith({ ...tempData });
                    }}
                    content={
                      <Flex column>
                        <Flex>
                          <Checkbox
                            checked={
                              tempData?.groups.find(
                                (classData) => classData.id === data.id
                              )?.students?.length === data.students?.length
                            }
                            onClick={(e, { checked }) => {
                              setTempData((prevData) => {
                                let group = tempData.groups.find(
                                  (item) => item.id === data.id
                                );
                                return {
                                  ...prevData,
                                  groups: checked
                                    ? group
                                      ? [
                                          ...prevData.groups.map((item) =>
                                            item.id === data.id
                                              ? {
                                                  id: data.id,
                                                  students: data.students,
                                                }
                                              : item
                                          ),
                                        ]
                                      : [
                                          ...prevData.groups,
                                          {
                                            id: data.id,
                                            students: data.students,
                                          },
                                        ]
                                    : [
                                        ...prevData.groups.filter(
                                          (item) => item.id !== data.id
                                        ),
                                      ],
                                };
                              });
                            }}
                            label={feedbackTranslation.allMembers}
                          />
                        </Flex>
                        <Flex wrap>
                          {data.students.length
                            ? data.students.map((sData, index) => (
                                <Flex.Item key={index}>
                                  {memberCard({
                                    student: sData,
                                    data,
                                  })}
                                </Flex.Item>
                              ))
                            : null}
                        </Flex>
                      </Flex>
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <Checkbox
                checked={
                  !!sharedWith.groups.find(
                    (groupsData) => groupsData.id === data.id
                  )
                  // Add below condition after student selection indone from API
                  //.students?.length === data.students.length
                }
                onClick={(e, { checked }) => {
                  setSharedWith((prevData) => {
                    let group = sharedWith.groups.find(
                      (item) => item.id === data.id
                    );
                    return {
                      ...prevData,
                      groups: checked
                        ? group
                          ? [
                              ...prevData.groups.map((item) =>
                                item.id === data.id
                                  ? { id: data.id, students: data.students }
                                  : item
                              ),
                            ]
                          : [
                              ...prevData.groups,
                              { id: data.id, students: data.students },
                            ]
                        : [
                            ...prevData.groups.filter(
                              (item) => item.id !== data.id
                            ),
                          ],
                    };
                  });
                  setIsChangedForm(true);
                }}
              />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  };

  const memberCard = ({ student, data }) => {
    return (
      <Card
        elevated
        className="fit-content all-feedback-card"
        fluid
        styles={{
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          width: "223px",
          height: "50px",
          margin: "10px",
          borderRadius: "7px",
        }}
      >
        <CardBody className="" style={{ padding: "10px" }}>
          <Flex space="between" vAlign="center">
            <Flex>
              <Flex>
                <Image
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    borderRadius: "4px",
                  }}
                  src={student.imageUrl}
                />
              </Flex>
              <Flex column vAlign="start">
                <Text style={{ fontSize: "14px" }}>{student.name}</Text>
              </Flex>
            </Flex>
            <Flex>
              <Checkbox
                checked={
                  !!tempData?.groups
                    ?.find((item) => item.id === data.id)
                    ?.students?.some(
                      (d) => d.studentEmailId === student.studentEmailId
                    )
                }
                onClick={(e, { checked }) => {
                  if (checked) {
                    setTempData((prevData) => {
                      let group = prevData.groups?.find(
                        (item) => item.id === data.id
                      );
                      return {
                        ...prevData,
                        groups: group
                          ? prevData.groups?.map((item) =>
                              item.id === data.id
                                ? {
                                    ...item,
                                    students: [...item.students, student],
                                  }
                                : item
                            )
                          : [
                              ...prevData.groups,
                              {
                                id: data.id,
                                students: [student],
                              },
                            ],
                      };
                    });
                  } else {
                    setTempData((prevData) => ({
                      ...prevData,
                      groups: prevData.groups?.map((item) =>
                        item.id === data.id
                          ? {
                              ...item,
                              students: item.students?.filter(
                                (item) =>
                                  item.studentEmailId !== student.studentEmailId
                              ),
                            }
                          : item
                      ),
                    }));
                  }
                }}
              />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  };
  const studentCard = ({ student, data }) => {
    return (
      <Card
        elevated
        className="fit-content all-feedback-card"
        fluid
        styles={{
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          width: "223px",
          height: "50px",
          margin: "10px",
          borderRadius: "7px",
        }}
      >
        <CardBody className="" style={{ padding: "10px" }}>
          <Flex space="between" vAlign="center">
            <Flex>
              <Flex>
                <Image
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    borderRadius: "4px",
                  }}
                  src={student.imageUrl}
                />
              </Flex>
              <Flex column vAlign="start">
                <Text style={{ fontSize: "14px" }} weight="semibold">
                  {student.name}
                </Text>
              </Flex>
            </Flex>
            <Flex>
              <Checkbox
                checked={
                  !!tempData?.classrooms
                    ?.find((item) => item.id === data.id)
                    ?.students?.some(
                      (d) => d.studentEmailId === student.studentEmailId
                    )
                }
                onClick={(e, { checked }) => {
                  if (checked) {
                    setTempData((prevData) => {
                      let classroom = prevData.classrooms?.find(
                        (item) => item.id === data.id
                      );
                      return {
                        ...prevData,
                        classrooms: classroom
                          ? prevData.classrooms?.map((item) =>
                              item.id === data.id
                                ? {
                                    ...item,
                                    students: [...item.students, student],
                                  }
                                : item
                            )
                          : [
                              ...prevData.classrooms,
                              {
                                id: data.id,
                                students: [student],
                              },
                            ],
                      };
                    });
                  } else {
                    setTempData((prevData) => ({
                      ...prevData,
                      classrooms: prevData.classrooms?.map((item) =>
                        item.id === data.id
                          ? {
                              ...item,
                              students: item.students?.filter(
                                (item) =>
                                  item.studentEmailId !== student.studentEmailId
                              ),
                            }
                          : item
                      ),
                    }));
                  }
                }}
              />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  };

  const mixModeChangeHandler = (e, { checked }) => {
    if (
      !checked &&
      questions
        .filter(({ isDeleted }) => !isDeleted)
        .some(({ questionType }) => questionType !== initialType)
    ) {
      setIsCancelMixMode(true);
      return;
    }

    setIsMixMode(checked);
  };

  const addQuestionHandler = ({ type }) => {
    const id = Math.ceil(10000 * Math.random());
    setQuestions((prev) => [
      ...prev,
      {
        id: `NEW_QUESTION_${id}`,
        order: 0,
        questionType: type,
        questionTitle: "",
        isNew: true,
        pollOptions:
          type === FEEDBACK_TYPES.poll
            ? [
                { option: 1, value: "" },
                { option: 2, value: "" },
                { option: 3, value: "" },
              ]
            : null,
      },
    ]);
    setIsChangedForm(true);
  };

  const deleteQuestionHandler = ({ id }) => {
    setQuestions((prev) =>
      prev.map((question) =>
        question.id === id ? { ...question, isDeleted: true } : question
      )
    );
    setIsChangedForm(true);
  };

  const items = useMemo(
    () => [
      {
        key: "text",
        type: FEEDBACK_TYPES.text,
        itemValue: feedbackTranslation.addTextQuestion,
        content: feedbackTranslation.addTextQuestion,
        icon: <ItalicIcon outline />,
      },
      {
        key: "audio",
        type: FEEDBACK_TYPES.audio,
        itemValue: feedbackTranslation.addAudioQuestion,
        content: feedbackTranslation.addAudioQuestion,
        icon: <VolumeIcon outline />,
        tooltip: `${feedbackTranslation.audioQuestionValidation} : ${audioQuestionLimit}`,
        children: (MenuItem, props) => {
          const { tooltip = "", key, ...rest } = props;
          return (
            <Tooltip
              content={tooltip}
              key={key}
              dismissOnContentMouseEnter
              trigger={<MenuItem {...rest} disabledFocusable />}
            />
          );
        },
        disabled: Array.isArray(questions)
          ? questions.filter(
              ({ questionType, isDeleted }) =>
                questionType === FEEDBACK_TYPES.audio && !isDeleted
            ).length >= audioQuestionLimit
          : false,
      },
      {
        key: "video",
        type: FEEDBACK_TYPES.video,
        itemValue: feedbackTranslation.addVideoQuestion,
        content: feedbackTranslation.addVideoQuestion,
        icon: <CallVideoIcon outline />,
        tooltip: `${feedbackTranslation.videoQuestionValidation} : ${videoQuestionLimit}`,
        children: (MenuItem, props) => {
          const { tooltip = "", key, ...rest } = props;
          return (
            <Tooltip
              content={tooltip}
              key={key}
              dismissOnContentMouseEnter
              trigger={<MenuItem {...rest} disabledFocusable />}
            />
          );
        },
        disabled: Array.isArray(questions)
          ? questions.filter(
              ({ questionType, isDeleted }) =>
                questionType === FEEDBACK_TYPES.video && !isDeleted
            ).length >= videoQuestionLimit
          : false,
      },
      {
        key: "poll",
        type: FEEDBACK_TYPES.poll,
        itemValue: feedbackTranslation.addPollQuestion,
        content: feedbackTranslation.addPollQuestion,
        icon: <PollIcon outline />,
      },
    ],
    [questions]
  );

  const onQuestionWrapDefaultLabelRenderer = ({
    props,
    defaultRender,
    index,
    type,
    isShowCharacterLength,
    currentLength,
    maxLength,
  }) => {
    return (
      <>
        <Flex space="between">
          <Flex vAlign="center">
            {defaultRender(props)}
            {TYPE_ICONS[type]}
          </Flex>
          <span>
            {defaultRender({
              ...props,
              label: `${index + 1}/${questionLimit}`,
              required: false,
            })}
          </span>
          {isShowCharacterLength && (
            <span
              style={{
                position: "absolute",
                right: "5px",
                bottom: 0,
                fontSize: "11px",
                zIndex: 1,
              }}
            >
              {currentLength} / {maxLength}
            </span>
          )}
        </Flex>
      </>
    );
  };

  const onWrapDefaultLabelRenderer = ({
    props,
    defaultRender,
    isShowCharacterLength,
    currentLength,
    maxLength,
  }) => {
    return (
      <>
        <Flex space="between">
          <Flex vAlign="center">{defaultRender(props)}</Flex>

          {isShowCharacterLength && (
            <span
              style={{
                position: "absolute",
                right: "5px",
                bottom: 0,
                fontSize: "11px",
                zIndex: 1,
              }}
            >
              {currentLength} / {maxLength}
            </span>
          )}
        </Flex>
      </>
    );
  };

  const cancelMixModeHandler = () => {
    setQuestions((prev) =>
      prev.filter(({ questionType }) => questionType === initialType)
    );
    setIsChangedForm(true);
    setIsCancelMixMode(false);
  };

  const addAIQuestionsHandler = (data) => {
    const {
      title,
      description: generatedDescriptionn,
      questions: questionsList,
      type,
    } = data;
    setDescription(generatedDescriptionn);
    setFeedbackQuestion(title);

    let isNotMultiItem =
      [FEEDBACK_TYPES.audio, FEEDBACK_TYPES.video].includes(feedbackType) ||
      (FEEDBACK_TYPES.any === feedbackType && !isMixMode);

    // if (Array.isArray(questionsList) && questionsList.length) {
    //   if (isNotMultiItem) {
    //     setFeedbackQuestion(questionsList[0]);
    //   } else {
    //     setFeedbackQuestion(title);
    //   }
    // }

    const formattedQuestions = Array.isArray(questionsList)
      ? questionsList.map((item, index) => {
          return {
            id: `NEW_QUESTION_${index}`,
            questionType: feedbackType,
            questionTitle:
              type === "poll" ? item.question : isNotMultiItem ? title : item,
            isNew: true,
            pollOptions:
              type === "poll"
                ? Array.isArray(item.options)
                  ? item.options.map((option, ind) => ({
                      option: ind + 1,
                      value: option,
                    }))
                  : []
                : null,
          };
        })
      : questions;
    setQuestions(formattedQuestions);
    setIsChangedForm(true);
  };

  useEffect(() => {
    if (filteredClassRooms.length) {
      const sortedClassRooms = filteredClassRooms.reduce((acc, classRoom) => {
        if (sharedWith.classrooms.includes(classRoom.id)) {
          acc.unshift(classRoom); // Add to the beginning of the array
        } else {
          acc.push(classRoom); // Add to the end of the array
        }
        return acc;
      }, []);
      setSortedClassRooms(sortedClassRooms);
    }
  }, [filteredClassRooms, sharedWith.classrooms]);

  return (
    <>
      <Dialog
        open={isCancelMixMode}
        onConfirm={cancelMixModeHandler}
        onCancel={() => setIsCancelMixMode(false)}
        header={feedbackTranslation.cancelMixMode}
        content={
          <Flex column>
            <Text>{`${feedbackTranslation.removeOtherTypeQuestionConfirmation}`}</Text>
          </Flex>
        }
        confirmButton={commonTranslation.yes}
        cancelButton={commonTranslation.no}
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setIsCancelMixMode(false);
          },
        }}
        styles={{ maxWidth: "600px" }}
      />
      <Flex column style={{ marginTop: "15px" }}>
        <Flex hAlign="end" vAlign="center" wrap>
          <FeedbackMagicBar
            user={user}
            setUser={setUser}
            FEEDBACK_TYPES={FEEDBACK_TYPES}
            isMixMode={isMixMode}
            type={feedbackType}
            responseHandler={(questions) => {
              addAIQuestionsHandler(questions);
            }}
            button={({ onClickHandler }) => (
              <Button
                primary
                onClick={onClickHandler}
                text
                className="chatgpt-icon-button"
              >
                <Tooltip
                  trigger={<img src={ChatGPTIcon} className="chatgpt-icon" />}
                  content={feedbackTranslation.generateFeedbackWithAI}
                />
              </Button>
            )}
          />
          {isMultiItemFeedback && (
            <Flex wrap vAlign="center">
              <Checkbox
                styles={{ alignItems: "center" }}
                label={feedbackTranslation.mixedMode}
                toggle
                checked={isMixMode}
                onChange={mixModeChangeHandler}
                disabled={
                  feedbackData?.id &&
                  FEEDBACK_TYPES.any === feedbackType &&
                  isMixMode
                }
              />
              <Tooltip
                trigger={<InfoIcon outline size="small" />}
                content={feedbackTranslation.unableToCancelMixMode}
              />
            </Flex>
          )}
        </Flex>
        <Flex.Item grow>
          <TextField
            value={feedbackQuestion}
            onChange={(e, value) => {
              setFeedbackQuestion(value);
              setIsChangedForm(true);
            }}
            style={{
              background: "#F5F5F5",
              borderRadius: "5px",
              width: "100%",
              position: "relative",
            }}
            styles={{
              fieldGroup: {
                height: "40px",
              },
            }}
            borderless
            label={
              isMultiItemFeedback
                ? feedbackTranslation.feedbackTitle
                : feedbackTranslation.questionLabel
            }
            errorMessage={
              isValidateFields
                ? !feedbackQuestion.length
                  ? isMultiItemFeedback
                    ? feedbackTranslation.feedbackTitleValidation
                    : feedbackTranslation.questionValidation
                  : ""
                : ""
            }
            required
            maxLength={254}
            onRenderLabel={(props, defaultRender) =>
              onWrapDefaultLabelRenderer({
                props,
                defaultRender,
                currentLength: Number(feedbackQuestion.length) || 0,
                maxLength: 254,
                isShowCharacterLength: true,
              })
            }
          />
        </Flex.Item>
        <Flex.Item grow>
          <TextField
            value={description}
            onChange={(e, value) => {
              setDescription(value);
              setIsChangedForm(true);
            }}
            style={{
              background: "#F5F5F5",
              borderRadius: "5px",
              width: "100%",
            }}
            borderless
            label={feedbackTranslation.descriptionLabel}
            styles={{
              root: {
                marginTop: "5px",
              },
              field: {
                padding: "12px 8px",
              },
            }}
            autoAdjustHeight
            multiline={true}
            maxLength={65000}
            // onRenderLabel={(props, defaultRender) =>
            //   onWrapDefaultLabelRenderer({
            //     props,
            //     defaultRender,
            //     currentLength: Number(description.length) || 0,
            //     maxLength: 65000,
            //     isShowCharacterLength: true,
            //   })
            // }
          />
        </Flex.Item>
        {isMultiItemFeedback && (
          <Flex column>
            <Flex column className="feedback-questions">
              {questions
                .filter(({ isDeleted }) => !isDeleted)
                .map((question, index) => (
                  <Flex key={question.id} column className="question-wrapper">
                    <Flex.Item grow>
                      <TextField
                        value={question.questionTitle}
                        onChange={(e, value) => {
                          setQuestions((prev) =>
                            prev.map((prevQuestion) =>
                              question.id === prevQuestion.id
                                ? { ...prevQuestion, questionTitle: value }
                                : prevQuestion
                            )
                          );
                          setIsChangedForm(true);
                        }}
                        styles={{
                          fieldGroup: {
                            height: "40px",
                          },
                        }}
                        style={{
                          background: "#F5F5F5",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        borderless
                        label={`${feedbackTranslation.question} ${index + 1}`}
                        onRenderLabel={(props, defaultRender) =>
                          onQuestionWrapDefaultLabelRenderer({
                            props,
                            defaultRender,
                            index,
                            type: question.questionType,
                            isShowCharacterLength: true,
                            maxLength: 254,
                            currentLength:
                              Number(question.questionTitle?.length) || 0,
                          })
                        }
                        errorMessage={
                          isValidateFields
                            ? !question.questionTitle?.length
                              ? feedbackTranslation.questionValidation
                              : ""
                            : ""
                        }
                        required
                        maxLength={254}
                      />
                    </Flex.Item>
                    {question.questionType === FEEDBACK_TYPES.poll &&
                    question.pollOptions?.length ? (
                      <div>
                        {question.pollOptions.map((item, ind) => (
                          <Flex key={item.option}>
                            <Flex fill>
                              <TextField
                                value={item.value}
                                onChange={(e, value) => {
                                  setQuestions((prev) =>
                                    prev.map((prevQuestion) =>
                                      question.id === prevQuestion.id
                                        ? {
                                            ...prevQuestion,
                                            pollOptions:
                                              prevQuestion.pollOptions.map(
                                                (data) =>
                                                  data.option === item.option
                                                    ? { ...data, value }
                                                    : data
                                              ),
                                          }
                                        : prevQuestion
                                    )
                                  );
                                  setIsChangedForm(true);
                                }}
                                style={{
                                  background: "#F5F5F5",
                                  borderRadius: "5px",
                                  width: "100%",
                                }}
                                styles={{ root: { width: "100%" } }}
                                borderless
                                label={`${feedbackTranslation.option} ${
                                  ind + 1
                                }`}
                                errorMessage={
                                  isValidateFields
                                    ? !item.value?.length
                                      ? feedbackTranslation.optionRequired
                                      : ""
                                    : ""
                                }
                              />
                            </Flex>

                            <Flex.Item align="end">
                              <Button
                                className="delete-option-btn"
                                title={feedbackTranslation.deleteOption}
                                disabled={question.pollOptions.length === 1}
                                content={
                                  <Icon
                                    iconName="Delete"
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                text
                                onClick={() => {
                                  deleteOptionHandler({
                                    id: question.id,
                                    option: item,
                                  });
                                }}
                              />
                            </Flex.Item>
                          </Flex>
                        ))}
                      </div>
                    ) : null}
                    <Flex
                      vAlign="center"
                      space="between"
                      className="question-action-wrapper"
                    >
                      <Flex>
                        {question.questionType === FEEDBACK_TYPES.poll && (
                          <Flex.Item grow align="end">
                            <Button
                              content={feedbackTranslation.addMoreOption}
                              disabled={question.pollOptions.length === 5}
                              onClick={() => addMoreOptionHandler({ question })}
                            />
                          </Flex.Item>
                        )}
                      </Flex>
                      <Flex>
                        <Button
                          content={feedbackTranslation.deleteQuestion}
                          onClick={() =>
                            deleteQuestionHandler({ id: question.id })
                          }
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
            </Flex>

            <Flex hAlign="end" column>
              <SplitButton
                menu={items}
                button={{
                  content: items[0].content,
                  key: items[0].key,
                  icon: items[0].icon,
                  "aria-roledescription": "splitbutton",
                  "aria-describedby": "instruction-message-icon-content",
                }}
                onMainButtonClick={() =>
                  addQuestionHandler({ type: FEEDBACK_TYPES.text })
                }
                onMenuItemClick={(e, { type }) => {
                  addQuestionHandler({ type });
                }}
                disabled={
                  questions.filter(({ isDeleted }) => !isDeleted).length >=
                  questionLimit
                }
                toggleButton={{
                  "aria-label": feedbackTranslation.moreOptions,
                }}
                className="add-new-question-btn"
              />
            </Flex>
          </Flex>
        )}
        <Flex column style={{ marginTop: "15px" }}>
          <Text color="black" weight="semibold">
            {feedbackTranslation.shareFeedbackWith}
          </Text>
          <Flex gap="gap.medium" wrap>
            {user?.role?.toLowerCase() !== "student" ? (
              <Flex vAlign="center">
                <Tooltip
                  content={feedbackTranslation.unSelectClassrooms}
                  trigger={
                    <Checkbox
                      checked={Boolean(sharedWith.classrooms.length)}
                      style={{ padding: "0 0 0 5px" }}
                      disabled={
                        sharedWith.isEveryoneInTheOrganization ||
                        sharedWith.isParentsInTheOrganization ||
                        sharedWith.isTeachersInTheOrganization ||
                        sharedWith.isStudentsInTheOrganization ||
                        isLoadingData ||
                        !classroomsData?.length
                      }
                      onClick={(_, { checked }) => {
                        if (!checked) {
                          setSharedWith((prevData) => ({
                            ...prevData,
                            classrooms: [],
                          }));
                          setIsChangedForm(true);
                        }
                      }}
                    />
                  }
                />
                <Tooltip
                  content={feedbackTranslation.classroomsList}
                  trigger={
                    <Button
                      text
                      onClick={() => {
                        setSelectedShareWithOption(
                          SHARE_WITH_OPTIONS.classrooms
                        );
                      }}
                      className={`share-with-option ${
                        selectedShareWithOption ===
                        SHARE_WITH_OPTIONS.classrooms
                          ? "selected-share-with-option"
                          : ""
                      }`}
                      disabled={
                        sharedWith.isEveryoneInTheOrganization ||
                        sharedWith.isParentsInTheOrganization ||
                        sharedWith.isTeachersInTheOrganization ||
                        sharedWith.isStudentsInTheOrganization ||
                        isLoadingData ||
                        !classroomsData?.length
                      }
                    >
                      {feedbackTranslation.classrooms}
                    </Button>
                  }
                />
              </Flex>
            ) : null}
            <Flex vAlign="center">
              <Tooltip
                content={feedbackTranslation.unSelectGroup}
                trigger={
                  <Checkbox
                    checked={!!sharedWith?.groups?.length}
                    style={{ padding: "0 0 0 5px" }}
                    disabled={
                      sharedWith.isEveryoneInTheOrganization ||
                      sharedWith.isParentsInTheOrganization ||
                      sharedWith.isTeachersInTheOrganization ||
                      sharedWith.isStudentsInTheOrganization ||
                      isLoadingData ||
                      !groupsData?.length
                    }
                    onClick={(_, { checked }) => {
                      if (!checked) {
                        setSharedWith((prevData) => ({
                          ...prevData,
                          groups: [],
                        }));
                        setIsChangedForm(true);
                      }
                    }}
                  />
                }
              />
              <Tooltip
                content={feedbackTranslation.groupList}
                trigger={
                  <Button
                    text
                    onClick={() => {
                      setSelectedShareWithOption(SHARE_WITH_OPTIONS.group);
                    }}
                    className={`share-with-option ${
                      selectedShareWithOption === SHARE_WITH_OPTIONS.group
                        ? "selected-share-with-option"
                        : ""
                    }`}
                    disabled={
                      sharedWith.isEveryoneInTheOrganization ||
                      sharedWith.isParentsInTheOrganization ||
                      sharedWith.isTeachersInTheOrganization ||
                      sharedWith.isStudentsInTheOrganization ||
                      isLoadingData ||
                      !groupsData?.length
                    }
                  >
                    {feedbackTranslation.groups}
                  </Button>
                }
              />
            </Flex>
            {user?.role?.toLowerCase() === "superadmin" ? (
              <Flex vAlign="center">
                <Checkbox
                  label={feedbackTranslation.parentsInOrganization}
                  checked={sharedWith.isParentsInTheOrganization}
                  disabled={
                    sharedWith.classrooms?.length ||
                    sharedWith.groups?.length ||
                    sharedWith.isEveryoneInTheOrganization ||
                    sharedWith.isTeachersInTheOrganization ||
                    sharedWith.isStudentsInTheOrganization
                  }
                  onClick={(e, { checked }) => {
                    setSharedWith((prevdata) => ({
                      ...prevdata,
                      isParentsInTheOrganization: checked,
                      classrooms: checked ? [] : prevdata.classrooms,
                      groups: checked ? [] : prevdata.groups,
                    }));
                    setIsChangedForm(true);
                    setSelectedShareWithOption(null);
                  }}
                />
              </Flex>
            ) : null}
            {!["student", "parent"].includes(user?.role?.toLowerCase()) ? (
              <Flex vAlign="center">
                <Checkbox
                  label={feedbackTranslation.teachersInOrganization}
                  checked={sharedWith.isTeachersInTheOrganization}
                  disabled={
                    sharedWith.classrooms?.length ||
                    sharedWith.groups?.length ||
                    sharedWith.isEveryoneInTheOrganization ||
                    sharedWith.isParentsInTheOrganization ||
                    sharedWith.isStudentsInTheOrganization
                  }
                  onClick={(e, { checked }) => {
                    setSharedWith((prevdata) => ({
                      ...prevdata,
                      isTeachersInTheOrganization: checked,
                      classrooms: checked ? [] : prevdata.classrooms,
                      groups: checked ? [] : prevdata.groups,
                    }));
                    setIsChangedForm(true);
                    setSelectedShareWithOption(null);
                  }}
                />
              </Flex>
            ) : null}
            {!["parent"].includes(user?.role?.toLowerCase()) ? (
              <Flex vAlign="center">
                <Checkbox
                  label={feedbackTranslation.studentsInOrganization}
                  checked={sharedWith.isStudentsInTheOrganization}
                  disabled={
                    sharedWith.classrooms?.length ||
                    sharedWith.groups?.length ||
                    sharedWith.isEveryoneInTheOrganization ||
                    sharedWith.isParentsInTheOrganization ||
                    sharedWith.isTeachersInTheOrganization
                  }
                  onClick={(e, { checked }) => {
                    setSharedWith((prevdata) => ({
                      ...prevdata,
                      isStudentsInTheOrganization: checked,
                      classrooms: checked ? [] : prevdata.classrooms,
                      groups: checked ? [] : prevdata.groups,
                    }));
                    setIsChangedForm(true);
                    setSelectedShareWithOption(null);
                  }}
                />
              </Flex>
            ) : null}
            <Flex vAlign="center">
              <Checkbox
                label={feedbackTranslation.everyoneInOrganization}
                checked={!!sharedWith.isEveryoneInTheOrganization}
                disabled={
                  sharedWith.isParentsInTheOrganization ||
                  sharedWith.isTeachersInTheOrganization ||
                  sharedWith.isStudentsInTheOrganization
                }
                onClick={(e, { checked }) => {
                  setSharedWith((prevdata) => ({
                    ...prevdata,
                    isEveryoneInTheOrganization: checked,
                    classrooms: checked ? [] : prevdata.classrooms,
                    groups: checked ? [] : prevdata.groups,
                  }));
                  setIsChangedForm(true);
                  setSelectedShareWithOption(null);
                }}
              />
            </Flex>
          </Flex>
          {selectedShareWithOption === SHARE_WITH_OPTIONS.classrooms && (
            <Flex gap="gap.small" style={{ marginTop: 10 }} vAlign="center">
              <Dropdown
                // inverted
                clearable
                onTouchCancelCapture={() => alert("cancel")}
                search
                items={classroomNames.sort()}
                placeholder={"Search by classname"}
                onChange={(e, { value }) => {
                  handleFilterClassrooms(value);
                }}
                noResultsMessage="We couldn't find any matches."
                // checkable
                // getA11ySelectionMessage={{
                //   onAdd: (item) => dropdownChangedHandler(item),
                // }}
              />
              <Checkbox
                label={feedbackTranslation.selectAll}
                checked={
                  sharedWith.classrooms.length === sortedClassRooms.length
                }
                onClick={(e, { checked }) => {
                  setSharedWith((prevdata) => ({
                    ...prevdata,
                    classrooms: checked ? sortedClassRooms : [],
                  }));
                  setIsChangedForm(true);
                }}
              />
            </Flex>
          )}
          {selectedShareWithOption === SHARE_WITH_OPTIONS.group && (
            <Flex>
              <Segment
                style={{ padding: 0, marginTop: "10px" }}
                content={
                  <Dropdown
                    // inverted
                    clearable
                    onTouchCancelCapture={() => alert("cancel")}
                    search
                    items={groupNames.sort()}
                    placeholder={"Search by groupname"}
                    onChange={(e, { value }) => {
                      handleFilterGroups(value);
                    }}
                    noResultsMessage="We couldn't find any matches."
                    // checkable
                    // getA11ySelectionMessage={{
                    //   onAdd: (item) => dropdownChangedHandler(item),
                    // }}
                  />
                }
                className="seg2"
              />
            </Flex>
          )}
          <Flex>
            {selectedShareWithOption === SHARE_WITH_OPTIONS.classrooms ? (
              <Flex wrap>
                {sortedClassRooms.map((data, index) => (
                  <div key={index}>{classroomCard({ data })}</div>
                ))}
              </Flex>
            ) : null}
            {selectedShareWithOption === SHARE_WITH_OPTIONS.group ? (
              <Flex wrap>
                {filteredGroups.map((data) => groupCard({ data }))}
              </Flex>
            ) : null}
          </Flex>

          <Flex style={{ marginTop: "20px" }}>
            <Flex column>
              <Text color="black">{feedbackTranslation.endDateTime}</Text>
              {selectedDateTime && moment(selectedDateTime).isValid() ? (
                <Text color="black" style={{ marginTop: "10px" }}>
                  {moment(selectedDateTime).format("DD MMMM YYYY hh:mm A")}
                </Text>
              ) : null}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label={feedbackTranslation.selectDateTime}
                  value={selectedDateTime}
                  onChange={(newValue) => {
                    setSelectedDateTime(newValue?.$d);
                    setIsChangedForm(true);
                  }}
                  renderInput={(params) => (
                    <>
                      <Button
                        {...params}
                        content={feedbackTranslation.selectDateTime}
                        style={{
                          marginTop: "10px",
                          width: "fit-content",
                        }}
                      />
                    </>
                  )}
                  disablePast={true}
                />
              </LocalizationProvider>
              <div
                style={{
                  color: "#A4262C",
                  fontSize: "12px",
                  fontWeight: 400,
                  marginTop: "5px",
                }}
              >
                {isValidateFields
                  ? !selectedDateTime
                    ? feedbackTranslation.dateTimeValidation
                    : moment(selectedDateTime).isBefore(
                        moment().add("minute", 30)
                      )
                    ? feedbackTranslation.EndTimeValidation
                    : ""
                  : ""}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default withTranslation()(FeedbackQuestions);
