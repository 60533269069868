import React, { useEffect } from "react";
import "./Tutors.scss";
import TutorCard from "./TutorCard/TutorCard";
import { Col, Row } from "antd";
import { useBooking } from "../../../../context/BookingContext";
import { Button, Flex, Image, Loader, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import NoTutorsImage from "../../../../Assets/images/svg/no_tutors.svg";
import { useTutorRecommendations } from "../../../../context/TutorRecommendationsContext";
import GetRecommendationsDialog from "../GetRecommendationsDialog/GetRecommendationsDialog";

const Tutors = () => {
  const { t } = useTranslation();
  const {
    handleGetTutors,
    tutors,
    checkIsTutorStarred,
    getStarredTutors,
    isLoadingTutors,
    globalSearch,
  } = useBooking();

  const {
    setIsOpenGetRecommendationsDialog,
    getRecommendations,
    getRecommendationByTutorEmail,
  } = useTutorRecommendations();

  useEffect(() => {
    getStarredTutors();
    handleGetTutors();
  }, []);

  const filteredTutors = globalSearch?.length
  ? tutors
      .filter((tutor) =>
        String(tutor?.name)?.toLowerCase().includes(globalSearch.toLowerCase())
      )
      .sort((a, b) => {
        const aRecommendation = getRecommendationByTutorEmail(a.email);
        const bRecommendation = getRecommendationByTutorEmail(b.email);

        const aScore = aRecommendation?.relevanceScore ?? -1;
        const bScore = bRecommendation?.relevanceScore ?? -1;
        
        return bScore - aScore; // Sorting in descending order
      })
  : tutors.sort((a, b) => {
      const aRecommendation = getRecommendationByTutorEmail(a.email);
      const bRecommendation = getRecommendationByTutorEmail(b.email);

      const aScore = aRecommendation?.relevanceScore ?? -1;
      const bScore = bRecommendation?.relevanceScore ?? -1;
      
      return bScore - aScore;
    });


  const translation = t("booking").tutors;

  return (
    <div style={{ height: "calc(100% - 145px)" }}>
      <Flex gap="gap.medium" space="between">
        <h1 style={{ color: "#6264a7", fontWeight: "bold", margin: 0 }}>
          {translation.header}
        </h1>
        <Button
          content="Get Recommendations"
          primary
          style={{ borderRadius: "5px" }}
          onClick={() => setIsOpenGetRecommendationsDialog(true)}
        />
      </Flex>

      {isLoadingTutors ? (
        <Flex hAlign="center" vAlign="center" style={{ height: "100%" }}>
          <Loader size="medium" />
        </Flex>
      ) : (
        <>
          <Flex
            className="tutors-top-banner"
            styles={{
              marginTop: "20px",
              position: "relative",
              backgroundImage:
                'url("https://images.pexels.com/photos/1391498/pexels-photo-1391498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(90deg, rgba(167,8,41,1) 1%, rgba(31,9,121,1) 35%, rgba(70,7,158,1) 53%, rgba(255,0,99,1) 100%)",
                zIndex: 1,
                borderRadius: "15px",
              },
              zIndex: 0,
            }}
            hAlign="center"
            vAlign="center"
            column
          >
            <Text
              size="largest"
              weight="semibold"
              className="tutors-top-banner-text"
              content="Schedule One on One call with Your"
              color="white"
              style={{ zIndex: 3 }}
            />
            <Text
              size="largest"
              weight="semibold"
              className="tutors-top-banner-text"
              content="Classmates and Teachers"
              color="white"
              style={{ zIndex: 3 }}
            />
          </Flex>
          {tutors.length ? (
            <Row gutter={[20, 20]}>
              {filteredTutors.map((tutor, i) => {
                const isStarred = checkIsTutorStarred(tutor.email);
                const recommendation = getRecommendationByTutorEmail(
                  tutor.email
                );
                return (
                  <Col
                    key={`tutor-${i + 1}`}
                    xxl={4}
                    xl={6}
                    md={12}
                    sm={24}
                    style={{ width: "100%" }}
                  >
                    <TutorCard
                      tutor={tutor}
                      isStarred={isStarred}
                      isFromStarredPage={false}
                      recommendation={recommendation}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Flex column hAlign="center" vAlign="center">
              <Image
                src={NoTutorsImage}
                style={{ maxWidth: "280px", width: "100%" }}
              />
              <span>{translation.noTutorsFound}</span>
            </Flex>
          )}
        </>
      )}
      <GetRecommendationsDialog />
    </div>
  );
};

export default Tutors;
