import React, { useEffect, useState } from "react";
import "./TutorCard.scss"; // Create a separate CSS file for custom styles
import {
  Button,
  CloseIcon,
  Dialog,
  Flex,
  Image,
  InfoIcon,
  Label,
  StarIcon,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import { useBooking } from "../../../../../context/BookingContext";
import userSvg from "../../../../../Assets/images/svg/user.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";

const TutorCard = ({
  tutor,
  isStarred,
  isFromStarredPage,
  recommendation = null,
}) => {

  const { removeStarredTutor, addTutorToStarred } = useBooking();
  const history = useHistory();
  const [isOpenStarredConfirmDialog, setIsOpenStarredConfirmDialog] =
    useState(false);

  const { t } = useTranslation();

  const tutorCardTranslation = t("booking").tutors.tutorCard;
  const removeFavoriteDialogTranslation =
    t("booking").tutors.removeFavoriteDialog;

  return (
    <>
      <Dialog
        header={removeFavoriteDialogTranslation.title}
        open={isOpenStarredConfirmDialog}
        style={{ maxWidth: "500px" }}
        confirmButton={removeFavoriteDialogTranslation.confirmBtn}
        content={
          <Flex column gap="gap.medium">
            <Text>{removeFavoriteDialogTranslation.confirmationMsg}</Text>
          </Flex>
        }
        onConfirm={() =>
          removeStarredTutor(
            isFromStarredPage ? tutor.tutorEmailId : tutor.email
          )
        }
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setIsOpenStarredConfirmDialog(false);
          },
        }}
      />
      <div
        className="tutor-card"
        onClick={() => {
          history.push(`/tutor/${tutor.email}/${tutor.role}`);
        }}
      >
        <Image
          src={
            tutor?.image
              ? `${tutor.image}${process.env.REACT_APP_AZURE_SAS_TOKEN.replace(
                  /"/g,
                  ""
                )}&${moment()}`
              : userSvg
          }
          className="tutor-image"
        />
        <Flex
          vAlign="center"
          hAlign="center"
          className="star-wrapper"
          onClick={(e) => {
            e.stopPropagation();
            isStarred
              ? setIsOpenStarredConfirmDialog(true)
              : addTutorToStarred(tutor.email);
          }}
        >
          {isStarred ? (
            <StarIcon style={{ color: "#FFB900" }} />
          ) : (
            <StarIcon outline />
          )}
        </Flex>
        {recommendation !== null && (
          <Label
            content={
              <Flex gap="gap.smaller">
                <Text content={tutorCardTranslation.recommended} />
                <Tooltip
                  trigger={<InfoIcon circular outline size="small"  />}
                  content={recommendation.reason}
                />
              </Flex>

            }
            className="recommended-label"
            circular
          />
        )}

        <div className="tutor-info overlay">
          <Flex
            column
            gap="gap.small"
            space="between"
            style={{ height: "100%" }}
          >
            <Flex column gap="gap.smaller">
              <Tooltip
                trigger={<p className="tutor-name">{tutor?.name || "Tutor"}</p>}
                content={tutor?.name || "Tutor"}
              />

              {isFromStarredPage ? (
                <Text content={tutor.role} />
              ) : tutor?.isAvailableSlot ? (
                <Label
                  content={tutorCardTranslation.slotsAvailable}
                  className="slots-status"
                  circular
                />
              ) : (
                <Label
                  content={tutorCardTranslation.noSlots}
                  className="no-slots-status"
                  color=""
                  circular
                />
              )}

              <Flex wrap gap="gap.smaller">
                {tutor?.specialities?.length
                  ? tutor?.specialities.map(
                      (speciality, i) =>
                        i < 3 && (
                          <Tooltip
                            trigger={
                              <span
                                key={`tutor-speciality-${i}`}
                                className="tutor-speciality"
                              >{`# ${speciality}`}</span>
                            }
                            content={`# ${speciality}`}
                          />
                        )
                    )
                  : tutorCardTranslation.notProvided}
              </Flex>
            </Flex>
            <Button className="view-profile-button">
              <Text
                weight="semibold"
                content={tutorCardTranslation.viewProfile}
              />
            </Button>
          </Flex>
        </div>
      </div>
    </>
  );
};

export default TutorCard;
