import React from "react";
import { AskBuddyProvider } from "./context/AskBuddyContext";
import { TutorRecommendationsProvider } from "./context/TutorRecommendationsContext";
import { LearningFromWebWithAiProvider } from "./context/LearningFromWebWithAiContext";

export const GlobalContextProvider = (props) => {
  return (
    <AskBuddyProvider user={props.user}>
      <LearningFromWebWithAiProvider user={props.user}>
        <TutorRecommendationsProvider user={props.user}>
          {props.children}
        </TutorRecommendationsProvider>
      </LearningFromWebWithAiProvider>
    </AskBuddyProvider>
  );
};
