import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const ColumnChart = ({
  categories,
  series,
  stacked,
  radius,
  labelX,
  labelY,
}) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

  const getResponsiveConfig = () => [
    {
      breakpoint: 1360,
      options: { chart: { width: 700, height: 350 } },
    },
    {
      breakpoint: 1300,
      options: {
        chart: { width: 800, height: 450 },
        plotOptions: { bar: { borderRadius: 10 } },
      },
    },
    // Add other breakpoints here
  ];

  useEffect(() => {
    // const truncatedCategories = categories.map((cat) => cat + cat + cat);
    const truncatedCategories =
      Array.isArray(categories) && categories.every(Array.isArray)
        ? categories.map((subArray) =>
            subArray.map((cat) =>
              cat.length > 10 ? `${cat.substring(0, 10)}...` : cat
            )
          )
        : categories.map((cat) =>
            cat.length > 10 ? `${cat.substring(0, 10)}...` : cat
          );

    setChartOptions({
      chart: { type: "bar", height: 350, stacked },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          borderRadius: radius || 2,
        },
      },
      xaxis: {
        categories: truncatedCategories,
        title: { text: labelX },
      },
      yaxis: {
        title: { text: labelY },
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const fullCategory = Array.isArray(categories[dataPointIndex])
            ? categories[dataPointIndex].join(" ")
            : categories[dataPointIndex]; // Get full category name
          const value = series[seriesIndex][dataPointIndex]; // Get value
          const seriesName = w.globals.seriesNames[seriesIndex]; // Get series name
          const color = w.globals.colors[seriesIndex]; // Get series color

          return `
         <div style="background: #fff; border-radius: 5px; box-shadow: 0px 0px 10px rgba(0,0,0,0.1);">
            <div style="background: #eceff1; padding: 10px; border-bottom: 1px solid #ccc;">
              <span>${fullCategory}</span>
            </div>
            <div style="display: flex; align-items: center; padding: 8px;">
              <div style="width: 10px; height: 10px; background: ${color}; border-radius: 50%; margin-right: 8px;"></div>
              <span>${seriesName}: <span>${value}</span></span>
            </div>
          </div>
          `;
        },
      },
      responsive: getResponsiveConfig(),
    });

    setChartSeries([...series]);
  }, [categories, series, stacked, radius, labelX, labelY]);

  let width =
    document.documentElement.clientWidth < 500
      ? document.documentElement.clientWidth - 56
      : (document.documentElement.clientWidth * 51) / 100;

  return (
    <Chart
      options={chartOptions}
      series={chartSeries}
      type="bar"
      width={width}
      height={450}
    />
  );
};

export default ColumnChart;

// import React, { useState, useEffect } from "react";
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

// const ColumnChart = ({ categories, series, stacked, radius, labelX, labelY }) => {
//   const [chartData, setChartData] = useState([]);

//   useEffect(() => {
//     const truncatedCategories = categories.map((cat) =>
//       cat.length > 10 ? `${cat.substring(0, 10)}...` : cat
//     );

//     const formattedData = truncatedCategories.map((category, index) => {
//       const dataPoint = { category };
//       series.forEach((serie) => {
//         dataPoint[serie.name] = serie.data[index];
//       });
//       return dataPoint;
//     });

//     setChartData(formattedData);
//   }, [categories, series]);

//   return (
//     <ResponsiveContainer width="100%" height={450}>
//       <BarChart
//         data={chartData}
//         margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="category" label={{ value: labelX, position: "insideBottom", offset: -5 }} />
//         <YAxis label={{ value: labelY, angle: -90, position: "insideLeft" }} />
//         <Tooltip />
//         <Legend />
//         {series.map((serie, index) => (
//           <Bar
//             key={serie.name}
//             dataKey={serie.name}
//             fill={serie.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`}
//             stackId={stacked ? "a" : undefined}
//             radius={[radius || 0, radius || 0, 0, 0]} // Apply rounded corners
//           />
//         ))}
//       </BarChart>
//     </ResponsiveContainer>
//   );
// };

// export default ColumnChart;
