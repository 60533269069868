import React, { useState } from "react";
import LearningFromWebDialogContent from "./LearningFromWebDialogContent";
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  Flex,
  RobotIcon,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import "./LearningFromWebDialog.scss";
import { useLearningFromWebWithAi } from "../../../../context/LearningFromWebWithAiContext";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";

const LearningFromWebDialog = () => {
  const {
    isOpenLearningFromWebDialog,
    isLoadingLearningResources,
    isLoadingChatMessages,
    handleClose,
    isFullScreen,
    setIsFullScreen,
  } = useLearningFromWebWithAi();

  return (
    <Dialog
      id="learning-from-web-dialog"
      className={`learning-from-web-dialog ${
        isFullScreen ? "full-screen-dialog" : ""
      }`}
      open={isOpenLearningFromWebDialog}
      content={<LearningFromWebDialogContent />}
      header={
        <Box>
          <Flex space="between" vAlign="center">
            <Flex gap="gap.small" vAlign="center" hAlign="center">
              <RobotIcon outline color="brand" size="large" />
              <Text content="AskBuddy" weight="semibold" />
            </Flex>
            <Tooltip
              content={isFullScreen ? "Exit Full Screen" : "Full Screen"}
              styles={{
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
              }}
              trigger={
                <Button
                  text
                  iconOnly
                  icon={
                    isFullScreen ? (
                      <FullscreenExitOutlined style={{ fontSize: "14px" }} />
                    ) : (
                      <FullscreenOutlined style={{ fontSize: "14px" }} />
                    )
                  }
                  onClick={() => setIsFullScreen(!isFullScreen)}
                  styles={{
                    height: "fit-content",
                  }}
                  aria-label={isFullScreen ? "Exit Full Screen" : "Full Screen"}
                />
              }
              zIndex={10}
              mountNode={
                document.getElementsByClassName("learning-from-web-dialog")[0]
              }
            />
          </Flex>
        </Box>
      }
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: handleClose,
        disabled: isLoadingLearningResources || isLoadingChatMessages,
      }}
    />
  );
};

export default LearningFromWebDialog;
