import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { useAskBuddy } from "../../../../../context/AskBuddyContext";

const PreBuiltQuestionBox = ({ question, style = {} }) => {
  const { askQuestion } = useAskBuddy();
  return (
    <Flex
      className="pre-built-question-box"
      vAlign="center"
      hAlign="center"
      onClick={() => askQuestion(question)}
      style={style}
    >
      <Text
        content={question}
        className="pre-built-question-text"
        weight="regular"
      />
    </Flex>
  );
};

export default PreBuiltQuestionBox;
