import React, { Component } from "react";

import Chart from "../Container/Charts";

import { Button, Dialog, Text } from "@fluentui/react-northstar";

import axios from "axios";

import { withTranslation } from "react-i18next";
import { reportTypes } from "./constant";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();
class SpecialReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this?.props?.user?.slug,
      selectedClass: [],
      selectedColony: [],
      selectedInternetSpeed: [],
      LessonDate: [
        new Date(d.getFullYear(), d.getMonth() - 3, d.getDate(), 0, 0, 0, 0),
        new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
      ],
      allClassNames: null,
      colonyNames: null,
      allInternetSpeed: null,
      isEmployee: null,
      isScholarship: null,
      isPassing: null,
      filterBy: "Presente",
      filerts: {
        CF_1: null,
        CF_2: null,
        CF_3: null,
        CF_4: null,
        CF_5: null,
      },
      checkPhysical: null,
      loading: { colonia: false, chart: false },
      internetSpeed: [],

      // There is an issue when CF1 from result => StudentData.CF_1
      // And CF_4 from internetSpeed => StudentData.CF_4 is null.
      // So 'No data given' label is displayed with data in table, PI chart and Column chart
      // SO have to show Popup that indicate Custom fields is not loaded properly or attendance is not taken
      isDataNotLoadedProperly: false,
      sortOrder: "desc",
    };
  }

  toggleSort() {
    this.setState({
      sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
    });
  }

  stateChangeHandler(slug) {
    this.setState({
      loading: { ...this.state.loading, colonia: true },
    });
    axios
      .post(
        `${url}/api/chart/get-chart-by-colonia${slug}`,
        {
          ClassName: this.state.selectedClass?.length
            ? this.state.selectedClass
            : null,
          LessonDate: this.state.LessonDate,
          // colonia: this.state.selectedColony?.length
          //   ? this.state.selectedColony
          //   : null,
          // internetSpeed: this.state.selectedInternetSpeed?.length
          //   ? this.state.selectedInternetSpeed
          //   : null,
          // isEmployee: this.state.isEmployee,
          // isScholarship: this.state.isScholarship,
          // isPassing: this.state.isPassing,
          ...this.state.filerts,
          ClassType: this.state.checkPhysical,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        const response = res.data;

        this.setState({
          coloniaData: response?.result?.sort(
            (a, b) =>
              parseInt(b[this.state.filterBy]) -
              parseInt(a[this.state.filterBy])
          ),
          internetSpeed: res.data.internetSpeed,
          customFields: res.data.sendData,
        });

        let isDataNotLoadedProperly = this.state.isDataNotLoadedProperly;

        if (this.state.colonyNames === null) {
          let colonyNames = response?.result?.map((colony) => {
            if (colony.StudentData.CF_1 == null) {
              isDataNotLoadedProperly = true;
            }
            return (
              colony.StudentData.CF_1 || "No Data Given" || "No Data Given"
            );
          });

          this.setState({ colonyNames });
        }

        if (this.state.allInternetSpeed === null) {
          let allInternetSpeed = res.data?.internetSpeed?.map((cf) => {
            if (cf.StudentData.CF_4 == null && !isDataNotLoadedProperly) {
              isDataNotLoadedProperly = true;
            }
            return cf.StudentData.CF_4 || "No Data Given";
          });

          this.setState({ allInternetSpeed });
        }
        this.setState({ isDataNotLoadedProperly });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          loading: { ...this.state.loading, colonia: false },
        });
      });
  }
  componentWillReceiveProps(nextProps) {
    this.stateChangeHandler(nextProps?.user?.slug);
  }

  componentDidMount() {
    this.setState({
      loading: { ...this.state.loading, chart: true },
    });
    axios
      .post(
        `${url}/api/chart/get-chart-by-classname${this.props.user.slug}`,
        {
          ClassName: null,
          LessonDate: this.state.LessonDate,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        let classNames = response
          ? response.map((lsn) => {
              return lsn.ClassName;
            })
          : [];

        if (this.state.allClassNames === null) {
          // console.log(this.state.classNames);
          this.setState({ allClassNames: classNames });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          loading: { ...this.state.loading, chart: false },
        });
      });
    this.stateChangeHandler(this.state.slug);
  }

  filterByHandler(option) {
    // console.log(option);

    this.setState({ filterBy: option }, () => {
      this.setState(
        {
          coloniaData: this.state.coloniaData.sort((a, b) =>
            this.state.sortOrder === "desc"
              ? parseInt(b[this.state.filterBy]) -
                parseInt(a[this.state.filterBy])
              : parseInt(a[this.state.filterBy]) -
                parseInt(b[this.state.filterBy])
          ),
          internetSpeed: this.state.internetSpeed.sort((a, b) =>
            this.state.sortOrder === "desc"
              ? parseInt(b[this.state.filterBy]) -
                parseInt(a[this.state.filterBy])
              : parseInt(a[this.state.filterBy]) -
                parseInt(b[this.state.filterBy])
          ),
        },
        () => {
          this.toggleSort();
        }
      );
    });
  }

  render() {
    const { t } = this.props;

    Array.prototype.sum = function (prop) {
      var total = 0;
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += parseInt(this[i][prop]);
      }
      return total;
    };

    let pie1data = {};

    let wardCount = 0;

    if (this.state.coloniaData) {
      // Step 1: Sort the array by the specified property in descending order
      // const sortedColoniaData = this.state.coloniaData.sort(
      //   (a, b) =>
      //     parseInt(b[this.state.filterBy]) - parseInt(a[this.state.filterBy])
      // );
      wardCount = new Set(
        this.state.coloniaData
          .map((colony) => colony.StudentData.CF_1)
          .filter((value) => value) // Filters out undefined, null, and empty strings
      ).size;

      // Step 2: Take the first 10 records from the sorted array
      const top10ColoniaData = this.state.coloniaData.slice(0, 10);

      // Step 3: Add these records to the pie1data object
      top10ColoniaData.forEach((colony) => {
        let key = colony.StudentData.CF_1
          ? colony.StudentData.CF_1.trim()
          : "No Data Given";
        pie1data[key] = parseInt(colony[this.state.filterBy]);
      });
    }

    let pie2data = {};

    if (this.state.internetSpeed) {
      // Step 1: Sort the array by the specified property in descending order
      // const sortedInternetSpeed = this.state.internetSpeed.sort(
      //   (a, b) =>
      //     parseInt(b[this.state.filterBy]) - parseInt(a[this.state.filterBy])
      // );

      // Step 2: Take the first 10 records from the sorted array
      const top10InternetSpeed = this.state.internetSpeed.slice(0, 10);

      // Step 3: Add these records to the pie2data object
      top10InternetSpeed.forEach((speed) => {
        let key = speed?.StudentData?.CF_4
          ? String(speed?.StudentData?.CF_4)?.trim()
          : "No Data Given";
        pie2data[key] = parseInt(speed[this.state.filterBy]);
      });
    }

    return (
      <>
        <Dialog
          open={this.state.isDataNotLoadedProperly}
          onConfirm={() => this.props.setSelectedReportPath("")}
          confirmButton={t("chart").getBackToDashboard}
          content={
            <Text size="large" weight="semibold">
              {t("chart").dataNotLoadedProperly}
            </Text>
          }
        />
        <Chart
          mainPath={this.props.mainPath}
          showControls={true}
          isLoading={this.state.loading.chart || this.state.loading.colonia}
          report={reportTypes.attendanceSpecial}
          user={this.props.user}
          logout={() => this.props.logout()}
          switchDb={this.props.switchDb}
          getDb={() => this.props.getDb()}
          path={this.props.match.path}
          header={{
            report: t("chart").screens.header.reports[3],
            lable: t("chart").screens.header.lable,
          }}
          startDate={this.state.LessonDate[0]}
          endDate={this.state.LessonDate[1]}
          startChangeHandler={(selectedDate) => {
            // console.log(selectedDate)
            let LessonDate = [...this.state.LessonDate];
            LessonDate[0] = selectedDate;
            this.setState({ LessonDate }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          endChangeHandler={(selectedDate) => {
            let LessonDate = [...this.state.LessonDate];
            LessonDate[1] = selectedDate;
            this.setState({ LessonDate }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          allClassNames={this.state.allClassNames}
          showClassType={true}
          checkPhysical={this.state.checkPhysical}
          setCheckPhysical={(classType) => {
            this.setState({ checkPhysical: classType }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          stateChangeHandler={(selectedClass) => {
            this.setState({ selectedClass }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          classAdd={(item) => {
            let selectedClass = [...this.state.selectedClass];
            selectedClass.push(item);
            this.setState({ selectedClass }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          classRemove={(item) => {
            let selectedClass = [...this.state.selectedClass];
            selectedClass.splice(selectedClass.indexOf(item), 1);
            this.setState({ selectedClass }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          customFields={this.state.customFields}
          getFilters={this.state.filerts}
          setFilters={(filerts) => {
            this.setState({ filerts }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          colonyNames={this.state.colonyNames}
          colonyAdd={(item) => {
            let selectedColony = [...this.state.selectedColony];
            selectedColony.push(item);
            this.setState({ selectedColony }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          colonyRemove={(item) => {
            let selectedColony = [...this.state.selectedColony];
            selectedColony.splice(selectedColony.indexOf(item), 1);
            this.setState({ selectedColony }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          allInternetSpeed={this.state.allInternetSpeed}
          internetSpeedAdd={(item) => {
            let selectedInternetSpeed = [...this.state.selectedInternetSpeed];
            selectedInternetSpeed.push(item);
            this.setState({ selectedInternetSpeed }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          internetSpeedRemove={(item) => {
            let selectedInternetSpeed = [...this.state.selectedInternetSpeed];
            selectedInternetSpeed.splice(
              selectedInternetSpeed.indexOf(item),
              1
            );
            this.setState({ selectedInternetSpeed }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          showLbl={[
            this.state.selectedClass?.length ? false : true,
            this.state.selectedColony?.length ? false : true,
            this.state.selectedInternetSpeed?.length ? false : true,
          ]}
          setIsEmployee={(item) => {
            this.setState({ isEmployee: item === "All" ? null : item }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          setIsScholarship={(item) => {
            this.setState(
              { isScholarship: item === "All" ? null : item },
              () => {
                this.stateChangeHandler(this.state.slug);
              }
            );
          }}
          setIsPassing={(item) => {
            this.setState({ isPassing: item === "All" ? null : item }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          boxData={
            this.state.coloniaData
              ? {
                  [t("chart").screens.special.boxs[0]]:
                    this.state.coloniaData?.length,
                  [t("chart").screens.special.boxs[1]]:
                    this.state.coloniaData.length,
                  [t("chart").screens.special.boxs[2]]: wardCount,
                  [t("chart").screens.special.boxs[3]]:
                    this.state.coloniaData.sum("Ausente"),
                  [t("chart").screens.special.boxs[4]]:
                    this.state.coloniaData.sum("Retardo"),
                  [t("chart").screens.special.boxs[5]]:
                    this.state.coloniaData.sum("Justificado"),
                }
              : null
          }
          hideChartsContent={this.state.isDataNotLoadedProperly}
          pieData={{
            pie1: {
              lable: t("chart").screens.special.pie.pie1.lbl,
              data: pie1data,
            },
            pie2: {
              lable: t("chart").screens.special.pie.pie2.lbl,
              data: pie2data,
            },
          }}
          columnData={{
            stacked: true,
            radius: 10,
            title: t("chart").screens.special.column.title,
            lableY: t("chart").screens.special.column.lableY,
            lableX: t("chart").screens.special.column.lableX,
            series: this.state.coloniaData
              ? [
                  {
                    name: [t("chart").screens.subject.column.series[0]],
                    data: [...this.state.coloniaData]
                      .slice(0, 10)
                      .map((stud) => {
                        return parseInt(stud.Presente);
                      }),
                  },
                  {
                    name: [t("chart").screens.subject.column.series[1]],
                    data: this.state.coloniaData.slice(0, 10).map((stud) => {
                      return parseInt(stud.Ausente);
                    }),
                  },
                  {
                    name: [t("chart").screens.subject.column.series[2]],
                    data: this.state.coloniaData.slice(0, 10).map((stud) => {
                      return parseInt(stud.Retardo);
                    }),
                  },
                  {
                    name: [t("chart").screens.subject.column.series[3]],
                    data: this.state.coloniaData.slice(0, 10).map((stud) => {
                      return parseInt(stud.Justificado);
                    }),
                  },
                ]
              : [],
            // categories:this.state.coloniaData?this.state.coloniaData.slice(0,10).map(stud=>{
            //     return([stud.StudentName.split(" ")[0],stud.StudentName.split(" ")[1]])
            // }):[],
            categories: this.state.coloniaData
              ? [...this.state.coloniaData].slice(0, 10).map((colony) => {
                  return colony.StudentData.CF_1 || "No Data Given";
                })
              : [],
          }}
          tableData={{
            header: {
              key: "Headers",
              items: t("chart").screens.special.table.header.map(
                (header, key) => {
                  return {
                    content:
                      key && key === 1 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Presente"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Presente")}
                        />
                      ) : key === 2 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Ausente"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Ausente")}
                        />
                      ) : key === 3 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Retardo"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Retardo")}
                        />
                      ) : key === 4 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Justificado"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Justificado")}
                        />
                      ) : key === 5 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Total"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Total")}
                        />
                      ) : (
                        header
                      ),
                    key: "Header-" + header,
                  };
                }
              ),
            },
            rows: this.state.coloniaData
              ? this.state.coloniaData.map((colony, key) => {
                  return {
                    key: colony.StudentData.CF_1 + "key" + key,
                    items: [
                      {
                        content: colony.StudentData.CF_1 || "No Data Given",
                        key: colony.StudentData.CF_1 + "Colonia" + key,
                      },
                      {
                        content: colony.Presente,
                        key: colony.StudentData.CF_1 + "Presente" + key,
                      },
                      {
                        content: colony.Ausente,
                        key: colony.StudentData.CF_1 + "Ausente" + key,
                      },
                      {
                        content: colony.Retardo,
                        key: colony.StudentData.CF_1 + "Retardo" + key,
                      },
                      {
                        content: colony.Justificado,
                        key: colony.StudentData.CF_1 + "Justificado" + key,
                      },
                      {
                        content: colony.Total,
                        key: colony.StudentData.CF_1 + "Total" + key,
                      },
                    ],
                  };
                })
              : [],
          }}
          selectedExtension={this.props.selectedExtension}
          setSelectedExtension={this.props.setSelectedExtension}
        />
      </>
    );
  }
}

export default withTranslation()(SpecialReport);
