import axios from "axios";

export const generateGptCreditRequest = async (payload, slug, token) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_EP_URL}/api/credit/generate${slug}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };

  const response = await axios.request(config);
  return response.data;
};

export const updateUserGptCredit = async (payload, slug, token) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_EP_URL}/api/user/update-user-credit${slug}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };

  const response = await axios.request(config);
  return response.data;
};

export const getUserUsedStorage = async (slug, email, role) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_EP_URL}/api/storage/user${slug}&email=${email}&role=${role}`,
  };
  const response = await axios.request(config);
  return response.data;
};

export const getStorageAnalytics = async (slug, email) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_EP_URL}/api/storage/analytics${slug}&email=${email}`,
  };
  const response = await axios.request(config);
  return response.data;
};

export const getStorageDetails = async (slug, email, page, extension) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_EP_URL}/api/storage/details${slug}&email=${email}&page=${page}&extension=${extension}`,
  };
  const response = await axios.request(config);
  return response.data;
};
export const createChannel = async (slug, payload) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_EP_URL}/api/channel${slug}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };

  const response = await axios.request(config);
  if (response.data.status !== "success") {
    throw new Error("Failed to create channel");
  }

  return response.data;
};

export const getChannel = async (slug) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_EP_URL}/api/channel${slug}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  if (response.data.status !== "success") {
    throw new Error("Failed to fetch channel");
  }

  return response.data;
};
