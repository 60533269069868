import {
  Button,
  CloseIcon,
  Dialog,
  DownloadIcon,
  Flex,
  Input,
  SearchIcon,
  ShareAltIcon,
  Text,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import { useCallback, useEffect, useState } from "react";
import { useChannelModal } from "../../../context/ChannelModalContext";
import "../../../pages/Events/Components/ShareEvent/ShareEvent.scss";
import { getQRCode } from "../../../utils/generateQRCode";

export const ShareChannelModal = () => {
  const eventLongUrl = `${process.env.REACT_APP_URI}/channel/details/A2Df3-2ghTyg-5sdR5e-2sDfg`;
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [emailInputText, setEmailInputText] = useState("");
  const [emails, setEmails] = useState([]);
  const [isInvalidEmailText, setIsInvalidEmailText] = useState(false);

  const { modals, closeModal } = useChannelModal();
  // const [activeTab, setActiveTab] = useState();
  const qrCodeGenerator = () => {
    const qrValue = getQRCode(eventLongUrl);
    return qrValue;
  };

  useEffect(() => {
    if (modals.shareChannel) {
      try {
        const qrValue = qrCodeGenerator();
        if (!qrValue) return;
        setQrCodeImage(qrValue);
      } catch (error) {}
    }
  }, [modals.shareChannel]);

  const downloadQRImage = useCallback(() => {
    const elm = document.createElement("a");
    elm.href = qrCodeImage;
    elm.download = ` CommanQR : ""
        }`;
    elm.click();
  }, [qrCodeImage]);

  const onAddEmailHandler = () => {
    let emailInputList = emailInputText?.length
      ? [
          ...new Set(
            emailInputText
              .toLowerCase()
              .split(";")
              .filter((email) => email)
          ),
        ]
      : [];
    if (!emailInputList.length) {
      return;
    }
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    let isValid = true;
    emailInputList.forEach((emailText) => {
      if (!emailRegex.test(emailText)) {
        isValid = false;
      }
    });

    if (emailInputText?.includes(",")) {
      isValid = false;
    }

    if (isValid) {
      setEmails((prev) => [...new Set([...prev, ...emailInputList])]);
      setEmailInputText("");
    } else {
      setIsInvalidEmailText(true);
    }
    console.log("Emails from inputs: ", emailInputList);
  };

  const shareEventLinkHandler = async () => {
    if (eventId) {
      try {
        navigator.clipboard.writeText(eventLongUrl);
        toast.success(shareEventTranslation.linkCopiedSuccess, {
          closeOnClick: true,
        });
      } catch {
        toast.error(shareEventTranslation.linkCopiedError, {
          closeOnClick: true,
        });
      }
    }
  };

  const sendEmailInvitationHandler = () => {
    console.log("sendEmailInvitationHandler is sent");
    setEmails([]);
    // try {
    //   setLoading({ type: emailLoading, isLoading: true });

    //   const payload = {
    //     emails,
    //     link: eventLongUrl,
    //     title: eventTitle,
    //   };

    //   const { data } = await axios({
    //     method: "POST",
    //     url: `${API_URL}/api/events/send-invitation/:${eventId}${user.slug}`,
    //     data: payload,
    //     headers: {
    //       Authorization: `Bearer ${user.accessToken}`,
    //     },
    //   });
    //   if (data) {
    //     if (
    //       data.status?.toLowerCase() === "success" &&
    //       typeof data.message === "string"
    //     ) {
    //       toast.success(data.message);
    //       setEmails([]);
    //     } else if (data.status?.toLowerCase() === "error") {
    //       toast.error(
    //         typeof data.message === "string"
    //           ? data.message
    //           : shareEventTranslation.invitationFailed
    //       );
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   setLoading({ type: null, isLoading: false });
    // }
  };

  const onShareButtonClickhandler = () => {
    setEmailInputText("");
    setEmails([]);
    setIsInvalidEmailText(false);
  };
  const handleClose = () => {
    closeModal("shareChannel");
  };
  return (
    <Dialog
      className="share-event-dialog"
      open={modals.shareChannel}
      onClose={handleClose}
      content={
        <div className="share-event-wrapper">
          <div className="share-event-tabs">
            <ol className="share-event-tab-list">
              <li className="share-event-tab-list-item active">Share</li>
            </ol>
            <div className="share-event-tab-content">
              <div className="share-event-link">
                <div className="share-event-link-container">
                  <div className="email-invite-wrapper">
                    <div className="input-wrapper">
                      <Flex column>
                        <Input
                          className="email-input"
                          fluid
                          icon={<SearchIcon />}
                          value={emailInputText}
                          onChange={(e, { value }) => {
                            setIsInvalidEmailText(false);
                            setEmailInputText(value);
                          }}
                          clearable
                          label={"Email(s) to invite"}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              onAddEmailHandler();
                            }
                          }}
                          error={isInvalidEmailText}
                        />
                      </Flex>
                    </div>
                    <div className="emails-wrapper">
                      <Flex>
                        <p style={{ marginBottom: "4em" }}>
                          You can send multiple separating accounts by
                          semicolons(;).
                        </p>
                      </Flex>
                    </div>
                    <div className="action-button-wrapper">
                      <Flex
                        space="between"
                        className="share-event-action-buttons"
                      >
                        <Button
                          primary
                          icon={<ShareAltIcon />}
                          content={"shareLink"}
                          onClick={() => onShareButtonClickhandler()}
                        />
                        <Button
                          primary
                          icon={<UserFriendsIcon />}
                          content={"Invite"}
                          onClick={() => sendEmailInvitationHandler()}
                        />
                      </Flex>
                    </div>
                  </div>
                  <div className="qr-code-action-wrapper">
                    <div className="qr-image-wrapper">
                      <img src={qrCodeImage} alt="QR Code" />
                    </div>
                    <div>
                      <Button
                        primary
                        icon={<DownloadIcon />}
                        onClick={downloadQRImage}
                        content={
                          <>
                            <Text>{"Download"} </Text>
                            <Text className="download-extra-text">
                              {"QR Code"}
                            </Text>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      header={<div>{"Share your Channel"}</div>}
      headerAction={{
        icon: <CloseIcon />,
        title: "close",
        onClick: () => {
          handleClose();
        },
      }}
      styles={{ maxWidth: "700px" }}
    />
  );
};
