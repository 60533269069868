import React, { useState, useEffect } from "react";
import {
  Flex,
  CardHeader,
  CardBody,
  Card,
  Text,
  Button,
  Loader,
} from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import { Avatar, Col, Row, Tooltip } from "antd";
import axios from "axios";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { ResponsiveImage } from "../../../../components/blocks/Images";
import noFeedbackImage from "../../../../Assets/images/nofeedback.png";
import DefaultGroupImage from "../../../../Assets/images/group-default.png";

import "./Groups.scss";
import { useFeedBack } from "../../../../context/FeedbackContext";

const GroupCard = styled(Flex)`
  padding: 10px;
  width: 100%;
`;

const TitleStudentSection = styled(Flex)`
  .title-wrapper {
    max-width: calc(100% - 110px);
    .title-section {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .title {
        font-size: 16px;
        display: unset;
      }
    }
  }
`;

const url = process.env.REACT_APP_EP_URL;

const Groups = ({ user, t, globalSearch }) => {
  const history = useHistory();

  const { groups, isLoading, getGroups } = useFeedBack();

  const translation = t("feedbackModule");

  const feedbackTranslation = translation.body.group;

  useEffect(() => {
    if (globalSearch && user.mail) {
      getGroups(globalSearch);
    } else if (user.mail && !groups.length) {
      getGroups(globalSearch);
    }
  }, [globalSearch]);

  const myCard = ({ group }) => {
    return (
      <Card
        elevated
        className="fit-content"
        fluid
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
        }}
        onClick={() => {
          history.push(`/feedback/groups/group?groupId=${group.groupId}`);
        }}
      >
        <CardHeader>
          <Flex gap="gap.small" column>
            <img
              alt={feedbackTranslation.imgAlt}
              src={
                group.groupImageUrl
              }
              onError={(e) => {
                e.target.src = DefaultGroupImage;
              }}
              style={{
                // maxWidth: "100%",
                // maxHeight: "180px",
                height: "160px",
                borderRadius: "10px 10px 0 0",
                objectFit: "cover",
              }}
              draggable="false"
              className="img-width"
            />
          </Flex>
        </CardHeader>
        <CardBody>
          <TitleStudentSection
            space="between"
            vAlign="center"
            style={{ padding: "0 10px", minHeight: "32px" }}
          >
            <Flex className="title-wrapper">
              <div className="title-section">
                <Tooltip placement="bottom" title={group.groupName}>
                  <Text weight="semibold" align="start" className="title">
                    {group.groupName}
                  </Text>
                </Tooltip>
              </div>
            </Flex>

            <Flex>
              {group.students?.length ? (
                <Avatar.Group
                  maxPopoverTrigger="hover"
                  maxCount={2}
                  size="default"
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    cursor: "pointer",
                  }}
                >
                  {group.students.map((student, index) => (
                    <Avatar
                      key={student.studentEmailId}
                      src={
                        student.studentImageUrl ? student.studentImageUrl : null
                      }
                      alt={feedbackTranslation.userImgAlt}
                    >
                      <Tooltip title={student.name}>
                        {student.name?.length
                          ? student.name[0]
                          : student.studentEmailId?.[0]}
                      </Tooltip>
                    </Avatar>
                  ))}
                </Avatar.Group>
              ) : (
                <div />
              )}
            </Flex>
          </TitleStudentSection>
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="groups-container">
      <div className="groups">
        <Flex space="between" wrap>
          <Text size="larger" weight="semibold" style={{ marginLeft: "10px" }}>
            {feedbackTranslation.header}
          </Text>

          <Button
            primary
            content={feedbackTranslation.createNewGroup}
            color="white"
            onClick={() => {
              history.push(`/feedback/groups/group?isNew=true`);
            }}
          />
        </Flex>

        <div className="all-groups">
          {isLoading ? (
            <Flex
              hAlign="center"
              vAlign="center"
              style={{ paddingTop: "50px" }}
            >
              <Loader size="medium" />
            </Flex>
          ) : groups.length ? (
            <Row>
              {groups.map((group, index) => {
                return (
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={6}
                    key={`Filtered-group-${index}`}
                  >
                    <GroupCard>{myCard({ group })}</GroupCard>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Flex hAlign="center" column style={{ marginTop: "40px" }}>
              <ResponsiveImage
                src={noFeedbackImage}
                style={{ width: "60%", maxWidth: "350px" }}
              />
              <Text weight="semibold" size="large" align="center">
                {groups.length
                  ? feedbackTranslation.noGroupsForFilter
                  : feedbackTranslation.noGroups}

                {!groups.length && (
                  <>
                    <Button
                      onClick={() => {
                        history.push("/feedback/groups/group?isNew=true");
                      }}
                      style={{ border: 0, marginTop: "-3px" }}
                      text
                    >
                      <Text
                        style={{
                          color: "#494b83",
                        }}
                        weight="semibold"
                      >
                        {feedbackTranslation.clickHere}
                      </Text>
                    </Button>
                    {feedbackTranslation.toCreate}
                  </>
                )}
              </Text>
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Groups);
