import React, { useEffect, useState, useMemo } from "react";
import { IconButton, TextField } from "@fluentui/react";
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  CloseIcon,
  Dialog,
  Dropdown,
  Flex,
  Image,
  Loader,
  Segment,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import { useHistory, useLocation } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { toast } from "react-toastify";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import FeedbackDialog from "../shared/FeedbackDialog";
import { uploadFileToBlob } from "../../utils/uploadData";

import noDataImage from "../../../../Assets/images/conversation.png";
import userSvg from "../../../../Assets/images/svg/user.svg";
import { ResponsiveImage } from "../../../../components/blocks/Images";

import "./Groups.scss";
import AddMember from "./AddMember";
import { Col, Row } from "antd";
import { removeSasToken, replaceOrAddSasToken } from "../../../../utils/azure";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const url = process.env.REACT_APP_EP_URL;

const createUpdateGroup = "createUpdate";
const deleteGroup = "delete";

const ImageWrapper = styled.div`
  max-height: 350px;
  height: 100%;
`;

const DeleteImageButton = styled(Button)`
  margin-top: 15px;
`;

const Group = (props) => {
  const { user, t } = props;
  const history = useHistory();

  let query = useQuery();
  let id = query.get("groupId");

  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [groupData, setGroupData] = useState(null);

  const [studentListOfSelectedClass, setStudentListOfSelectedClass] = useState(
    []
  );

  const [
    filteredStudentListOfSelectedClass,
    setFilteredStudentListOfSelectedClass,
  ] = useState([]);

  const [selectedStudentsOfSelectedClass, setSelectedStudentsOfSelectedClass] =
    useState([]);
  const [allStudentsForGroup, setAllStudentsForGroup] = useState([]);
  const [classDropdownList, setClassDropdownList] = useState([]);
  const [selectedStudentToDelete, setSelectedStudentToDelete] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isValidateFields, setIsValidateFields] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [classData, setClassData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({
    isLoading: false,
    action: null,
  });
  const [selectedClass, setSelectedClass] = useState(null);
  const [isClassDataLoading, setIsClassDataLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [studentSearchText, setStudentSearchText] = useState("");
  const [selectedStudentByNameEmail, setSelectedStudentByNameEmail] = useState(
    []
  );

  const translation = t("feedbackModule");

  const feedbackTranslation = translation.body.group;

  const getGroup = async (id) => {
    setIsLoading(true);
    setGroupId(id);
    try {
      const { data } = await axios({
        method: "GET",
        url: `${url}/api/feedback/group/get-group-by-groupid/${id}${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result) {
        let { groupName, groupImage = "", GroupUsers } = data.result;
        setGroupData(data.result);

        setGroupName(groupName);
        groupImage && setSelectedImage(
          replaceOrAddSasToken(groupImage, process.env.REACT_APP_AZURE_SAS_TOKEN.replace(/"/g, ""))
        );
        let groupUser = GroupUsers?.length
          ? GroupUsers.map(({ users, ...rest }) => {
            let { email, firstName, lastName, photo } = users ?? {};

            return {
              ...rest,
              studentEmailId: email,
              name: `${firstName} ${lastName ?? ""}`,
              studentImageUrl: photo ? photo + user?.SASToken : userSvg,
            };
          })
          : [];
        setAllStudentsForGroup(groupUser);
      } else {
        setGroupName("");
        setAllStudentsForGroup([]);
        setSelectedImage(null);
        setGroupData(null);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getClassroomStudents = async () => {
    try {
      setIsClassDataLoading(true);
      const { data } = await axios({
        method: "GET",
        url: `${url}/api/classroom/all-classrooms-with-students${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (data?.result?.length) {
        let classNames = [];
        const formattedData = data.result.reduce((acc, classData) => {
          const { ClassName, StudentData } = classData;
          if (!classNames.includes(ClassName)) {
            classNames.push(ClassName);
            let formattedClass = {
              class: ClassName,
              students:
                Array.isArray(StudentData) && StudentData.length
                  ? StudentData.map(
                    ({ email, firstName, lastName, photo, ...rest }) => {
                      return {
                        ...rest,
                        studentEmailId: email,
                        name: `${firstName} ${lastName ? lastName : ""}`,
                        studentImageUrl: photo
                          ? photo + user?.SASToken
                          : userSvg,
                      };
                    }
                  )
                  : [],
            };
            return [...acc, formattedClass];
          } else {
            return acc;
          }
        }, []);
        setClassData(formattedData);
        setClassDropdownList(formattedData.map((item) => item.class));
      } else {
        setClassData([]);
        setClassDropdownList([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsClassDataLoading(false);
    }
  };

  useEffect(() => {
    // let id = query.get("groupId");
    if (user.mail && id) getGroup(id);
    getClassroomStudents();
  }, [id]);

  useEffect(() => {
    let filteredData = studentListOfSelectedClass;

    if (studentSearchText.length) {
      filteredData = filteredData.filter((data) =>
        data.name?.toLowerCase()?.includes(studentSearchText?.toLowerCase())
      );
    }

    setFilteredStudentListOfSelectedClass(filteredData);
  }, [studentListOfSelectedClass, studentSearchText]);

  const studentCard = ({ student }) => {
    return (
      <Card
        elevated
        className="fit-content all-feedback-card"
        fluid
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          width: "223px",
          height: "50px",
          margin: "10px",
          borderRadius: "7px",
        }}
      >
        <CardBody className="student-card-body" style={{ padding: "10px" }}>
          <Flex wrap space="between" vAlign="center">
            <Flex>
              <Flex>
                <Image
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    borderRadius: "4px",
                  }}
                  src={student.studentImageUrl}
                />
              </Flex>
              <Flex column vAlign="start">
                <Tooltip
                  content={
                    student.name ?? student.studentEmailId?.split("@")?.[0]
                  }
                  trigger={
                    <Text className="student-name-wrapper">
                      {student.name ?? student.studentEmailId?.split("@")?.[0]}
                    </Text>
                  }
                />
              </Flex>
            </Flex>
            <Flex>
              <Checkbox
                checked={selectedStudentsOfSelectedClass.some(
                  (item) => item.studentEmailId === student.studentEmailId
                )}
                onClick={(e, { checked }) => {
                  if (checked) {
                    if (
                      allStudentsForGroup.some(
                        (item) => item.studentEmailId === student.studentEmailId
                      )
                    ) {
                      toast.warning(feedbackTranslation.studentExistValidation);
                      return;
                    }
                    setSelectedStudentsOfSelectedClass([
                      ...selectedStudentsOfSelectedClass,
                      student,
                    ]);
                  } else {
                    setSelectedStudentsOfSelectedClass((prevData) =>
                      prevData.filter(
                        (item) => item.studentEmailId !== student.studentEmailId
                      )
                    );
                  }
                }}
              />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  };

  const selectedStudentsCard = ({ student }) => {
    return (
      <Segment
        key={student.studentEmailId}
        content={
          <Flex style={{ flexDirection: "column" }}>
            <Image
              src={student.studentImageUrl ? student.studentImageUrl : userSvg}
              width="100%"
              height="200px"
              className="img"
            />
            <Text
              content={student.name ?? student.studentEmailId?.split("@")?.[0]}
              className="Karla fw6 m1 tcap"
            />
          </Flex>
        }
        onClick={() => {
          selectedStudentToDelete.some(
            (item) => item.studentEmailId === student.studentEmailId
          )
            ? setSelectedStudentToDelete((prevData) =>
              prevData.filter(
                (data) => data.studentEmailId !== student.studentEmailId
              )
            )
            : setSelectedStudentToDelete((prevData) => [...prevData, student]);
        }}
        className="student-card-wrapper"
        style={{
          border: selectedStudentToDelete.some(
            (item) => item.studentEmailId === student.studentEmailId
          )
            ? "2px solid red"
            : "none",
        }}
      />
    );
  };

  const createGroupHandler = async () => {
    setIsSubmitting({ isLoading: true, action: createUpdateGroup });
    let isCreatedOrUpdated = false;
    try {
      let formData = new FormData();
      const payload = {
        groupImage: "image/url",
        groupName: groupName,
        studentEmails: allStudentsForGroup.map(
          (student) => student.studentEmailId
        ),
        createdBy: user?.mail,
      };
      if (selectedImage) {
        let imageUrl = null;
        if (selectedImage?.includes("blob.core.windows.net")) {
          imageUrl = selectedImage;
        } else {
          const response = await fetch(selectedImage);
          const blob = await response.blob();
          formData.append("groupImageData", blob);
          // let storagePath = `feedback/group/${user.mail}-${groupName
          //   ?.replace(/\s/g, "")
          //   ?.replace("/", "-")}-${Date.now()}`;
          // imageUrl = await uploadFileToBlob(
          //   await fetch(selectedImage).then((r) => r.blob()),
          //   storagePath
          // );
        }
        payload.groupImage = imageUrl;
      } else {
        payload.groupImage = null;
      }
      setIsValidateFields(true);

      if (!payload.studentEmails?.length) {
        toast.error(feedbackTranslation.minStudentInGroup);
        return;
      }

      console.log(payload, "payload");

      Object.keys(payload).forEach((key) => {
        if (key === "groupImage") {
          formData.append(key, removeSasToken(payload[key]));
        } else {
          formData.append(key, JSON.stringify(payload[key]));
        }
      });


      if (groupName.length && !!payload.studentEmails?.length) {
        if (groupId) {
          const { data } = await axios({
            url: `${url}/api/feedback/group/update-group/${groupId}${user.slug}`,
            method: "PUT",
            data: formData,
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          });
          if (
            data?.status?.toLowerCase() === "success" &&
            data.result === "Group update successfully"
          ) {
            toast.success(feedbackTranslation.groupUpdated);
            isCreatedOrUpdated = true;
          }
        } else {
          const { data } = await axios({
            url: `${url}/api/feedback/group/create-group${user.slug}`,
            method: "POST",
            data: formData,
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          });
          if (
            data?.status?.toLowerCase() === "success" &&
            !!data.result?.groupId?.length
          ) {
            toast.success(feedbackTranslation.groupCreated);

            isCreatedOrUpdated = true;
          } else {
            toast.error(
              typeof data.result?.message === "string"
                ? data.result.message
                : feedbackTranslation.somethingWentWrong
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting({ isLoading: false, action: createUpdateGroup });
      if (isCreatedOrUpdated) {
        history.push("/feedback/groups");
      }
    }
  };

  const deleteGroupHandler = async () => {
    let isDeleted = false;
    try {
      setIsSubmitting({ isLoading: true, action: deleteGroup });
      const { data } = await axios({
        url: `${url}/api/feedback/group/delete-group/${groupId}${user.slug}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.status?.toLowerCase() === "success") {
        toast.success(
          typeof data.result === "string"
            ? data.result
            : feedbackTranslation.groupDeleted
        );
        isDeleted = true;
      }
    } catch (error) {
    } finally {
      setIsSubmitting({ isLoading: false, action: deleteGroup });
      if (isDeleted) {
        history.push("/feedback/groups");
      }
    }
  };

  return (
    <div className="group">
      <Dialog
        open={confirmCancel}
        onConfirm={() => {
          history.push("/feedback/groups");
          setConfirmCancel(false);
        }}
        onCancel={() => {
          setConfirmCancel(false);
        }}
        header={translation.common.cancel}
        content={
          <Flex column>
            <Text>{feedbackTranslation.cancelConfirmation}</Text>
          </Flex>
        }
        headerAction={{
          icon: <CloseIcon />,
          title: translation.common.close,
          onClick: () => {
            setConfirmCancel(false);
          },
        }}
        confirmButton={translation.common.yes}
        cancelButton={translation.common.no}
        styles={{ maxWidth: "600px" }}
      />
      <Dialog
        open={confirmDelete}
        onConfirm={() => {
          deleteGroupHandler();
          setConfirmDelete(false);
        }}
        onCancel={() => {
          setConfirmDelete(false);
        }}
        header={feedbackTranslation.deleteGroup}
        headerAction={{
          icon: <CloseIcon />,
          title: translation.common.close,
          onClick: () => {
            setConfirmDelete(false);
          },
        }}
        content={
          <Flex column>
            <Text>{feedbackTranslation.deleteConfirmation}</Text>
          </Flex>
        }
        confirmButton={translation.common.yes}
        cancelButton={translation.common.no}
        styles={{ maxWidth: "600px" }}
      />
      {isLoading ? (
        <Loader size="medium" style={{ paddingTop: "50px" }} />
      ) : (
        <div>
          {!groupId || (groupId && groupData) ? (
            <div>
              <div>
                <Flex vAlign="center">
                  <IconButton
                    iconProps={{ iconName: "ChromeBack" }}
                    title={translation.common.back}
                    ariaLabel={translation.common.back}
                    onClick={() => {
                      setConfirmCancel(true);
                    }}
                  />
                  <Text
                    size="large"
                    weight="semibold"
                    style={{ marginRight: "10px" }}
                  >
                    {groupId
                      ? feedbackTranslation.editGroup
                      : feedbackTranslation.createNewGroup}
                  </Text>
                </Flex>
              </div>
              <div>
                <div className="ms-Grid">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8">
                      <Flex column style={{ marginBottom: "10px" }}>
                        <Flex.Item grow>
                          <TextField
                            value={groupName}
                            onChange={(e, value) => {
                              setGroupName(value);
                            }}
                            style={{
                              background: "#F5F5F5",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                            borderless={true}
                            label={feedbackTranslation.groupName}
                            required
                            errorMessage={
                              isValidateFields
                                ? !groupName.length
                                  ? feedbackTranslation.groupNameValidation
                                  : ""
                                : ""
                            }
                          />
                        </Flex.Item>
                        <Flex
                          fill
                          wrap
                          space="between"
                          vAlign="center"
                          style={{ marginTop: "15px", rowGap: "10px" }}
                        >
                          <Text weight="regular" size="medium">
                            {feedbackTranslation.groupMembers}
                          </Text>
                          <Flex gap="gap.small" wrap>
                            {selectedStudentToDelete.length ? (
                              <FeedbackDialog
                                button={
                                  <Button
                                    content={feedbackTranslation.removeSelected}
                                    styles={{
                                      backgroundColor: "#C4314B",
                                      color: "white",
                                    }}
                                  />
                                }
                                header={feedbackTranslation.removeFromGroup}
                                headerAction={{
                                  icon: <CloseIcon />,
                                  title: translation.common.close,
                                  onClick: () => { },
                                }}
                                confirmButton={feedbackTranslation.yesRemove}
                                onConfirm={() => {
                                  let deleteStudentEmailIds =
                                    selectedStudentToDelete.map(
                                      (student) => student.studentEmailId
                                    );
                                  setAllStudentsForGroup((prevData) =>
                                    prevData.filter(
                                      (student) =>
                                        !deleteStudentEmailIds.includes(
                                          student.studentEmailId
                                        )
                                    )
                                  );
                                  setSelectedStudentToDelete([]);
                                }}
                                content={
                                  <Flex column>
                                    <Text>
                                      {
                                        feedbackTranslation.removeStudentConfirmation
                                      }
                                    </Text>
                                  </Flex>
                                }
                                styles={{ maxWidth: "600px" }}
                              />
                            ) : null}
                            <FeedbackDialog
                              closeOnOutsideClick={false}
                              button={
                                <Button
                                  onClick={() => {
                                    setStudentSearchText("");
                                  }}
                                  content={feedbackTranslation.addMembers}
                                  loading={isClassDataLoading}
                                  disabled={
                                    isSubmitting.isLoading || isClassDataLoading
                                  }
                                />
                              }
                              header={feedbackTranslation.addMembers}
                              headerAction={{
                                icon: <CloseIcon />,
                                title: translation.common.close,
                                onClick: () => {
                                  setStudentListOfSelectedClass([]);
                                  setSelectedStudentsOfSelectedClass([]);
                                  setSelectedClass(null);
                                },
                              }}
                              confirmButton={translation.common.done}
                              onConfirm={() => {
                                setSelectedClass(null);
                                setStudentListOfSelectedClass([]);
                                setSelectedStudentsOfSelectedClass([]);
                                setSelectedStudentByNameEmail([]);

                                setAllStudentsForGroup((prevData) => {
                                  let students = prevData.map(
                                    (student) => student.studentEmailId
                                  );

                                  const formattedMembers =
                                    selectedStudentByNameEmail.map(
                                      ({
                                        header,
                                        role,
                                        email,
                                        image,
                                        userID,
                                      }) => ({
                                        name: header,
                                        role,
                                        studentEmailId: email,
                                        studentImageUrl: image,
                                        userID,
                                      })
                                    );

                                  let formattedData = [
                                    ...selectedStudentsOfSelectedClass,
                                    ...formattedMembers,
                                  ].filter(
                                    (data) =>
                                      !students.includes(data.studentEmailId)
                                  );
                                  return [...prevData, ...formattedData];
                                });
                              }}
                              content={
                                <AddMember
                                  {...props}
                                  classDropdownList={classDropdownList}
                                  setSelectedClass={setSelectedClass}
                                  setSelectedStudentsOfSelectedClass={
                                    setSelectedStudentsOfSelectedClass
                                  }
                                  setStudentListOfSelectedClass={
                                    setStudentListOfSelectedClass
                                  }
                                  classData={classData}
                                  selectedStudentsOfSelectedClass={
                                    selectedStudentsOfSelectedClass
                                  }
                                  studentListOfSelectedClass={
                                    studentListOfSelectedClass
                                  }
                                  allStudentsForGroup={allStudentsForGroup}
                                  setStudentSearchText={setStudentSearchText}
                                  studentSearchText={studentSearchText}
                                  filteredStudentListOfSelectedClass={
                                    filteredStudentListOfSelectedClass
                                  }
                                  studentCard={studentCard}
                                  selectedClass={selectedClass}
                                  selectedStudentByNameEmail={
                                    selectedStudentByNameEmail
                                  }
                                  setSelectedStudentByNameEmail={
                                    setSelectedStudentByNameEmail
                                  }
                                />
                              }
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                      <Row gutter={[10, 10]} style={{ margin: 0 }}>
                        {allStudentsForGroup?.length
                          ? allStudentsForGroup.map((student, index) => (
                            <Col
                              xs={24}
                              sm={12}
                              md={8}
                              xl={6}
                              xxl={6}
                              key={`Group-student-${index}`}
                            >
                              {selectedStudentsCard({ student })}
                            </Col>
                          ))
                          : null}
                      </Row>
                      {/* <Flex
                        wrap
                        style={{
                          rowGap: "15px",
                          gap: "5px",
                          minHeight: "100px",
                          marginTop: "15px",
                        }}
                      >
                        {allStudentsForGroup?.length
                          ? allStudentsForGroup.map((student, index) =>
                              selectedStudentsCard({ student })
                            )
                          : null}
                      </Flex> */}
                      <div className="group-image-mobile">
                        <Flex
                          fill
                          hAlign="end"
                          style={{ marginTop: "15px", paddingRight: "10px" }}
                        >
                          <Card
                            elevated
                            className="file-browse-section-card"
                            fluid
                            styles={{
                              ":hover": { backgroundColor: "white" },
                              backgroundColor: "white",
                              width: "325px",
                              height: "150px",
                              borderRadius: "4px",
                              padding: "5px",
                            }}
                          >
                            <CardBody
                              style={{
                                marginBottom: "0px",
                                height: "100%",
                              }}
                            >
                              {selectedImage ? (
                                <FeedbackDialog
                                  button={
                                    <img
                                      src={selectedImage}
                                      style={{ width: "100%", height: "100%" }}
                                      // onClick={() => setSelectedImage(null)}
                                      onError={(e) => {
                                        // setSelectedImage(null);
                                        // e.target.src =
                                        //   "https://img.freepik.com/free-vector/group-therapy-illustration-concept_52683-45727.jpg";
                                      }}
                                    />
                                  }
                                  header={feedbackTranslation.groupImage}
                                  headerAction={{
                                    icon: <CloseIcon />,
                                    title: translation.common.close,
                                    onClick: () => { },
                                  }}
                                  onOpen={() => {
                                    // setTempData({ ...sharedWith });
                                  }}
                                  confirmButton={translation.common.delete}
                                  cancelButton={translation.common.cancel}
                                  onConfirm={() => {
                                    setSelectedImage(null);
                                  }}
                                  content={
                                    <ImageWrapper>
                                      <img
                                        src={selectedImage}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain"
                                        }}
                                        onError={(e) => {
                                          // setSelectedImage(null);
                                          e.target.src =
                                            "https://img.freepik.com/free-vector/group-therapy-illustration-concept_52683-45727.jpg";
                                        }}
                                      />
                                    </ImageWrapper>
                                  }
                                />
                              ) : (
                                <FileUploader
                                  handleChange={(file) => {
                                    setSelectedImage(URL.createObjectURL(file));
                                  }}
                                  types={["png", "jpeg", "jpg"]}
                                  //   value={file}
                                  children={
                                    <div
                                      style={{
                                        border: "1px dashed gray",
                                        display: "flex",
                                        justifyCcontent: "center",
                                        height: "140px",
                                      }}
                                    >
                                      <Flex
                                        column
                                        fill
                                        hAlign="center"
                                        style={{ padding: "35px 0" }}
                                        vAlign="center"
                                      >
                                        <Text
                                          align="center"
                                          size="small"
                                          weight="bold"
                                        >
                                          {feedbackTranslation.groupImage}
                                        </Text>
                                        <Text align="center" size="small">
                                          {feedbackTranslation.dragNDrop}
                                        </Text>
                                      </Flex>
                                    </div>
                                  }
                                  name="file"
                                  fluid
                                />
                              )}
                              <div>
                                {!!selectedImage && (
                                  <DeleteImageButton
                                    content={feedbackTranslation.deleteImage}
                                    primary
                                    onClick={() => {
                                      setSelectedImage(null);
                                    }}
                                  />
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </Flex>
                      </div>
                      <Flex
                        wrap
                        gap="gap.medium"
                        hAlign="end"
                        style={{ marginTop: "15px", rowGap: "10px" }}
                      >
                        <Button
                          content={translation.common.cancel}
                          onClick={() => {
                            setConfirmCancel(true);
                          }}
                          disabled={isSubmitting.isLoading}
                        />
                        <Button
                          content={
                            groupId
                              ? translation.common.save
                              : translation.common.create
                          }
                          loading={
                            isSubmitting.isLoading &&
                            isSubmitting.action === createUpdateGroup
                          }
                          disabled={isSubmitting.isLoading}
                          primary
                          onClick={() => {
                            createGroupHandler();
                          }}
                        />
                        {!!groupId && !!groupData && (
                          <Button
                            content={feedbackTranslation.deleteGroup}
                            loading={
                              isSubmitting.isLoading &&
                              isSubmitting.action === deleteGroup
                            }
                            disabled={isSubmitting.isLoading}
                            onClick={() => {
                              setConfirmDelete(true);
                            }}
                            style={{
                              backgroundColor: isSubmitting.isLoading
                                ? "#edebe9"
                                : "#a91b0d",
                              color: isSubmitting.isLoading
                                ? "#c8c6c4"
                                : "white",
                            }}
                          />
                        )}
                      </Flex>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4 group-image">
                      <Flex
                        fill
                        hAlign="end"
                        style={{ marginTop: "15px", paddingRight: "10px" }}
                      >
                        <Card
                          elevated
                          className="file-browse-section-card"
                          fluid
                          styles={{
                            ":hover": { backgroundColor: "white" },
                            backgroundColor: "white",
                            width: "325px",
                            height: "150px",
                            borderRadius: "4px",
                            padding: "5px",
                          }}
                        >
                          <CardBody
                            style={{
                              marginBottom: "0px",
                              height: "100%",
                            }}
                          >
                            {selectedImage ? (
                              <FeedbackDialog
                                button={
                                  <img
                                    src={selectedImage}
                                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                    // onClick={() => setSelectedImage(null)}
                                    onError={(e) => {
                                      // setSelectedImage(null);
                                      // e.target.src =
                                      //   "https://img.freepik.com/free-vector/group-therapy-illustration-concept_52683-45727.jpg";
                                    }}
                                  />
                                }
                                header={feedbackTranslation.groupImage}
                                headerAction={{
                                  icon: <CloseIcon />,
                                  title: translation.common.close,
                                  onClick: () => { },
                                }}
                                onOpen={() => {
                                  // setTempData({ ...sharedWith });
                                }}
                                confirmButton={translation.common.delete}
                                cancelButton={translation.common.cancel}
                                onConfirm={() => {
                                  setSelectedImage(null);
                                }}
                                content={
                                  <ImageWrapper>
                                    <img
                                      src={selectedImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                      onError={(e) => {
                                        // setSelectedImage(null);
                                        e.target.src =
                                          "https://img.freepik.com/free-vector/group-therapy-illustration-concept_52683-45727.jpg";
                                      }}
                                    />
                                  </ImageWrapper>
                                }
                              />
                            ) : (
                              <FileUploader
                                handleChange={(file) => {
                                  setSelectedImage(URL.createObjectURL(file));
                                }}
                                types={["png", "jpeg", "jpg"]}
                                //   value={file}
                                children={
                                  <div
                                    style={{
                                      border: "1px dashed gray",
                                      display: "flex",
                                      justifyCcontent: "center",
                                      height: "140px",
                                    }}
                                  >
                                    <Flex
                                      column
                                      fill
                                      hAlign="center"
                                      style={{ padding: "35px 0" }}
                                      vAlign="center"
                                    >
                                      <Text
                                        align="center"
                                        size="small"
                                        weight="bold"
                                      >
                                        {feedbackTranslation.groupImage}
                                      </Text>
                                      <Text align="center" size="small">
                                        {feedbackTranslation.dragNDrop}
                                      </Text>
                                    </Flex>
                                  </div>
                                }
                                name="file"
                                fluid
                              />
                            )}
                            <div>
                              {!!selectedImage && (
                                <DeleteImageButton
                                  content={feedbackTranslation.deleteImage}
                                  primary
                                  onClick={() => {
                                    setSelectedImage(null);
                                  }}
                                />
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Flex>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <Flex vAlign="center">
                  <IconButton
                    iconProps={{ iconName: "ChromeBack" }}
                    title={translation.common.back}
                    ariaLabel={translation.common.back}
                    onClick={() => {
                      history.push("/feedback/groups");
                    }}
                  />
                  <Text
                    size="large"
                    weight="semibold"
                    style={{ marginRight: "10px" }}
                  >
                    {feedbackTranslation.header}
                  </Text>
                </Flex>
              </div>

              <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
                <ResponsiveImage src={noDataImage} style={{ width: "252px" }} />
                <Text size="large">
                  {feedbackTranslation.noDataFound}
                  <Button
                    onClick={() => {
                      history.push("/feedback/groups/group?isNew=true");
                      setGroupName("");
                      setAllStudentsForGroup([]);
                      setSelectedImage(null);
                      setGroupData(null);
                      setGroupId(null);
                    }}
                    style={{ border: 0, marginTop: "-3px" }}
                    text
                  >
                    <Text
                      style={{
                        color: "#494b83",
                      }}
                      weight="semibold"
                    >
                      {feedbackTranslation.clickHere}
                    </Text>
                  </Button>
                  {feedbackTranslation.toCreateGroup}
                </Text>
              </Flex>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Group);
