import React, { useEffect, useLayoutEffect, useState } from "react";
import Calender from "../Calender/Calender";
import { ArrowLeftIcon } from "@fluentui/react-northstar";
import { useBooking } from "../../../../context/BookingContext";
import MonthSlider from "../MonthSlider/MonthSlider";
import Header from "../../../../components/Header/Header";
import moment from "moment";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Slots from "./Slots/Slots";
import MeetingDetailsForm from "./MeetingDetailsForm/MeetingDetailsForm";
import axios from "axios";
import { useTranslation } from "react-i18next";
import BookingDialog from "../BookingDialog/BookingDialog";
import AddMember from "../AddMember";
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  CloseIcon,
  Dialog,
  Dropdown,
  Flex,
  Image,
  Loader,
  Segment,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import { toast } from "react-toastify";
import userSvg from "../../../../Assets/images/svg/user.svg";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";

const url = process.env.REACT_APP_EP_URL;

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onFailure,
    scope:
      "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.events",
    ux_mode: "popup",
    select_account: true,
    // flow: "auth-code",
  });

  return (
    <button
      id="google-login-btn"
      style={{ display: "none" }}
      onClick={googleLogin}
    >
      MeetingDetailsForm
    </button>
  );
};

const ScheduleMeeting = (props) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const {
    getScheduledSlots,
    selectedDates,
    selectedSlotId,
    step,
    setStep,
    setSelectedTab,
    setSelectedSlotId,
    user,
    editingScheduleData,
    setEditingScheduleData,
    setSelectedDates,
    meetingData,
    setMeetingData,
    otherTopics,
    setOtherTopics,
    handleBookSlot,
    showError,
    selectedMeetType,
    setSelectedMeetType,
    openEventLoginModal,
    setOpenEventLoginModal,
    handleLogin,
    handleMSLogin,
    onSuccess,
    onFailure,
    googleLoginHandler,
    isSubmitting,
    handleUpdateBookedSlot,
  } = useBooking();
  const { mail, role, slotId = "" } = useParams();
  const history = useHistory();

  const { t } = useTranslation();
  const translation = t("booking").scheduleAMeetingPage;
  const eventTranslation = t("events").body.eventForm;
  const commonTranslation = t("booking").common;
  const signInDialogTranslation = t("booking").signInDialog;

  const isEditing =
    history.location.pathname ===
    `/edit-schedule-meeting/${mail}/${role}/${slotId}`;

  const [userProfileData, setUserProfileData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);

  const getProfileData = async () => {
    try {
      setIsLoadingData(true);
      const { slug, accessToken } = user;
      const { data } = await axios.get(
        `${url}/api//tutor/get-tutor-info/${mail}${slug}&role=${role}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (data) {
        let profileData = {
          ...data,
          skills: data.ProfileSkills,
          interests: data.ProfileInterests,
        };
        setUserProfileData(profileData);
      } else {
        setUserProfileData({});
      }
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    mail &&
      selectedDates.length &&
      getScheduledSlots(
        moment(selectedDates[0]).format("YYYY-MM-DD"),
        moment(selectedDates[0]).format("YYYY-MM-DD"),
        selectedDates,
        mail
      );
  }, [mail, selectedDates]);

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    if (isEditing) {
      const editingSchedule = JSON.parse(
        localStorage.getItem("editingSchedule") || "{}"
      );
      if (Number(editingSchedule?.slotId) === Number(slotId)) {
        setEditingScheduleData(editingSchedule);
        setSelectedDates([
          moment(editingSchedule.slot.startTime)
            .clone()
            .startOf("day")
            .toISOString(),
        ]);
      } else {
        setEditingScheduleData(null);
      }
    }
  }, [isEditing]);

  useLayoutEffect(() => {
    if (isEditing && slotId) {
      setSelectedSlotId(slotId);
    }
  }, [isEditing, slotId]);

  const isShowActionButtons = isEditing
    ? Number(selectedSlotId) !== Number(slotId)
    : selectedSlotId;

  return (
    <div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {/* <div className="App event-form google-login-btn">
          <GoogleLogin
            buttonText="Sign in with Google"
            onSuccess={onSuccess}
            onError={onFailure}
            scope="https://www.googleapis.com/auth/calendar.events"
          />
        </div> */}
        <GoogleLoginButton onSuccess={onSuccess} onFailure={onFailure} />
      </GoogleOAuthProvider>
      <Dialog
        cancelButton={commonTranslation.cancel}
        confirmButton={signInDialogTranslation.signIn}
        open={openEventLoginModal.isOpen}
        onCancel={() => {
          setOpenEventLoginModal({ isOpen: false, type: null });
          setSelectedMeetType("");
        }}
        onConfirm={() => {
          setOpenEventLoginModal({ ...openEventLoginModal, isOpen: false });
          if (openEventLoginModal.type === "isMSTeamMeeting") {
            handleMSLogin({ type: "isMSTeamMeeting", value: true });
          } else if (openEventLoginModal.type === "isGoogleMeetMeeting") {
            googleLoginHandler();
          }
        }}
        closeOnOutsideClick={false}
        content={
          <div>
            <Flex wrap>
              {openEventLoginModal.type === "isMSTeamMeeting"
                ? eventTranslation.MSTeamsMeeting.signInConfirmation
                : eventTranslation.googleMeetMeeting.signInConfirmation}
            </Flex>
          </div>
        }
        header={signInDialogTranslation.signIn}
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setOpenEventLoginModal({
              isOpen: false,
              type: null,
            });
            setSelectedMeetType("");
          },
        }}
      />
      <h1 style={{ color: "#6264a7", fontWeight: "bold", paddingLeft: "20px" }}>
        <Flex gap="gap.medium" vAlign="center">
          <ArrowLeftIcon
            size="medium"
            onClick={() => {
              if (isEditing) {
                history.push(`/booking`);
                setSelectedTab("mySchedules");
              } else {
                history.push(`/tutor/${mail}/${role}`);
              }
            }}
          />
          <span>
            {isEditing
              ? translation.editScheduledMeeting
              : translation.scheduleAMeeting}
          </span>
        </Flex>
      </h1>

      {step === 1 ? (
        <Flex hAlign="center" style={{ justifyContent: "space-around" }} wrap>
          <div>
            <p
              style={{
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "20px",
              }}
            >
              {translation.selectDate}
            </p>
            <MonthSlider />
            <Calender />
          </div>
          <Flex
            column
            style={{
              justifyContent: "space-between",
              marginTop: isMobileView ? "20px" : 0,
              marginBottom: isMobileView ? "20px" : 0,
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "20px",
                }}
              >
                {translation.availableTimeSlots}
              </p>
              <Slots isEditing={isEditing} isMobileView={isMobileView} />
            </div>
            {isShowActionButtons ? (
              <Flex
                hAlign="end"
                gap="gap.medium"
                style={{ marginTop: isMobileView ? "20px" : 0 }}
              >
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    if (isEditing) {
                      setSelectedSlotId(slotId);
                    } else {
                      setSelectedSlotId("");
                    }
                  }}
                >
                  {commonTranslation.cancel}
                </Button>
                {isEditing ? (
                  <Button
                    primary
                    onClick={handleUpdateBookedSlot}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Flex gap="gap.small">
                        <Loader size="small" />
                        <span>{`${translation.updating}...`}</span>
                      </Flex>
                    ) : (
                      translation.update
                    )}
                  </Button>
                ) : (
                  <Button
                    primary
                    onClick={() => {
                      setStep(step + 1);
                    }}
                  >
                    {commonTranslation.next}
                  </Button>
                )}
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      ) : step === 2 ? (
        <Flex hAlign="center">
          <MeetingDetailsForm
            {...props}
            tutorInfo={userProfileData}
            isLoadingData={isLoadingData}
          />
        </Flex>
      ) : (
        ""
      )}
    </div>
  );
};

export default ScheduleMeeting;
