import { Button, Tooltip } from "@fluentui/react-northstar";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import Chart from "../../Container/Charts";
import { reportTypes } from "../constant";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();

const AssignmentSubmissionReport = (props) => {
  const { user, t } = props;

  const { slug } = user;

  const [chartData, setChartData] = useState({});
  const [filterBy, setFilterBy] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date(
      d.getFullYear(),
      d.getMonth() - 3,
      d.getDate(),
      0,
      0,
      0,
      0
    ),
    endDate: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [allClassNames, setAllClassNames] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedColony, setSelectedColony] = useState([]);
  const [selectedInternetSpeed, setSelectedInternetSpeed] = useState([]);
  const [customFields, setCustomFields] = useState(null);
  const [customFieldFilter, setCustomFieldFilter] = useState({
    colonyNames: null,
    allInternetSpeed: null,
    isEmployee: null,
    isScholarship: null,
    isPassing: null,
    filerts: {
      CF_1: null,
      CF_2: null,
      CF_3: null,
      CF_4: null,
      CF_5: null,
    },
  });
  const [sortOrder, setSortOrder] = useState("desc"); // Default descending

  const toggleSort = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const fetchReportData = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        url: `${url}/api/chart/get-chart-by-assignment-submission${slug}`,
        method: "post",
        data: {
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          ClassName: selectedClass.length ? selectedClass : null,
          ...customFieldFilter.filerts,
        },
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });

      if (response.data?.result?.data) {
        let responseData = response.data.result;

        setCustomFields(
          Object.entries(responseData.customFields).length
            ? responseData.customFields
            : null
        );

        let submissionData = responseData.data?.length ? responseData.data : [];

        let totalAssignments = responseData.totalAssignments;

        let classNames = responseData.classData?.length
          ? responseData.classData.map((item) => item.ClassName)
          : [];

        let totalSubmission = submissionData.reduce((acc, item) => {
          return acc + (Number(item.Total) || 0);
        }, 0);
        let completedSubmission = submissionData.reduce((acc, item) => {
          return acc + (Number(item.Completed) || 0);
        }, 0);
        let pendingSubmission = submissionData.reduce((acc, item) => {
          return acc + (Number(item.Pending) || 0);
        }, 0);
        let gradesPendingSubmission = submissionData.reduce((acc, item) => {
          return acc + (Number(item["Grades Pending"]) || 0);
        }, 0);
        let resubmitSubmission = submissionData.reduce((acc, item) => {
          return acc + (Number(item.Resubmit) || 0);
        }, 0);
        let boxData = {
          totalStudents: submissionData.length,
          totalAssignments,
          totalSubmission,
          completedSubmission,
          pendingSubmission,
          gradesPendingSubmission,
          resubmitSubmission,
        };

        let pieData = {
          pie1: submissionData
            // Step 1: Filter out items where `StudentDatum` is not defined
            .filter((item) => item.StudentDatum)
            // Step 2: Sort the array by `Total` in descending order
            .sort((a, b) => b.Total - a.Total)
            // Step 3: Take the first 10 records from the sorted array
            .slice(0, 10)
            // Step 4: Reduce these records into an accumulator object
            .reduce((acc, item) => {
              acc[item.StudentDatum.StudentName] = item.Total;
              return acc;
            }, {}),
          pie2: {
            completed: completedSubmission,
            pending: pendingSubmission,
            gradesPending: gradesPendingSubmission,
            resubmit: resubmitSubmission,
          },
        };

        let barData = [...submissionData];
        let tableData = submissionData;

        if (!allClassNames.length) {
          setAllClassNames(classNames);
        }

        setChartData({
          boxData,
          pieData,
          barData,
          tableData,
        });
      } else {
        setChartData({});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortObject = (data) => {
    // Convert object to array of [key, value] pairs
    const sortedEntries = Object.entries(data).sort((a, b) =>
      sortOrder === "desc" ? b[1] - a[1] : a[1] - b[1]
    );

    // Convert back to object
    const sortedObject = Object.fromEntries(sortedEntries);

    return sortedObject;
  };

  useEffect(() => {
    fetchReportData();
  }, [dateFilter, selectedClass, customFieldFilter]);

  const filterByHandler = (option) => {
    try {
      setFilterBy(option);

      setChartData((prevData) => {
        if (!prevData) return prevData; // Handle cases where prevData is undefined/null

        return {
          ...prevData,
          tableData: prevData?.tableData
            ? [...prevData.tableData].sort((a, b) =>
                sortOrder === "desc"
                  ? b[option] - a[option]
                  : a[option] - b[option]
              )
            : [],
          barData: prevData?.barData
            ? [...prevData.barData].sort((a, b) =>
                sortOrder === "desc"
                  ? b[option] - a[option]
                  : a[option] - b[option]
              )
            : [],
          pieData: prevData?.pieData
            ? {
                ...prevData.pieData,
                pie1: prevData?.pieData?.pie1
                  ? sortObject(prevData.pieData.pie1)
                  : {},
                pie2: prevData?.pieData?.pie2
                  ? sortObject(prevData.pieData.pie2)
                  : {},
              }
            : {},
        };
      });

      setTimeout(() => toggleSort(), 0); // Ensure state update before toggling
    } catch (error) {
      console.error("filterByHandler: error", error);
    }
  };

  return (
    <Chart
      mainPath={props.mainPath}
      showControls={true}
      isLoading={isLoading}
      report={reportTypes.assignmentStudent}
      user={props.user}
      logout={() => props.logout()}
      switchDb={props.switchDb}
      getDb={() => props.getDb()}
      path={props.match.path}
      header={{
        report: t("chart").screens.header.reports[0],
        lable: t("chart").screens.header.lable,
      }}
      startDate={dateFilter.startDate}
      endDate={dateFilter.endDate}
      startDateLabel={
        t("chart").screens.assignmets.grades.controls.date.date2[0]
      }
      endDateLabel={t("chart").screens.assignmets.grades.controls.date.date3[0]}
      startChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, startDate: selectedDate });
      }}
      endChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, endDate: selectedDate });
      }}
      showClassType={false}
      allClassNames={allClassNames}
      stateChangeHandler={(selectedClass) => {
        setSelectedClass(selectedClass);
      }}
      customFields={customFields}
      getFilters={customFieldFilter.filerts}
      setFilters={(filerts) => {
        setCustomFieldFilter((prevData) => ({ ...prevData, filerts }));
      }}
      // showLbl={[this.state.selectedClass?.length ? false : true]}
      boxData={
        chartData.boxData
          ? {
              [t("chart").screens.assignmets.grades.boxs[0]]:
                chartData.boxData.totalStudents,
              [t("chart").screens.assignmets.grades.boxs[1]]:
                chartData.boxData.totalAssignments,
              [t("chart").screens.assignmets.grades.boxs[2]]:
                chartData.boxData.totalSubmission,
              [t("chart").screens.assignmets.grades.boxs[3]]:
                chartData.boxData.completedSubmission,
              [t("chart").screens.assignmets.grades.boxs[4]]:
                chartData.boxData.pendingSubmission,
              [t("chart").screens.assignmets.grades.boxs[5]]:
                chartData.boxData.gradesPendingSubmission,
              [t("chart").screens.assignmets.grades.boxs[6]]:
                chartData.boxData.resubmitSubmission,
            }
          : null
      }
      pieData={{
        pie1: {
          lable: t("chart").screens.assignmets.grades.pie.pie1.lbl,
          data: chartData.pieData?.pie1,
        },
        pie2: {
          lable: t("chart").screens.assignmets.grades.pie.pie2.lbl,
          data: chartData.pieData?.pie2
            ? {
                [t("chart").screens.assignmets.grades.pie.pie2.dataLbl[0]]:
                  chartData.pieData.pie2.completed,
                [t("chart").screens.assignmets.grades.pie.pie2.dataLbl[1]]:
                  chartData.pieData.pie2.pending,
                [t("chart").screens.assignmets.grades.pie.pie2.dataLbl[2]]:
                  chartData.pieData.pie2.gradesPending,
                [t("chart").screens.assignmets.grades.pie.pie2.dataLbl[3]]:
                  chartData.pieData.pie2.resubmit,
              }
            : null,
        },
      }}
      columnData={{
        stacked: false,
        radius: 10,
        title: t("chart").screens.assignmets.grades.column.title,
        lableY: t("chart").screens.assignmets.grades.column.lableY,
        lableX: t("chart").screens.assignmets.grades.column.lableX,
        series: chartData.barData
          ? [
              {
                name: [t("chart").screens.assignmets.grades.column.series[0]],
                data: chartData.barData.slice(0, 7).map((submission) => {
                  return parseInt(submission.Completed) || 0;
                }),
              },
              {
                name: [t("chart").screens.assignmets.grades.column.series[1]],
                data: chartData.barData.slice(0, 7).map((submission) => {
                  return parseInt(submission.Pending) || 0;
                }),
              },
              {
                name: [t("chart").screens.assignmets.grades.column.series[2]],
                data: chartData.barData.slice(0, 7).map((submission) => {
                  return parseInt(submission["Grades Pending"]) || 0;
                }),
              },
              {
                name: [t("chart").screens.assignmets.grades.column.series[3]],
                data: chartData.barData.slice(0, 7).map((submission) => {
                  return parseInt(submission.Resubmit) || 0;
                }),
              },
            ]
          : [],
        categories: chartData.barData
          ? chartData.barData.slice(0, 7).map((submission) => {
              return document.documentElement.clientWidth < 500
                ? submission.StudentDatum.StudentName?.split("-")[0] + "..."
                : [
                    submission.StudentDatum.StudentName?.split("-")[0],
                    submission.StudentDatum.StudentName?.split("-")?.slice(1),
                  ];
            })
          : [],
      }}
      tableData={{
        header: {
          key: "Headers",
          items: t("chart").screens.assignmets.grades.table.header.map(
            (header, key) => {
              return {
                content:
                  key && key === 1 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "Completed" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("Completed")}
                    />
                  ) : key === 2 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "Pending" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("Pending")}
                    />
                  ) : key === 3 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color:
                          filterBy === "Grades Pending" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "50px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("Grades Pending")}
                    />
                  ) : key === 4 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "Resubmit" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("Resubmit")}
                    />
                  ) : key === 5 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "Total" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "50px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("Total")}
                    />
                  ) : (
                    header
                  ),
                key: "Header-" + header,
              };
            }
          ),
        },
        rows: chartData.tableData
          ? chartData.tableData.map((submission, key) => {
              return {
                key: submission.StudentDatum.StudentName + "key-" + key,
                items: [
                  {
                    content: submission.StudentDatum.StudentName,
                    key:
                      submission.StudentDatum.StudentName + "StudentName" + key,
                  },
                  {
                    content: submission.Completed,
                    key:
                      submission.StudentDatum.StudentName + "Completed" + key,
                  },
                  {
                    content: submission.Pending,
                    key: submission.StudentDatum.StudentName + "Pending" + key,
                  },
                  {
                    content: submission["Grades Pending"],
                    key:
                      submission.StudentDatum.StudentName +
                      "Grades Pending" +
                      key,
                  },
                  {
                    content: submission.Resubmit,
                    key: submission.StudentDatum.StudentName + "Resubmit" + key,
                  },
                  {
                    content: submission.Total,
                    key: submission.StudentDatum.StudentName + "Total" + key,
                  },
                ],
              };
            })
          : [],
      }}
      selectedExtension={props.selectedExtension}
      setSelectedExtension={props.setSelectedExtension}
    />
  );
};

export default withTranslation()(AssignmentSubmissionReport);
