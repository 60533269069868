import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TextField,
  Typography,
  FormHelperText,
  InputAdornment,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useChannelModal } from "../../../context/ChannelModalContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const SubChannelPasswordsModal = ({
  subChannelsCount,
  subChannelPrefix,
  subChannels,
  setSubChannels,
}) => {
  const [showPassword, setShowPassword] = useState({});

  const togglePasswordVisibility = (index) => {
    setShowPassword((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Function to initialize/reset channels
  const initializeChannels = () => {
    return Array.from({ length: subChannelsCount }, (_, index) => ({
      name: `${subChannelPrefix}-${index + 1}`,
      password: "",
      saved: false,
      error: "",
    }));
  };

  // Initialize on mount and when props change
  useEffect(() => {
    setSubChannels(initializeChannels());
  }, [subChannelsCount, subChannelPrefix]);

  const validatePassword = (password) => {
    if (password.length < 6) {
      return "Password must be at least 6 characters";
    }
    if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one uppercase letter";
    }
    if (!/[0-9]/.test(password)) {
      return "Password must contain at least one number";
    }
    return "";
  };

  const handlePasswordChange = (index, value) => {
    const updatedSubChannels = [...subChannels];
    const error = validatePassword(value);
    updatedSubChannels[index] = {
      ...updatedSubChannels[index],
      password: value,
      error,
    };
    setSubChannels(updatedSubChannels);
  };

  const handleSave = (index) => {
    const updatedSubChannels = [...subChannels];
    const error = validatePassword(updatedSubChannels[index].password);

    if (error) {
      updatedSubChannels[index] = {
        ...updatedSubChannels[index],
        error,
      };
      setSubChannels(updatedSubChannels);
      return;
    }

    updatedSubChannels[index] = {
      ...updatedSubChannels[index],
      saved: true,
      error: "",
    };
    setSubChannels(updatedSubChannels);
  };

  const handleDownloadExcel = () => {
    if (subChannels.every((channel) => channel.saved)) {
      const csvContent = subChannels
        .map((channel) => `${channel.name},${channel.password}`)
        .join("\n");

      const blob = new Blob([`Channel Name,Password\n${csvContent}`], {
        type: "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sub_channel_passwords.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  const { modals, closeModal, switchModals } = useChannelModal();
  const handleClose = () => {
    closeModal("subChannelPassword");
    switchModals("subChannelPassword", "onDemand");
    setSubChannels((prev) =>
      prev.map((channel) => ({ ...channel, saved: false }))
    );
  };

  return (
    <Dialog
      open={modals.subChannelPassword}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "0.75rem",
          maxWidth: "32rem",
          width: "100%",
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: "2px solid #494B83",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" component="h2">
          Sub-channel Passwords
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "1.5rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Channel Name</TableCell>
              <TableCell>Password</TableCell>
              <TableCell align="right">Save</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subChannels.map((channel, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body2">{channel.name}</Typography>
                </TableCell>
                <TableCell>
                  <div>
                    <TextField
                      type={showPassword[index] ? "text" : "password"}
                      value={channel.password}
                      onChange={(e) =>
                        handlePasswordChange(index, e.target.value)
                      }
                      placeholder="Set Password"
                      fullWidth
                      size="small"
                      variant="outlined"
                      error={Boolean(channel.error)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#494B83",
                            borderWidth: "2px",
                          },
                          "&:hover fieldset": {
                            borderColor: "#494B83",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#494B83",
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => togglePasswordVisibility(index)}
                              edge="end"
                            >
                              {showPassword[index] ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {channel.error && (
                      <FormHelperText error>{channel.error}</FormHelperText>
                    )}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => handleSave(index)}
                    disabled={
                      channel.saved ||
                      !channel.password ||
                      Boolean(channel.error)
                    }
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "#494B83",
                      color: "white !important",
                      "&:hover": { backgroundColor: "#3a3c69" },
                      "&.Mui-disabled": {
                        backgroundColor: "#494B83",
                        opacity: 0.5,
                      },
                    }}
                  >
                    {channel.saved ? "Saved" : "Save"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>

      <Box
        sx={{
          display: "flex",
          borderTop: "2px solid #494B83",
          flexDirection: "column",
          gap: 1,
          p: 3,
        }}
      >
        <Button
          onClick={handleDownloadExcel}
          fullWidth
          variant="contained"
          disabled={!subChannels.every((channel) => channel.saved)}
          sx={{
            backgroundColor: "#494B83",
            color: "white",
            "&:hover": { backgroundColor: "#3a3c69" },
          }}
        >
          Download Excel List
        </Button>
        <Button
          onClick={handleClose}
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "#494B83",
            color: "white",
            mx: 0,
            "&:hover": { backgroundColor: "#3a3c69" },
          }}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};
