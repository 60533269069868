import { Box, Chip, Input, Paper } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import FilterDrawer from "./Drawer";
import { CalendarToday, Person, Tag } from "@mui/icons-material";
import { useFilter } from "./contexts";
import axios from "axios";

function FilterCollection({
  setSearch,
  isFilterApply,
  setFilterApply,
  onFetchCollections,
  sort,
  setSort,
  slug,
}) {
  const [filterOption, setFilterOption] = useState({
    authors: [],
    tags: [],
  });

  const { filterDataWithKeys, setFilterDataWithKeys } = useFilter();

  useEffect(() => {
    const getTemplet = () => {
      axios
        .get(
          `${process.env.REACT_APP_EP_URL}/api/library/filtered-collection-option${slug}`
        )
        .then((res) => {
          setFilterOption(res.data.result);
        })
        .catch((err) => {
          console.error(err, err.response);
        });
    };

    getTemplet();
  }, [slug]);

  const removeSelectedFilter = useCallback(
    (category, value) => {
      const updatedCategory = Array.isArray(filterDataWithKeys[category])
        ? filterDataWithKeys[category].filter((item) => item !== value)
        : "";

      setFilterDataWithKeys({
        ...filterDataWithKeys,
        [category]: updatedCategory,
      });
      setTimeout(() => {
        onFetchCollections();
      }, 1000);
    },
    [filterDataWithKeys, setFilterDataWithKeys]
  );

  const renderSelectedFilter = useMemo(() => {
    return (
      <Paper elevation={0} sx={{ p: 2, bgcolor: "transparent" }}>
        {/* Filters Display */}
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {/* Age Filter */}
          {filterDataWithKeys.age && (
            <Chip
              icon={<CalendarToday fontSize="small" />}
              label={`Age: ${
                filterDataWithKeys.age > 7
                  ? `${filterDataWithKeys.age / 30} Month`
                  : `${filterDataWithKeys.age} Days`
              }`}
              onDelete={() =>
                removeSelectedFilter("age", filterDataWithKeys.age)
              }
              variant="outlined"
              sx={{
                svg: {
                  height: 16,
                  width: 16,
                },
              }}
            />
          )}

          {/* Author Filters */}
          {filterDataWithKeys.author.map((author) => (
            <Chip
              key={author}
              icon={<Person fontSize="small" />}
              label={author}
              onDelete={() => removeSelectedFilter("author", author)}
              variant="outlined"
              sx={{
                svg: {
                  height: 16,
                  width: 16,
                },
              }}
            />
          ))}

          {/* Tag Filters */}
          {filterDataWithKeys.tags.map((tag) => (
            <Chip
              key={tag}
              icon={<Tag fontSize="small" />}
              label={tag}
              onDelete={() => removeSelectedFilter("tags", tag)}
              variant="outlined"
              sx={{
                svg: {
                  height: 16,
                  width: 16,
                },
              }}
            />
          ))}
        </Box>
      </Paper>
    );
  }, [filterDataWithKeys, removeSelectedFilter]);

  const sortByContet = [
    "Sort by :",
    "Newest",
    "Oldest",
    "MostLiked",
    "Title(A-Z)",
    "Author(A-Z)",
  ];

  const selectedFilters = [
    {
      title: "Age",
      label: ["7 Days", "1 Months", "3 Months", "6 Months"],
      id: "age",
      search: false,
      selected: filterDataWithKeys?.age ? 1 : 0,
    },
    {
      title: "Author",
      label: filterOption?.authors,
      id: "author",
      search: true,
      selected: filterDataWithKeys?.author?.length || 0,
    },
    {
      title: "Tags",
      label: filterOption?.tags,
      id: "tags",
      search: true,
      selected: filterDataWithKeys?.tags?.length || 0,
    },
  ];

  const onClearFilters = async () => {
    try {
      onFetchCollections();
      setFilterDataWithKeys({
        ...filterDataWithKeys,
        age: "",
        author: [],
        tags: [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center" style={{ display: "flex" }}>
        <Input
          id="search"
          name="search"
          placeholder="Search by collection name"
          className="m-0 w-100 mt-lg-0 mt-3 d-block"
          sx={{
            px: 1,
            margin: "8px 0",
            borderRadius: "4px",
            border: "1.5px solid #bebebe",
            fontSize: 14,
            fontFamily: "karla",
            width: "30%",
          }}
          disableUnderline
          onChange={(e) => {
            const value = e.target.value;
            setSearch(value);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            gap: "2%",
          }}
        >
          {sortByContet.map((sortText, index) => {
            return (
              <p
                className="text-nowrap"
                style={{
                  cursor: "pointer",
                  fontWeight: sort === index ? "bold" : "normal",
                }}
                onClick={() => setSort(index)}
              >
                {sortText}
              </p>
            );
          })}
          <div className="d-flex justify-content-end pt-lg-0 align-items-center">
            <div>
              <FilterDrawer
                selectedFilters={selectedFilters}
                onClearFilters={onClearFilters}
                setFilterApply={setFilterApply}
                onFetchCollections={onFetchCollections}
              />
            </div>
          </div>
        </div>
      </div>
      {isFilterApply && renderSelectedFilter}
    </>
  );
}

export default FilterCollection;
