import { Layout, Menu } from "antd";
import React from "react";
import "./SideBar.scss";
import { useBooking } from "../../../../context/BookingContext";
import {
  MeetingTimeIcon,
  ReactionsIcon,
  SettingsIcon,
  SpotlightIcon,
  StarIcon,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { Sider } = Layout;
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import { useLearningFromWebWithAi } from "../../../../context/LearningFromWebWithAiContext";

const SideBar = (props) => {
  const { t } = useTranslation();
  const { selectedTab, setSelectedTab } = useBooking();
  const { setIsOpenLearningFromWebDialog } = useLearningFromWebWithAi();

  const style = {
    background: "#F5F5F5",
    boxShadow: "2px 0px rgba(0, 0, 0, 0.03)",
    marginTop: 2,
    position: props.isMobileView ? "absolute" : "sticky",
    zIndex: props.isMobileView && "2",
    height: props.isMobileView
      ? "calc(100vh - 61px)"
      : selectedTab === "Tutors"
      ? "calc(100vh - 143px)"
      : "calc(100vh - 61px)",
    top: selectedTab === "Tutors" ? "143px" : "61px",
  };

  const translation = t("booking").sidebar;

  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);
  const tabFromQuery = searchParams.get("tab");

  React.useEffect(() => {
    if (
      tabFromQuery &&
      ["Tutors", "mySchedules", "starredTutors"].includes(tabFromQuery)
    ) {
      setSelectedTab(tabFromQuery);
    }
  }, [tabFromQuery, setSelectedTab]);
  return (
    <Sider
      breakpoint="md"
      collapsedWidth={"0"}
      theme={"light"}
      style={style}
      className="booking-sidebar-wrapper"
    >
      <Menu
        theme="light"
        style={{ background: "#F5F5F5", paddingTop: "10px" }}
        mode="inline"
        selectedKeys={selectedTab}
      >
        {/* {props?.user?.isTutor ? (
          <Menu.Item
            key="Schedules"
            icon={<AccessTimeFilledIcon />}
            onClick={() => {
              setSelectedTab("Schedules");
            }}
          >
            {translation.headers.setMyAvailability}
          </Menu.Item>
        ) : null} */}
        <Menu.Item
          key="Tutors"
          icon={<UserFriendsIcon outline />}
          onClick={() => {
            setSelectedTab("Tutors");
            history.push(`/booking?tab=Tutors`);
          }}
        >
          {translation.headers.tutors}
        </Menu.Item>
        <Menu.Item
          key="mySchedules"
          icon={<MeetingTimeIcon outline />}
          onClick={() => {
            setSelectedTab("mySchedules");
            history.push(`/booking?tab=mySchedules`);
          }}
        >
          {translation.headers.mySchedules}
        </Menu.Item>
        <Menu.Item
          key="starredTutors"
          icon={<StarIcon outline />}
          onClick={() => {
            setSelectedTab("starredTutors");
            history.push(`/booking?tab=starredTutors`);
          }}
        >
          {translation.headers.Starred}
        </Menu.Item>
        {(props?.user?.isTutor || props.user.role === "ExternalTutor") && (
          <Menu.Item
            key="setting"
            icon={<SettingsIcon outline />}
            onClick={() => {
              setSelectedTab("setting");
              history.push(`/booking?tab=setting`);
            }}
          >
            {translation.headers.setting}
          </Menu.Item>
        )}
        {!props?.user?.isTutor && (
          <Menu.Item
            key="tutorRequest"
            icon={<ReactionsIcon outline />}
            onClick={() => {
              setSelectedTab("tutorRequest");
              history.push(`/booking?tab=tutorRequest`);
            }}
          >
            {translation.headers.tutorRequest}
          </Menu.Item>
        )}

        <Menu.Item
          key="help"
          icon={<SpotlightIcon outline />}
          onClick={() => {
            setIsOpenLearningFromWebDialog(true);
          }}
        >
          AskBuddy
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideBar;
