import React from "react";
import { Link } from "react-router-dom";

import {
  AttendeeIcon,
  CalendarIcon,
  CheckmarkCircleIcon,
  ContactGroupIcon,
  ContentIcon,
  MenuIcon,
  PollIcon,
  SettingsIcon,
  WhiteboardIcon,
} from "@fluentui/react-icons-northstar";
import {
  Button,
  Divider,
  Flex,
  Image,
  Layout,
  Segment,
} from "@fluentui/react-northstar";
import {
  default as Logo,
  default as LogoLight,
} from "../../Assets/images/logo.png";

import * as Icon from "react-feather";

import "./Header.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";


const Navbar = (props_) => {
  const {
    props,
    setHam,
    ham,
    isMobileView,
    openModal,
    profileButton,
    t,
    openFullscreen,
    closeFullscreen,
    isFullscreen,
  } = props_;

  const getColor = (selectedTab) => {
    return props.selectedTab === selectedTab ? "#68689E" : "#544F53";
  };

  const history = useHistory();
  
  const handleNavigation = (path) => {
    return history.push(path);
  };

  const isActive = (selectedTab) => {
    return props.selectedTab === selectedTab ? "activeBtn activeTab" : "button";
  };

  const tabsWithFilter = [
    "whiteboard",
    "assignments",
    "tutors",
    "attendance",
    "feedback",
    "events",
  ];

  const toggleClass = (element, classToRemove, classToAdd) => {
    if (element) {
      element.classList.add(classToAdd);
      element.classList.remove(classToRemove);
    }
  };

  return (
    <Flex
      gap="gap.small"
      style={{ height: "60px", backgroundColor: "#F3F2F1" }}
    >
      <Link
        to={props.selectedExtension?.attendance && "/attendance"}
        onClick={() => {
          props.setSelectedTab("attendance");
        }}
        style={{
          width: window.innerWidth < 930 ? "70px" : "166px",
        }}
      >
        <Image
          className="link-image"
          src={
            props.user?.dbData?.db_selected === "Trial" &&
            props.user?.role === "Admin"
              ? props.user?.mode === "dark"
                ? LogoLight
                : Logo
              : props.user?.schoolInfo?.SchoolLogo
              ? props.user?.schoolInfo?.SchoolLogo + props.user?.SASToken
              : props.user?.mode === "dark"
              ? LogoLight
              : Logo
          }
          title={props.user?.schoolInfo?.School || "Tangible IT"}
        />
      </Link>
      <>
        <Flex
          gap="gap.medium"
          style={{ paddingBlock: "20px" }}
          className="ctr-right"
        >
          {props.user?.role === "Admin" && (
            <Flex.Item>
              <Button
                icon={<ContentIcon outline size="large" />}
                content={t("startUp").initial[1]}
                className="startUp-btn"
              />
            </Flex.Item>
          )}
          {isMobileView && props.selectedReportPath && (
            <Icon.Filter
              size="20"
              className="ctrl"
              onClick={() => {
                openModal();
              }}
            />
          )}
          <MenuIcon
            outline
            size="large"
            className="Ham ctrl"
            onClick={() => setHam(!ham)}
          />
        </Flex>

        <div className={ham ? "ham_container active" : "ham_container"}>
          <div className="cover">
            <Flex column>
              {props.user &&
                ["Admin", "SuperAdmin", "Teacher"].includes(
                  props.user?.role
                ) && (
                  <Segment
                    content={
                      <Layout
                        gap="1rem"
                        vertical={false}
                        start={<ContactGroupIcon outline size="large" />}
                        main="Attendance"
                        end=""
                        style={{
                          color: getColor("attendance"),
                        }}
                        onClick={() => {
                          handleNavigation("/attendance");
                          setHam(!ham);
                        }}
                      />
                    }
                  />
                )}
              <Segment
                content={
                  <Layout
                    gap="1rem"
                    vertical={false}
                    start={<ContactGroupIcon outline size="large" />}
                    main="Assignments"
                    end=""
                    style={{
                      color: getColor("assignments"),
                    }}
                    onClick={() => {
                      handleNavigation("/assignments");
                      setHam(!ham);
                    }}
                  />
                }
              />

              {props.user &&
                ["Admin", "SuperAdmin", "Teacher", "Student"].includes(
                  props.user?.role
                ) && (
                  <Segment
                    content={
                      <Layout
                        gap="1rem"
                        vertical={false}
                        start={<PollIcon outline size="large" />}
                        main="Dashboard"
                        end=""
                        style={{
                          color: getColor("dashboard"),
                        }}
                        onClick={() => {
                          handleNavigation("/dashboard");
                          setHam(!ham);
                        }}
                      />
                    }
                  />
                )}
              {props.user &&
                ["Admin", "SuperAdmin", "Teacher", "Student"].includes(
                  props.user?.role
                ) && (
                  <Segment
                    content={
                      <Layout
                        gap="1rem"
                        vertical={false}
                        start={<WhiteboardIcon outline size="large" />}
                        main="Whiteboard"
                        end=""
                        style={{
                          color: getColor("whiteboard"),
                        }}
                        onClick={() => {
                          handleNavigation("/whiteboard");
                          setHam(!ham);
                        }}
                      />
                    }
                  />
                )}
              <Segment
                content={
                  <Layout
                    gap="1rem"
                    vertical={false}
                    start={<CalendarIcon outline size="large" />}
                    main="Events"
                    end=""
                    style={{
                      color: getColor("events"),
                    }}
                    onClick={() => {
                      handleNavigation("/events/my-events");
                      setHam(!ham);
                    }}
                  />
                }
              />
              {!["admin", "superadmin", "parent"].includes(
                props?.user?.role.toLowerCase()
              ) ? (
                <Segment
                  content={
                    <Layout
                      gap="1rem"
                      vertical={false}
                      start={<CalendarIcon outline size="large" />}
                      main="Booking"
                      end=""
                      style={{
                        color: getColor("booking"),
                      }}
                      onClick={() => {
                        handleNavigation("/booking");
                      }}
                    />
                  }
                />
              ) : null}
              {props.user &&
                ["Admin", "SuperAdmin", "Teacher"].includes(
                  props.user?.role
                ) && (
                  <Segment
                    content={
                      <Layout
                        gap="1rem"
                        vertical={false}
                        start={<CheckmarkCircleIcon outline size="large" />}
                        main={"Attendance History"}
                        end=""
                        style={{
                          color: getColor("history"),
                        }}
                        onClick={() => {
                          handleNavigation("/history");
                          setHam(!ham);
                        }}
                      />
                    }
                  />
                )}
              <Segment
                content={
                  <Layout
                    gap="1rem"
                    vertical={false}
                    start={<ContactGroupIcon outline size="large" />}
                    main="Feedback"
                    end=""
                    style={{
                      color: getColor("feedback"),
                    }}
                    onClick={() => {
                      handleNavigation("/feedback/submit-feedback");
                      setHam(!ham);
                    }}
                  />
                }
              />
              {props.user &&
                ["Admin", "SuperAdmin"].includes(props.user?.role) && (
                  <Segment
                    content={
                      <Layout
                        gap="1rem"
                        vertical={false}
                        start={<SettingsIcon outline size="large" />}
                        main="Settings"
                        end=""
                        style={{
                          color: getColor("settings"),
                        }}
                        onClick={() => {
                          handleNavigation("/settings");
                          setHam(!ham);
                        }}
                      />
                    }
                  />
                )}

              {props.selectedExtension?.aiTutor &&
                !["parent", "externaltutor"].includes(
                  props.user?.role.toLowerCase()
                ) && (
                  <Segment
                    content={
                      <Layout
                        gap="1rem"
                        vertical={false}
                        start={<ContactGroupIcon outline size="large" />}
                        main="AiTutor"
                        end=""
                        style={{
                          color: getColor("ai-tutor"),
                        }}
                        onClick={() => {
                          handleNavigation("/ai-tutor");
                          setHam(!ham);
                        }}
                      />
                    }
                  />
                )}
              <Divider className="ham-dv" />
              <Segment
                content={
                  <Layout
                    gap="0rem"
                    vertical={false}
                    main={profileButton}
                    style={{
                      color: "#544F53",
                    }}
                  />
                }
              />
            </Flex>
          </div>
        </div>

        <nav style={{ paddingTop: "17px", display: "flex" }}>
          {props?.user?.role.toLowerCase() === "parent" ? (
            <Link
              to={"/parent/student"}
              onClick={() => {
                props.setSelectedTab("student");
              }}
              className={isActive("student")}
            >
              Student
            </Link>
          ) : null}
          {["Admin", "SuperAdmin", "Teacher"].includes(props.user?.role) &&
          props.selectedExtension?.attendance ? (
            <Link
              to={"/attendance"}
              onClick={() => {
                props.setSelectedTab("attendance");
              }}
              className={isActive("attendance")}
            >
              Attendance
            </Link>
          ) : null}
          {props.selectedExtension?.assignment &&
          !["parent", "externaltutor"].includes(
            props.user?.role.toLowerCase()
          ) ? (
            <Link
              to="/assignments"
              onClick={() => {
                props.setSelectedTab("assignments");
              }}
              className={isActive("assignments")}
            >
              Assignments
            </Link>
          ) : null}

          {props.user &&
          ["Admin", "SuperAdmin", "Teacher", "Student"].includes(
            props.user?.role
          ) &&
          props.selectedExtension?.dashboard ? (
            <Link
              onClick={() => {
                props.setSelectedTab("dashboard");
              }}
              to="/dashboard"
              className={isActive("dashboard")}
            >
              Dashboard
            </Link>
          ) : null}
          {props.user &&
          ["Admin", "SuperAdmin", "Teacher", "Student"].includes(
            props.user?.role
          ) &&
          props.selectedExtension?.whiteBoard ? (
            <Link
              to={"/whiteboard"}
              onClick={() => {
                props.setSelectedTab("whiteboard");
              }}
              className={isActive("whiteboard")}
            >
              Whiteboard
            </Link>
          ) : null}
          {props.user &&
          ["Admin", "SuperAdmin", "Teacher", "Student", "Parent"].includes(
            props.user?.role
          ) &&
          props.selectedExtension?.events ? (
            <Link
              to={
                props.user.role === "Parent"
                  ? "/events/discover"
                  : "/events/my-events"
              }
              onClick={() => {
                props.setSelectedTab("events");
              }}
              className={isActive("events")}
            >
              Events
            </Link>
          ) : null}
          {(!["admin", "superadmin", "parent"].includes(
            props?.user?.role.toLowerCase()
          ) ||
            props?.user?.isTutor) &&
          props.selectedExtension?.booking ? (
            <Link
              to={"/booking"}
              onClick={() => {
                props.setSelectedTab("booking");
              }}
              className={isActive("booking")}
            >
              Booking
            </Link>
          ) : null}
          {!["externaltutor"].includes(props?.user?.role.toLowerCase()) &&
          props.selectedExtension?.feedback ? (
            <Link
              to={"/feedback/submit-feedback"}
              onClick={() => {
                props.setSelectedTab("feedback");
              }}
              className={isActive("feedback")}
            >
              Feedback
            </Link>
          ) : null}

          {props.user && ["Admin", "SuperAdmin"].includes(props.user?.role) && (
            <Link
              to={"/settings"}
              onClick={() => {
                props.setSelectedTab("settings");
              }}
              className={isActive("settings")}
            >
              Settings
            </Link>
          )}

          {props.selectedExtension?.aiTutor &&
            !["parent", "externaltutor"].includes(
              props.user?.role.toLowerCase()
            ) && (
              <Link
                to={"/ai-tutor"}
                onClick={() => {
                  props.setSelectedTab("ai-tutor");
                }}
                className={isActive("ai-tutor")}
              >
                AiTutor
              </Link>
            )}

          {!props.openWorkSpace &&
            tabsWithFilter.includes(props.selectedTab) && (
              <Icon.Filter
                size="20"
                className="ctrl mt-2"
                onClick={() => {
                  const hiddenElement = document.querySelector(".h-ctr");
                  const shownElement = document.querySelector(".s-ctr");

                  if (hiddenElement) {
                    toggleClass(hiddenElement, "h-ctr", "s-ctr");
                  } else if (shownElement) {
                    toggleClass(shownElement, "s-ctr", "h-ctr");
                  }
                }}
              />
            )}

          <Button
            text
            circular
            icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            onClick={isFullscreen ? closeFullscreen : openFullscreen}
            title={isFullscreen ? "Close Full Screen" : "Full Screen"}
            style={{
              marginTop: "2px",
              minWidth: "2rem",
              display: "block",
            }}
          />
        </nav>

        <Flex className="rightNav" gap="gap.small" hAlign="end">
          {props.user?.role === "Admin" && (
            <Button
              icon={<ContentIcon outline size="large" />}
              content={window.innerWidth > 1100 ? t("startUp").initial[1] : ""}
              className="contentIcon"
              iconOnly={window.innerWidth < 1100}
            />
          )}
          {["attendance", "history"].includes(props.selectedTab) && (
            <Link
              to="/history"
              onClick={() => {
                props.setSelectedTab("history");
              }}
              className={isActive("history")}
              style={{ marginLeft: 0 }}
            >
              <AttendeeIcon style={{ marginRight: "8px" }} />
              {window.innerWidth > 1100 ? "Attendance History" : ""}
            </Link>
          )}

          {profileButton}
        </Flex>
      </>
    </Flex>
  );
};

export default Navbar;
