import axios from "axios";

export async function fetchBlob(blobUrl) {
  try {
    const response = await axios.get(blobUrl, { responseType: "blob" });
    return response.data;
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}

export function replaceOrAddSasToken(url, newSasToken) {
  const urlObj = new URL(url);
  
  // Check if the URL already has a SAS token (assuming it has 'sig' or 'sv' as identifiers)
  if (urlObj.searchParams.has("sig") || urlObj.searchParams.has("sv")) {
      // Remove existing SAS parameters
      const keysToRemove = ["sv", "ss", "srt", "sp", "se", "st", "spr", "sig"];
      keysToRemove.forEach(param => urlObj.searchParams.delete(param));
  }
  
  // Append the new SAS token
  const newParams = new URLSearchParams(newSasToken);
  newParams.forEach((value, key) => {
      urlObj.searchParams.set(key, value);
  });
  
  return urlObj.toString();
}

export function removeSasToken(url) {
  try {
      const urlObj = new URL(url);
      urlObj.search = ""; // Remove all query parameters (SAS token)
      return urlObj.toString();
  } catch (error) {
      console.error("Invalid URL", error);
      return "";
  }
}