import React, { useState } from "react";
import {
  Dialog,
  TextArea,
  Button,
  Text,
  Label,
  Tooltip,
  Flex,
  CloseIcon,
  Loader,
} from "@fluentui/react-northstar";
import "./SubmitSummaryModel.scss";
import { useBooking } from "../../../../context/BookingContext";
import { useTranslation } from "react-i18next";

const SubmitSummaryModel = ({ onClose, onSubmit }) => {
  const {
    summary,
    setSummary,
    summaryLoading,
    submitSummaryModelBookingId,
    bookings,
  } = useBooking();

  const { t } = useTranslation();
  const translation = t("booking").summaryDialog;

  const MAX_CHARACTERS = 1000;

  const handleSummaryChange = (e, { value }) => {
    if (value.length <= MAX_CHARACTERS) {
      setSummary(value);
    }
  };

  const handleSubmit = () => {
    if (summary.trim()) {
      onSubmit(summary, submitSummaryModelBookingId);
    }
  };

  const currentBooking = bookings.find(
    (booking) => booking.id === submitSummaryModelBookingId
  );
  return (
    <Dialog
      open={submitSummaryModelBookingId}
      className="submit-summary-model"
      onCancel={onClose}
      header={{
        content: (
          <Text weight="bold" size="large">
            {translation.title}
          </Text>
        ),
      }}
      content={
        currentBooking.isSummarySubmitted ? (
          <Flex>
            <TextArea fluid value={currentBooking.summary} readOnly rows={12} />
          </Flex>
        ) : (
          <Flex column gap="gap.smaller">
            <Label
              aria-label="Meeting Summary Input"
              content={translation.subTitle}
              style={{ width: "fit-content" }}
            />
            <Tooltip
              content={`${summary.length}/${MAX_CHARACTERS}`}
              trigger={
                <TextArea
                  fluid
                  placeholder={translation.summaryInput}
                  value={summary}
                  onChange={handleSummaryChange}
                  maxLength={MAX_CHARACTERS}
                  disabled={summaryLoading}
                  aria-label="Meeting summary text area"
                  rows={12}
                />
              }
            />
            <Text
              size="small"
              color={summary.length >= MAX_CHARACTERS ? "red" : "gray"}
              align="end"
            >
              {summary.length}/{MAX_CHARACTERS}
            </Text>
            <Button
              primary
              style={{
                width: "fit-content",
                borderRadius: "5px",
                alignSelf: "flex-end",
              }}
              disabled={
                !summary.trim() ||
                summary.length > MAX_CHARACTERS ||
                summaryLoading
              }
              content={
                summaryLoading ? (
                  <Flex vAlign="center" hAlign="center" gap="gap.small">
                    <Loader size="small" />
                    <Text content={`${translation.submitting}...`} />
                  </Flex>
                ) : (
                  translation.submitButton
                )
              }
              onClick={handleSubmit}
            />
          </Flex>
        )
      }
      headerAction={{
        icon: <CloseIcon />,
        onClick: onClose,
        disabled: summaryLoading,
      }}
    />
  );
};

export default SubmitSummaryModel;
