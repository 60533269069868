import { Button, Tooltip } from "@fluentui/react-northstar";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import Chart from "../../Container/Charts";
import { reportTypes } from "../constant";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();

const AssignmentReport = (props) => {
  const { user, t } = props;
  const { slug } = user;
  const [chartData, setChartData] = useState({});
  const [filterBy, setFilterBy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date(
      d.getFullYear(),
      d.getMonth() - 3,
      d.getDate(),
      0,
      0,
      0,
      0
    ),
    endDate: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
  });
  const [allClassNames, setAllClassNames] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [sortOrder, setSortOrder] = useState("desc"); // Default descending

  const toggleSort = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const fetchReportData = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        url: `${url}/api/chart/get-assignment-by-subject${slug}`,
        method: "post",
        data: {
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          ClassName: selectedClass.length ? selectedClass : null,
        },
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      if (response.data?.result) {
        let responseData = response.data.result;

        let classNames = [
          ...new Set(responseData.map((item) => item.ClassName)).values(),
        ];

        let reportData = responseData.reduce((acc, lesson) => {
          acc[lesson.ClassName] = acc[lesson.ClassName] ?? {
            ClassName: lesson.ClassName,
          };

          let publishedAssignments = lesson.Assingments.filter(
            (item) => item.creationStatus === "Published"
          ).length;
          let scheduledAssignments = lesson.Assingments.filter(
            (item) => item.creationStatus === "Scheduled"
          ).length;
          let draftAssignments = lesson.Assingments.filter(
            (item) => item.creationStatus === "Draft"
          ).length;

          acc[lesson.ClassName].published =
            (Number(acc[lesson.ClassName].published) || 0) +
            publishedAssignments;
          acc[lesson.ClassName].scheduled =
            (Number(acc[lesson.ClassName].scheduled) || 0) +
            scheduledAssignments;
          acc[lesson.ClassName].draft =
            (Number(acc[lesson.ClassName].draft) || 0) + draftAssignments;
          acc[lesson.ClassName].total =
            (Number(acc[lesson.ClassName].total) || 0) +
            draftAssignments +
            publishedAssignments +
            scheduledAssignments;
          return acc;
        }, {});
        let data = Object.values(reportData);

        let totalSubjects = data.length;
        let totalAssignments = data.reduce((acc, item) => {
          return acc + item.total;
        }, 0);
        let publishedAssignments = data.reduce((acc, item) => {
          return acc + item.published;
        }, 0);
        let draftAssignments = data.reduce((acc, item) => {
          return acc + item.draft;
        }, 0);
        let scheduledAssignments = data.reduce((acc, item) => {
          return acc + item.scheduled;
        }, 0);
        let boxData = {
          totalSubjects,
          totalAssignments,
          publishedAssignments,
          draftAssignments,
          scheduledAssignments,
        };
        let pieData = {
          pie1: data
            // Step 1: Sort the array by `total` in descending order
            .sort((a, b) => b.total - a.total)
            // Step 2: Take the first 10 records from the sorted array
            .slice(0, 10)
            // Step 3: Reduce these records into an accumulator object
            .reduce((acc, item) => {
              acc[item.ClassName] = item.total;
              return acc;
            }, {}),
          pie2: {
            published: publishedAssignments,
            draft: draftAssignments,
            scheduled: scheduledAssignments,
          },
        };
        let barData = data;
        let tableData = data;
        setChartData({ boxData, pieData, barData, tableData });
        if (!allClassNames.length) {
          setAllClassNames(classNames);
        }
      } else {
        setChartData({});
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [dateFilter, selectedClass]);

  const sortObject = (data) => {
    // Convert object to array of [key, value] pairs
    const sortedEntries = Object.entries(data).sort((a, b) =>
      sortOrder === "desc" ? b[1] - a[1] : a[1] - b[1]
    );

    // Convert back to object
    const sortedObject = Object.fromEntries(sortedEntries);

    return sortedObject;
  };

  const filterByHandler = (option) => {
    try {
      setFilterBy(option);

      setChartData((prevData) => {
        if (!prevData) return prevData; // Handle cases where prevData is undefined/null

        return {
          ...prevData,
          tableData: prevData?.tableData
            ? [...prevData.tableData].sort((a, b) =>
                sortOrder === "desc"
                  ? b[option] - a[option]
                  : a[option] - b[option]
              )
            : [],
          barData: prevData?.barData
            ? [...prevData.barData].sort((a, b) =>
                sortOrder === "desc"
                  ? b[option] - a[option]
                  : a[option] - b[option]
              )
            : [],
          pieData: prevData?.pieData
            ? {
                ...prevData.pieData,
                pie1: prevData?.pieData?.pie1
                  ? sortObject(prevData.pieData.pie1)
                  : {},
                pie2: prevData?.pieData?.pie2
                  ? sortObject(prevData.pieData.pie2)
                  : {},
              }
            : {},
        };
      });

      setTimeout(() => toggleSort(), 0); // Ensure state update before toggling
    } catch (error) {
      console.error("filterByHandler: error", error);
    }
  };

  return (
    <Chart
      mainPath={props.mainPath}
      showControls={true}
      isLoading={isLoading}
      report={reportTypes.assignmentSubject}
      user={props.user}
      logout={() => props.logout()}
      switchDb={props.switchDb}
      getDb={() => props.getDb()}
      path={props.match.path}
      header={{
        report: t("chart").screens.header.reports[0],
        lable: t("chart").screens.header.lable,
      }}
      startDate={dateFilter.startDate}
      endDate={dateFilter.endDate}
      startChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, startDate: selectedDate });
      }}
      endChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, endDate: selectedDate });
      }}
      showClassType={false}
      allClassNames={allClassNames}
      stateChangeHandler={(selectedClass) => {
        setSelectedClass(selectedClass);
      }}
      // customFields={this.state.customFields}
      // showLbl={[this.state.selectedClass?.length ? false : true]}
      boxData={
        chartData.boxData
          ? {
              [t("chart").screens.assignmets.subject.boxs[0]]:
                chartData.boxData.totalSubjects,
              [t("chart").screens.assignmets.subject.boxs[1]]:
                chartData.boxData.totalAssignments,
              [t("chart").screens.assignmets.subject.boxs[2]]:
                chartData.boxData.publishedAssignments,
              [t("chart").screens.assignmets.subject.boxs[3]]:
                chartData.boxData.scheduledAssignments,
              [t("chart").screens.assignmets.subject.boxs[4]]:
                chartData.boxData.draftAssignments,
            }
          : null
      }
      pieData={{
        pie1: {
          lable: t("chart").screens.assignmets.subject.pie.pie1.lbl,
          data: chartData.pieData?.pie1,
        },
        pie2: {
          lable: t("chart").screens.assignmets.subject.pie.pie2.lbl,
          data: chartData.pieData?.pie2
            ? {
                [t("chart").screens.assignmets.subject.pie.pie2.dataLbl[0]]:
                  chartData.pieData.pie2.published,
                [t("chart").screens.assignmets.subject.pie.pie2.dataLbl[1]]:
                  chartData.pieData.pie2.draft,
                [t("chart").screens.assignmets.subject.pie.pie2.dataLbl[2]]:
                  chartData.pieData.pie2.scheduled,
              }
            : null,
        },
      }}
      columnData={{
        stacked: false,
        radius: 10,
        title: t("chart").screens.assignmets.subject.column.title,
        lableY: t("chart").screens.assignmets.subject.column.lableY,
        lableX: t("chart").screens.assignmets.subject.column.lableX,
        series: chartData.barData
          ? [
              {
                name: [t("chart").screens.assignmets.subject.column.series[0]],
                data: chartData.barData.slice(0, 7).map((lsn) => {
                  return parseInt(lsn.published);
                }),
              },
              {
                name: [t("chart").screens.assignmets.subject.column.series[1]],
                data: chartData.barData.slice(0, 7).map((lsn) => {
                  return parseInt(lsn.draft);
                }),
              },
              {
                name: [t("chart").screens.assignmets.subject.column.series[2]],
                data: chartData.barData.slice(0, 7).map((lsn) => {
                  return parseInt(lsn.scheduled);
                }),
              },
            ]
          : [],
        categories: chartData.barData
          ? chartData.barData.slice(0, 7).map((lsn) => {
              return document.documentElement.clientWidth < 500
                ? lsn.ClassName.split("-")[0] + "..."
                : document.documentElement.clientWidth < 700
                ? [
                    lsn.ClassName.split("-")[0],
                    `${lsn.ClassName.split("-")[1]}(${
                      lsn.ClassName.split("-")[2]
                    })`,
                  ]
                : [
                    lsn.ClassName.split("-")[0],
                    lsn.ClassName.split("-")[1],
                    lsn.ClassName.split("-")[2],
                  ];
            })
          : [],
      }}
      tableData={{
        header: {
          key: "Headers",
          items: t("chart").screens.assignmets.subject.table.header.map(
            (header, key) => {
              return {
                content:
                  key && key === 1 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "published" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("published")}
                    />
                  ) : key === 2 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "draft" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("draft")}
                    />
                  ) : key === 3 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "scheduled" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("scheduled")}
                    />
                  ) : key === 4 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "total" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("total")}
                    />
                  ) : (
                    header
                  ),
                key: "Header-" + header,
              };
            }
          ),
        },
        rows: chartData.tableData
          ? chartData.tableData.map((lsn, key) => {
              return {
                key: lsn.ClassName + "key-" + key,
                items: [
                  {
                    content: lsn.ClassName,
                    key: lsn.ClassName + "ClassName" + key,
                  },
                  {
                    content: lsn.published,
                    key: lsn.ClassName + "Published" + key,
                  },
                  {
                    content: lsn.draft,
                    key: lsn.ClassName + "Draft" + key,
                  },
                  {
                    content: lsn.scheduled,
                    key: lsn.ClassName + "Scheduled" + key,
                  },
                  {
                    content: lsn.total,
                    key: lsn.ClassName + "Total" + key,
                  },
                ],
              };
            })
          : [],
      }}
      selectedExtension={props.selectedExtension}
      setSelectedExtension={props.setSelectedExtension}
    />
  );
};

export default withTranslation()(AssignmentReport);
