import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filterDataWithKeys, setFilterDataWithKeys] = useState({
    age: "",
    author: [],
    tags: [],
  });

  return (
    <FilterContext.Provider
      value={{
        filterDataWithKeys,
        setFilterDataWithKeys,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

// Hook to use Modal Context
export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilter must be used within a FilterProvider");
  }
  return context;
};
