import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { ShareAltIcon } from "@fluentui/react-icons-northstar";
import {
  Avatar,
  Button,
  Card,
  CloseIcon,
  Dialog,
  Flex,
  Input,
  MenuButton,
  Pill,
  // Alert,
  RedoIcon,
  Text,
  TextArea,
  UserFriendsIcon,
} from "@fluentui/react-northstar";

import * as Icon from "react-feather";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import i18n from "../../helper/i18n";

import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Header.css";
import Navbar from "./Navbar";
import { DirectoryChannelModal } from "../AudioChannel/DirectoryChannel";
import { AudioChannelOptionModal } from "../AudioChannel/AudioChannelOptions";
import { useChannelModal } from "../../context/ChannelModalContext";

const url = process.env.REACT_APP_EP_URL;

const Header = (props) => {
  var elem = document.documentElement;
  const [isFullscreen, setFullscreen] = React.useState(false);
  const [ham, setHam] = React.useState(false);
  const [help, setHelp] = React.useState(false);
  const [msgSend, setMsgSend] = React.useState(null);
  const [notification, setNotification] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [referModal, setReferModal] = React.useState(false);
  const [referralCode, setReferralCode] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [openIsModal, setIsOpenModal] = React.useState(false);
  const [pathname, setPathName] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selLan, setSelLan] = React.useState(i18n.language);
  const [isMobileView, setIsMobileView] = React.useState(
    window.innerWidth <= 767
  );
  const [openAudoChannelOptions, setOpenAudoChannelOptions] =
    React.useState(false);
  const [openDirectoryChannel, setOpenDirectoryChannel] = React.useState(false);

  const [isTabletView, setIsTabletView] = React.useState(
    window.innerWidth <= 991
  );
  const [emailInputText, setEmailInputText] = React.useState("");
  const [isInvalidEmailText, setIsInvalidEmailText] = React.useState(false);
  const [emails, setEmails] = React.useState([]);
  const [loading, setLoading] = React.useState({
    type: null,
    isLoading: false,
  });
  const [isEmailsExpanded, setIsEmailsExpanded] = React.useState(false);
  const { openChannelModal } = useChannelModal();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const emailLoading = "emailLoading";
  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const shareFeedbackTranslation = translation.body.allFeedback.shareFeedback;
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    props?.setOpenWorkSpace();
    let selectedTabName = "";
    const params = new URLSearchParams(window.location.search);
    const tabValue = params.get("tab"); // Get the value of the "tab" query parameter
    const pathname = location.pathname;

    if (!pathname) {
      props.setSelectedTab("attendance");
    }

    switch (true) {
      case pathname.includes("settings"):
        props.setIsWBFullScreen(false);
        selectedTabName = "settings";
        break;
      case pathname.includes("attendance"):
        props.setIsWBFullScreen(false);
        selectedTabName = "attendance";
        break;
      case pathname.includes("student"):
        props.setIsWBFullScreen(false);
        selectedTabName = "student";
        break;
      case pathname.includes("assignments"):
        props.setIsWBFullScreen(false);
        selectedTabName = "assignments";
        break;
      case pathname.includes("dashboard"):
        props.setIsWBFullScreen(false);
        selectedTabName = "dashboard";
        break;
      case pathname.includes("whiteboard"):
        selectedTabName = "whiteboard";
        break;
      case pathname.includes("events"):
        props.setIsWBFullScreen(false);
        selectedTabName = "events";
        break;
      case tabValue === "Tutors":
        props.setIsWBFullScreen(false);
        selectedTabName = "tutors";
        break;
      case pathname.includes("booking"):
        props.setIsWBFullScreen(false);
        selectedTabName = "booking";
        break;
      case pathname.includes("feedback"):
        props.setIsWBFullScreen(false);
        selectedTabName = "feedback";
        break;

      case pathname.includes("ai-tutor"):
        props.setIsWBFullScreen(false);
        selectedTabName = "ai-tutor";
        break;
      case pathname.includes("history"):
        props.setIsWBFullScreen(false);
        selectedTabName = "history";
        break;
      case pathname.includes("start-up"):
        props.setIsWBFullScreen(false);
        selectedTabName = "startup";
        break;
      case pathname.includes("account"):
        props.setIsWBFullScreen(false);
        selectedTabName = "account";
        break;
      default:
        selectedTabName = "attendance";
        props.setIsWBFullScreen(false);
    }

    props.setSelectedTab(selectedTabName);
  }, [location]);

  useEffect(() => {
    props?.setOpenWorkSpace();
    window.addEventListener("message", (e) => {
      if (e?.data?.type === "SELECTED_WORKSPACE") {
        localStorage.setItem(
          "selectedWorkspaceCard",
          e.data.selectedWorkspaceCardId
        );
      }
      if (e?.data?.type === "WORKSPACE_ELEMENTS") {
        const data = JSON.parse(
          JSON.stringify(e.data.personalWorkSpaceElements)
        );
        localStorage.setItem("personalWorkSpaceElements", data);
      }
      if (e?.data?.type === "PREVIEW_IMAGE") {
        const data = e.data.dataUrl;
        localStorage.setItem("previewImage", data);
      }
      if (e?.data?.type === "BACK_TO_WHITEBOARD") {
        props?.setOpenWorkSpace();
        document.getElementById("datePicker-1").click();
      }
    });
  }, [props.selectedTab]);

  useEffect(() => {
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard"
    );
    const personalWorkSpaceElements = JSON.parse(
      localStorage.getItem("personalWorkSpaceElements")
    );

    const previewImage = localStorage.getItem("previewImage");
    const updateWorkspaceDetails = async () => {
      try {
        const response = await axios
          .put(
            `${process.env.REACT_APP_EP_URL}/api/workspace/update-workspace/${selectedWorkspaceCardID}`,
            {
              elements: personalWorkSpaceElements,
              previewImage: previewImage,
            }
          )
          .then((response) => {
            console.log("update-workspace");
            localStorage.removeItem("selectedWorkspaceCard");
            localStorage.removeItem("personalWorkSpaceElements");
            localStorage.removeItem("previewImage");
          });
        return response;
      } catch (error) {
        console.log("updatePagesDetails:error", error);
      }
    };

    if (
      personalWorkSpaceElements !== "null" &&
      personalWorkSpaceElements &&
      selectedWorkspaceCardID &&
      previewImage !== "null" &&
      previewImage
    ) {
      updateWorkspaceDetails();
    }
  }, []);

  useEffect(() => {
    const screen = window.localStorage.getItem("screen");
    // fetch user referral code
    getToken();

    if (
      screen &&
      (location.pathname?.includes("report-by") ||
        ["/attendance-special-report", "/feedback-report"].includes(
          location.pathname
        ))
    ) {
      document
        .querySelector(".dashboard-menu-section ")
        ?.classList?.remove("c-none");
      document
        .querySelector(".dashboard-menu-section ")
        ?.setAttribute("data-content", screen);
    } else {
      document
        .querySelector(".dashboard-menu-section ")
        ?.classList?.add("c-none");
    }
    setOptionSelection();
    getCurrentIndex();

    window.addEventListener("message", (e) => {
      if (e?.data?.type === "CONFIRMATION_MODAL") {
        setPopup(e.data.isShowInput);
      }
    });
  }, []);

  useEffect(() => {
    const msg = JSON.parse(localStorage.getItem("msg"));
    localStorage.setItem("msg", JSON.stringify({ ...msg, i18nextLng: selLan }));
  }, [selLan]);

  useEffect(() => {
    setFullscreen(!!document.fullscreenElement);
    document.addEventListener("fullscreenchange", () => {
      setFullscreen(!!document.fullscreenElement);
    });
  }, []);

  const getCurrentIndex = () => {
    if (props.selectedTab === "/attendance-report-by-subject") {
      setCurrentIndex(0);
    } else if (props.selectedTab === "/attendance-report-by-student") {
      setCurrentIndex(1);
    } else if (props.selectedTab === "/attendance-report-by-teacher") {
      setCurrentIndex(2);
    } else if (props.selectedTab === "/attendance-special-report") {
      setCurrentIndex(3);
    } else {
      setCurrentIndex(-1);
    }
  };

  const onAddEmailHandler = () => {
    let emailInputList = emailInputText?.length
      ? [
          ...new Set(
            emailInputText
              .toLowerCase()
              .split(";")
              .filter((email) => email)
          ),
        ]
      : [];
    if (!emailInputList.length) {
      return;
    }
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    let isValid = true;
    emailInputList.forEach((emailText) => {
      if (!emailRegex.test(emailText)) {
        isValid = false;
      }
    });

    if (emailInputText?.includes(",")) {
      isValid = false;
    }

    if (isValid) {
      setEmails((prev) => [...new Set([...prev, ...emailInputList])]);
      setEmailInputText("");
    } else {
      setIsInvalidEmailText(true);
    }
  };

  const sendEmailInvitationHandler = async () => {
    try {
      setLoading({ type: emailLoading, isLoading: true });

      const payload = {
        emails,
        link: `https://www.acv.app/prices/${referralCode}`,
        schoolInfo: props?.user?.schoolInfo,
        language: selLan,
        referBy: props?.user?.name,
      };

      const { data } = await axios({
        method: "POST",
        url: `${url}/api/refer/shareReferLink`,
        data: payload,
        headers: {
          Authorization: `Bearer ${props.user.accessToken}`,
        },
      });
      if (data) {
        if (
          data.status?.toLowerCase() === "success" &&
          typeof data.message === "string"
        ) {
          toast.success(data.message);
          setEmails([]);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ type: null, isLoading: false });
    }
  };

  const setOptionSelection = () => {
    const activeTabs = [
      "/attendance-report-by-subject",
      "/attendance-report-by-student",
      "/attendance-report-by-teacher",
      "/attendance-special-report",
    ];
    const isActive = activeTabs.includes(props.selectedTab);
    const button = `<span dir="auto"  class="button dashboard-btn ${
      isActive && "activeBtn activeTab"
    }">Dashboard</span>`;
    const block = document.querySelector(
      ".DashNav .ui-dropdown__trigger-button"
    );
    if (block) {
      block.innerHTML = button;
    }
  };

  window.addEventListener("load", () => {
    document
      .getElementsByClassName("docs-btn")[0]
      ?.parentNode?.parentNode?.parentNode?.classList.add("docs-list");
  });

  const copyTextClipboard = () => {
    const link = `https://www.acv.app/prices/${referralCode}`;
    toast.success("Referral Link copied successfully!");
    navigator.clipboard.writeText(link).then(
      function () {},
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  /* View in fullscreen! */
  function openFullscreen() {
    try {
      if (!document.fullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
      }
      setFullscreen(true);
    } catch (err) {
      console.log(err);
    }
  }

  /* Close fullscreen */
  function closeFullscreen() {
    try {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      }
      setFullscreen(false);
    } catch (err) {
      console.log(err);
    }
  }

  const toggleClass = (class1, class2) => {
    const element =
      document.querySelector(`.${class1}`) ||
      document.querySelector(`.${class2}`);
    if (element) {
      element.classList.toggle(class1);
      element.classList.toggle(class2);
    }
  };

  const openModal = () => {
    const {
      selectedTab,
      handleClickOpen,
      openDashboardMenu,
      openFilterDialog,
      toggleControl,
    } = props;

    const isMobileOrTablet = isMobileView || isTabletView;

    if (selectedTab === "attendance" || selectedTab === "whiteboard") {
      if (isMobileOrTablet) {
        handleClickOpen();
      } else {
        toggleClass("h-ctr", "s-ctr");
      }
    } else if (selectedTab === "/dashboard") {
      if (isMobileOrTablet) {
        openDashboardMenu();
      } else {
        toggleClass("h-ctr", "s-ctr");
      }
    } else if (selectedTab === "history") {
      if (isMobileOrTablet) {
        openFilterDialog();
      } else {
        toggleClass("h-ctr", "s-ctr");
      }
    } else if (selectedTab === "events" || selectedTab === "feedback") {
      toggleClass("h-ctr", "s-ctr");
    } else if (selectedTab === "assignments") {
      toggleControl();
    }
  };

  const languages = [
    { key: "English", code: "en", label: "English" },
    { key: "Español", code: "es", label: "Español" },
    { key: "Português", code: "pt", label: "Português" },
    { key: "Français", code: "fr", label: "Français" },
    { key: "Deutsch", code: "de", label: "Deutsch" },
    { key: "हिंदी", code: "hi", label: "हिंदी" },
    { key: "日本語", code: "ja", label: "日本語" },
    { key: "Italiano", code: "it", label: "Italiano" },
  ];
  const channelOptions = [
    { key: "options", label: "Options" },
    { key: "directory", label: "Directory" },
  ];

  const handleLanguageChange = (code, label) => {
    if (popup) {
      setPathName(label);
      setIsOpenModal(true);
    } else {
      setSelLan(code);
      i18n.changeLanguage(code);
      setIsOpen(false);
      setIsOpenModal(false);
    }
  };

  const multilngbtn = {
    content: (
      <Button
        icon={<Icon.Globe />}
        content="Language"
        aria-label="Hover button"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
          border: "none",
          justifyContent: "start",
        }}
        onClick={() => setIsOpen(true)}
      />
    ),
    menu: {
      items: languages.map(({ key, code, label }) => ({
        content: (
          <Button
            content={label}
            className={selLan !== code ? "FlagBtn" : "FlagBtn selLan"}
            iconOnly
          />
        ),
        key,
        onClick: () => handleLanguageChange(code, label),
      })),
    },
    key: "Multi-Language",
  };

  const commonButtonStyle = {
    margin: "0",
    padding: "0",
    boxShadow: "none",
    backgroundColor: "transparent",
    border: "none",
    justifyContent: "start",
  };

  const getIsEditOrPopup = () =>
    props.isEditProfile === true ||
    location.pathname === "/assignments/create" ||
    location.pathname === "/assignments/assignmnet/rubric" ||
    location.pathname === "/feedback/text-feedback" ||
    location.pathname === "/event/new" ||
    props.isNew === true ||
    props.addEventCategory?.isEdit === true ||
    props.addEventsTag?.isEdit === true ||
    props.addSkillForProfile?.isEdit === true ||
    props.addInterestForProfile?.isEdit === true ||
    popup;

  const switchDB = {
    content: (
      <Button
        icon={<Icon.Repeat />}
        content="Switch Org."
        style={commonButtonStyle}
      />
    ),
    key: "switch-org-btn",
    onClick: () => {
      if (getIsEditOrPopup()) {
        setPathName("switchDB");
        setIsOpenModal(true);
      } else {
        props.switchDb();
        setIsOpenModal(false);
      }
    },
  };

  const rollBackDB = {
    content: (
      <Button
        icon={<RedoIcon outline size="large" />}
        content="Switch Data"
        style={commonButtonStyle}
      />
    ),
    key: "rollBackDB-btn",
    onClick: () => setOpen(true),
  };

  const docsbtn = {
    content: (
      <Button icon={<Icon.BookOpen />} content="Docs" className="docs-btn" />
    ),
    key: "Docs-btn",
    onClick: () => window.open("https://docs.acv.app", "_blank"),
  };

  const helpbtn = {
    content: (
      <Button
        icon={<Icon.HelpCircle />}
        content="Help"
        style={commonButtonStyle}
      />
    ),
    key: "Help-btn",
    onClick: () => {
      setMsgSend(null);
      setHelp(true);
    },
  };

  const referbtn = {
    content: (
      <Button
        icon={<Icon.Share2 />}
        content="Refer"
        style={commonButtonStyle}
      />
    ),
    key: "Refer-btn",
    onClick: () => setReferModal(true),
  };
  const channelBtn = {
    content: (
      <Button
        icon={<Icon.Tv />}
        content="Channel"
        aria-label="Hover button"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
          border: "none",
          justifyContent: "start",
        }}
        onClick={() => setIsOpen(true)}
      />
    ),
    menu: {
      items: channelOptions.map(({ key, label }) => ({
        content: <Button content={label} className={"FlagBtn"} iconOnly />,
        key,
        onClick: () => {
          if (label === "Options") {
            openChannelModal("audioChannel");
            // setOpenAudoChannelOptions(true);
          } else {
            openChannelModal("directoryChannel");
            // setOpenDirectoryChannel(true);
          }
        },
      })),
    },
    key: "Channel",
  };

  const accountbtn = {
    content: (
      <Button
        icon={<Icon.UserCheck />}
        content="Account"
        style={commonButtonStyle}
      />
    ),
    key: "Account-btn",
    onClick: () => {
      if (getIsEditOrPopup()) {
        setPathName("accountbtn");
        setIsOpenModal(true);
      } else {
        history.push("/account");
        setIsOpenModal(false);
      }
    },
  };

  const logoutbtn = {
    content: (
      <Button
        icon={<Icon.LogOut />}
        content="Logout"
        style={commonButtonStyle}
      />
    ),
    key: "Logout-btn",
    onClick: () => props.logout(),
  };

  const getToken = () => {
    const headers = {
      Authorization: "Bearer " + props.user.accessToken,
    };
    const email = props?.user?.mail;
    const API = "/api/refer/getReferToken";
    axios
      .post(
        `${url}${API}`,
        {
          email: email || "pd@devtim.tech",
        },
        { headers }
      )
      .then((res) => {
        if (res && res.data) setReferralCode(res.data.referCode);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        setMsgSend({
          status: "Error",
          msg: "Refer token couldn't be get. Try to contact Tangible IT Admins.",
        });
      });
  };

  const avatarContent = useMemo(() => {
    const UrlPhoto = props.updateProfileImage
      ? props.updateProfileImage
      : props.user.UrlPhoto
      ? props.user.UrlPhoto
      : null;
    return (
      <Avatar
        image={UrlPhoto}
        name={
          props.user?.displayName
            ? props.user?.displayName
            : props.user?.name.toUpperCase()
        }
        status={{
          color:
            props?.user?.actualRole === "SuperAdmin"
              ? "#f25767"
              : props?.user?.actualRole === "Admin"
              ? "#ffaa44"
              : props?.user?.actualRole === "Teacher"
              ? "#6264a7"
              : props?.user?.actualRole === "Student"
              ? "#096dd9"
              : "",
          title: props?.user?.actualRole,
        }}
      />
    );
  }, [props.updateProfileImage]);

  const profileButton = (
    <MenuButton
      trigger={
        <Card.Header fitted>
          <Flex gap="gap.small">
            {avatarContent}
            {window.innerWidth > 890 && (
              <Flex column>
                <Text
                  content={props.user?.displayName || props.user?.name}
                  weight="bold"
                  style={{
                    textOverflow: "ellipsis",
                    width: "90px",
                    maxWidth: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                />
                <Text content={props.user?.role} size="small" />
              </Flex>
            )}
          </Flex>
        </Card.Header>
      }
      menu={
        props.user && props.user?.actualRole === "SuperAdmin"
          ? props.user?.actualRole === "SuperAdmin" &&
            props.user?.role === "Admin"
            ? [
                multilngbtn,
                switchDB,
                rollBackDB,
                docsbtn,
                helpbtn,
                referbtn,
                // channelBtn,
                accountbtn,
                logoutbtn,
              ]
            : [
                multilngbtn,
                switchDB,
                {
                  content: (
                    <Button
                      icon={<Icon.UploadCloud />}
                      content="Startup"
                      style={{
                        margin: "0",
                        padding: "0",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        border: "none",
                        justifyContent: "start",
                      }}
                      onClick={() => {
                        if (getIsEditOrPopup()) {
                          setPathName("Startup");
                          setIsOpenModal(true);
                        } else {
                          history.push("/start-up");
                          setIsOpenModal(false);
                        }
                      }}
                    />
                  ),
                  key: "Startup-btn",
                },
                docsbtn,
                helpbtn,
                referbtn,
                // channelBtn,
                {
                  content: (
                    <Button
                      icon={<Icon.User />}
                      content="Profile"
                      style={{
                        margin: "0",
                        padding: "0",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        border: "none",
                        justifyContent: "start",
                      }}
                      onClick={() =>
                        window.open("https://www.acv.app/profile", "_blank")
                      }
                    />
                  ),
                  key: "Download-Invoice-btn",
                },
                accountbtn,
                logoutbtn,
              ]
          : props.user?.role.toLowerCase() === "parent"
          ? [multilngbtn, switchDB, docsbtn, helpbtn, accountbtn, logoutbtn]
          : props.user?.role.toLowerCase() === "externaltutor"
          ? [multilngbtn, helpbtn, accountbtn, logoutbtn]
          : [
              multilngbtn,
              switchDB,
              docsbtn,
              helpbtn,
              referbtn,
              // channelBtn,
              accountbtn,
              logoutbtn,
            ]
      }
      mouseLeaveDelay={0}
      on={document.documentElement.clientWidth < 1060 ? "click" : "hover"}
      className="Avatar"
      key="menuBtn"
      open={isOpen}
      onOpenChange={(e, { open }) => setIsOpen(open)}
    />
  );

  const pathnameMapping = {
    accountbtn: {
      header: "Go to Account page",
      action: () => history.push("/account"),
    },
    switchDB: {
      header: "Go to Switch DB page",
      action: () => {
        props.switchDb();
        history.push("/switch-db");
      },
    },
    Startup: {
      header: "Go to Start Up page",
      action: () => history.push("/start-up"),
    },
    english: {
      header: "Switch to English Language",
      action: () => {
        setSelLan("en");
        i18n.changeLanguage("en");
        setIsOpen(false);
      },
    },
    Español: {
      header: "Switch to Español Language",
      action: () => {
        setSelLan("es");
        i18n.changeLanguage("es");
        setIsOpen(false);
      },
    },
    Português: {
      header: "Switch to Português Language",
      action: () => {
        setSelLan("pt");
        i18n.changeLanguage("pt");
        setIsOpen(false);
      },
    },
    Français: {
      header: "Switch to Français Language",
      action: () => {
        setSelLan("fr");
        i18n.changeLanguage("fr");
        setIsOpen(false);
      },
    },
    Deutsch: {
      header: "Switch to Deutsch Language",
      action: () => {
        setSelLan("de");
        i18n.changeLanguage("de");
        setIsOpen(false);
      },
    },
    Japanese: {
      header: "Switch to Japanese Language",
      action: () => {
        setSelLan("ja");
        i18n.changeLanguage("ja");
        setIsOpen(false);
      },
    },
    Italian: {
      header: "Switch to Italian Language",
      action: () => {
        setSelLan("it");
        i18n.changeLanguage("it");
        setIsOpen(false);
      },
    },
    हिंदी: {
      header: "Switch to हिंदी Language",
      action: () => {
        setSelLan("hi");
        i18n.changeLanguage("hi");
        setIsOpen(false);
      },
    },
  };

  const dialogContent = pathnameMapping[pathname]
    ? "Do you really want to change this page? You will lose all the changes that are not saved."
    : "";

  const dialogHeader = pathnameMapping[pathname]?.header || "Confirm Action";

  const onConfirmAction =
    pathnameMapping[pathname]?.action || (() => setIsOpenModal(false));

  return (
    <div
      className={`Header ${props.user.role}`}
      style={{ display: props.isWBFullScreen ? "none" : "block" }}
    >
      <AudioChannelOptionModal user={props.user} />
      <DirectoryChannelModal user={props.user} />
      <Dialog
        open={openIsModal}
        onConfirm={() => {
          onConfirmAction();
          setIsOpenModal(false);
        }}
        confirmButton="Okay"
        content={dialogContent}
        header={dialogHeader}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => setIsOpenModal(false),
        }}
        styles={{ width: "fit-content !important" }}
      />

      {notification ? (
        notification.status === "Error" ? (
          <Alert severity="error">{notification.msg}</Alert>
        ) : (
          <Alert severity="success">{notification.msg}</Alert>
        )
      ) : (
        ""
      )}

      <Dialog
        onConfirm={() => {
          const email = document.querySelector("#senderEmail").value;
          const name = document.querySelector("#senderName").value;
          const message = document.querySelector("#helpMessage").value;
          const API =
            props.user?.role === "Admin" || props.user?.role === "SuperAdmin"
              ? "/api/school/help-clientAdmin-to-acvAdmin"
              : `/api/school/help-client-to-admin${props.user.slug}`;
          axios
            .post(`${url}${API}`, {
              email: email || "juan.samano@tangibleit.com",
              Name: name,
              Message: message,
              SchoolName:
                props.user?.schoolInfo?.School || "No School Name Provided",
            })
            .then((res) => {
              setMsgSend({
                status: "Success",
                msg: "Mail notification has been send successfully, you will get reply via email.",
              });
              setHelp(false);
              setOpenSnackbar(true);
            })
            .catch((err) => {
              toast.error(err?.response?.data?.message);
              console.log(err);
              setMsgSend({
                status: "Error",
                msg: "Mail notification couldn't be send. Try to contact Tangible IT Admins.",
              });
            });
        }}
        onCancel={() => setHelp(false)}
        cancelButton="Close"
        confirmButton="Send"
        content={
          <Flex gap="gap.small" column>
            <label htmlFor="senderEmail">Your Email</label>
            <Input
              type="text"
              id="senderEmail"
              placeholder="Enter your email"
              defaultValue={
                props.data?.user?.username
                  ? props.data?.user?.username
                  : props.user?.mail
              }
              showSuccessIndicator
              disabled
              fluid
            />
            <label htmlFor="senderName">Your Name</label>
            <Input
              type="text"
              id="senderName"
              placeholder="Enter your name"
              defaultValue={props.user?.name}
              showSuccessIndicator
              disabled
              fluid
            />
            <label htmlFor="helpMessage">Enter Your Message</label>
            <TextArea
              type="text"
              id="helpMessage"
              placeholder="Type Your Message Here"
              resize="vertical"
              fluid
              height="200"
            />
          </Flex>
        }
        header={
          props.user?.role === "Admin" || props.user?.role === "SuperAdmin"
            ? "Ask to ACV's Admin"
            : "Ask to your Organization's Admin"
        }
        open={help}
      />
      {msgSend ? (
        msgSend.status === "Error" ? (
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Mail notification has been rejected,Please tray again.
            </Alert>
          </Snackbar>
        ) : (
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Notification successfully sent, you will get reply via email.
            </Alert>
          </Snackbar>
        )
      ) : (
        ""
      )}

      <Dialog
        open={open}
        onConfirm={() => {
          axios
            .post(`${url}/api/startup/update-DB-data${props.user.slug}`, {
              plan: "Current",
              email: props.data?.user?.username
                ? props.data?.user?.username
                : props.user?.mail,
            })
            .then((res) => {
              props.getDb();
              toast.success("Database has been updated successfully!");
              history.push("/");
              setTimeout(() => {
                setNotification(null);
              }, 3000);
            })
            .catch((err) => {
              toast.error(err?.response?.data?.message);
              console.log(err);
            });
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
        confirmButton="Yes"
        cancelButton="No"
        content="This action will switch your database to current db, are you sure about switching to your current acv?"
        header="Switch back to own database"
      />

      <Dialog
        content={
          <>
            <Flex column style={{ marginTop: "10px" }}>
              <p>{t("referLink").inviteFriends}</p>
              <Input
                className="email-input"
                fluid
                // icon={<SearchIcon />}
                value={emailInputText}
                onChange={(e, { value }) => {
                  setIsInvalidEmailText(false);
                  setEmailInputText(value);
                }}
                clearable
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onAddEmailHandler();
                  }
                }}
                placeholder="Enter email address"
                error={isInvalidEmailText}
              />

              {isInvalidEmailText ? (
                <p style={{ color: "rgb(196, 49, 75)" }}>
                  {shareFeedbackTranslation.emailValidationError}
                </p>
              ) : null}
              <p style={{ marginTop: !isInvalidEmailText && "1rem" }}>
                {shareFeedbackTranslation.emailAddNote}
              </p>
            </Flex>
            <div className="emails-wrapper" style={{ marginTop: "10px" }}>
              <Flex wrap>
                {(emails.length > 5
                  ? isEmailsExpanded
                    ? emails
                    : emails.slice(0, 5)
                  : emails
                ).map((item) => {
                  return (
                    <Pill
                      actionable
                      onDismiss={() => {
                        setEmails((prev) =>
                          prev.filter((data) => data !== item)
                        );
                      }}
                      style={{
                        minWidth: "initial",
                        backgroundColor: "#6264A7",
                        color: "#fff",
                      }}
                    >
                      {item}
                    </Pill>
                  );
                })}
              </Flex>
              {emails.length > 5 && (
                <Flex hAlign="end">
                  <Button
                    primary
                    onClick={() => setIsEmailsExpanded((prev) => !prev)}
                  >
                    {isEmailsExpanded ? "Show Less" : "Show All"}
                  </Button>
                </Flex>
              )}
            </div>
            <div
              className="action-button-wrapper"
              style={{ marginTop: "10px" }}
            >
              <Flex
                space="between"
                vAlign="center"
                className="feedback-share-action-buttons"
              >
                <Button
                  primary
                  icon={<ShareAltIcon />}
                  content={shareFeedbackTranslation.shareLink}
                  onClick={copyTextClipboard}
                  style={{ backgroundColor: "rgb(196, 49, 75)" }}
                />
                <Button
                  primary
                  disabled={
                    !emails.length ||
                    (loading.type === emailLoading && loading.isLoading)
                  }
                  icon={<UserFriendsIcon />}
                  content={
                    loading.type === emailLoading && loading.isLoading
                      ? commonTranslation.loading
                      : shareFeedbackTranslation.invite
                  }
                  loading={loading.type === emailLoading && loading.isLoading}
                  onClick={sendEmailInvitationHandler}
                />
              </Flex>
            </div>
          </>
        }
        header={t("referLink").shareLink}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => setReferModal(false),
        }}
        onOpen={getToken}
        open={referModal}
        style={{ maxWidth: "600px" }}
      />

      <Navbar
        props={props}
        setHam={setHam}
        ham={ham}
        isMobileView={isMobileView}
        openModal={openModal}
        profileButton={profileButton}
        t={t}
        isFullscreen={isFullscreen}
        closeFullscreen={closeFullscreen}
        openFullscreen={openFullscreen}
      />
    </div>
  );
};

export default Header;
