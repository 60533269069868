import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Avatar,
  Card,
  CardBody,
  Checkbox,
  Dropdown,
  Flex,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  NormalPeoplePicker,
  TextField,
  ValidationState,
} from "@fluentui/react";

import useAddMember from "./useAddMember";
import Tabs from "./Tabs/Tabs";

import "./AddMember.scss";

const AddMember = ({
  classDropdownList,
  setSelectedClass,
  setSelectedStudentsOfSelectedClass,
  setStudentListOfSelectedClass,
  classData,
  selectedStudentsOfSelectedClass,
  studentListOfSelectedClass,
  allStudentsForGroup,
  setStudentSearchText,
  studentSearchText,
  filteredStudentListOfSelectedClass,
  studentCard,
  selectedClass,
  selectedStudentByNameEmail,

  t,
  ...rest
}) => {
  const translation = t("feedbackModule");

  const commonTranslation = translation.common;

  const feedbackTranslation = translation.body.group;

  const {
    searchByNameQuery,
    setSearchByNameQuery,
    isLoadingDataByName,
    debouncedSearchByNameHandler,
    selectedAddMemberTab,
    setSelectedAddMemberTab,
    AddMemberTabTypes,
    fetchedMembers,
    onMemberSelectHandler,
    onSelectedMemberRemoveHandler,
    fetchDataByNameHandler,
    onFilterChanged,
    onInputChange,
    validateInput,
    suggestionProps,
    getTextFromItem,
  } = useAddMember({
    selectedStudentByNameEmail,
    allStudentsForGroup,
    ...rest,
  });

  const memberCard = ({ member }) => {
    return (
      <Card
        elevated
        className="fit-content all-feedback-card"
        fluid
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          width: "223px",
          height: "50px",
          margin: "10px",
          borderRadius: "7px",
        }}
      >
        <CardBody className="student-card-body" style={{ padding: "10px" }}>
          <Flex wrap space="between" vAlign="center">
            <Flex vAlign="center">
              <Flex>
                <Avatar
                  square
                  image={member.image}
                  name={member.header}
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    borderRadius: "4px",
                  }}
                />
              </Flex>
              <Flex column vAlign="start">
                <Tooltip
                  content={member.header ?? member.content?.split("@")?.[0]}
                  trigger={
                    <Text className="student-name-wrapper">
                      {member.header ?? member.content?.split("@")?.[0]}
                    </Text>
                  }
                />
                <Text size="small">{member.role}</Text>
              </Flex>
            </Flex>
            <Flex>
              <Checkbox
                checked={true}
                onClick={(e, { checked }) => {
                  onSelectedMemberRemoveHandler(member);
                }}
              />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  };

  return (
    <Flex className="add-member-wrapper feedback-tabs-wrapper" column>
      <Flex hAlign="end">
        <div
          style={{
            backgroundColor: "#585a96",
            color: "white",
            padding: "5px 12px",
            borderRadius: "16px",
          }}
        >
          {(selectedStudentsOfSelectedClass?.length || 0) +
            (selectedStudentByNameEmail?.length || 0)}{" "}
          {feedbackTranslation.membersSelected}
        </div>
      </Flex>
      <Tabs currentTab={selectedAddMemberTab}>
        <Flex
          tabId={AddMemberTabTypes.addStudent}
          label={feedbackTranslation.addMember.byClass}
          column
          style={{ minHeight: "200px" }}
          gap="gap.medium"
        >
          <Flex space="between" vAlign="center" wrap style={{ rowGap: "10px" }}>
            <Dropdown
              items={classDropdownList}
              placeholder={feedbackTranslation.selectClass}
              className="select-class-dropdown"
              checkable
              getA11ySelectionMessage={{
                onAdd: (item) =>
                  `${item} ${feedbackTranslation.hasBeenSelected}`,
              }}
              onChange={(e, { value }) => {
                if (value) {
                  setSelectedClass(value);
                  setSelectedStudentsOfSelectedClass([]);
                  setStudentListOfSelectedClass(
                    classData.find((item) => item.class === value).students
                  );
                } else {
                  setSelectedClass(null);
                }
              }}
              search
            />
          </Flex>

          {!!studentListOfSelectedClass?.length && (
            <Checkbox
              label={feedbackTranslation.addAllStudents}
              checked={
                studentListOfSelectedClass.length
                  ? selectedStudentsOfSelectedClass?.length ===
                    studentListOfSelectedClass?.length
                  : false
              }
              onChange={(e, { checked }) => {
                const studentIdListOfSelectedClass =
                  studentListOfSelectedClass.map((item) => item.studentEmailId);
                const allStudentsIdListForGroup = allStudentsForGroup.map(
                  (item) => item.studentEmailId
                );

                if (
                  studentIdListOfSelectedClass.every((item) =>
                    allStudentsIdListForGroup.includes(item)
                  )
                ) {
                  return toast.warning(
                    feedbackTranslation.allStudentExistValidation
                  );
                } else if (
                  studentIdListOfSelectedClass.some((item) =>
                    allStudentsIdListForGroup.includes(item)
                  )
                ) {
                  toast.warning(feedbackTranslation.someStudentExistValidation);
                }
                if (checked) {
                  setSelectedStudentsOfSelectedClass(
                    studentListOfSelectedClass.filter(
                      (item) =>
                        !allStudentsIdListForGroup.includes(item.studentEmailId)
                    )
                  );
                } else {
                  setSelectedStudentsOfSelectedClass([]);
                }
              }}
            />
          )}

          {!!studentListOfSelectedClass?.length && (
            <Flex fill>
              <TextField
                value={studentSearchText}
                onChange={(e, value) => {
                  setStudentSearchText(value);
                }}
                style={{
                  background: "#F5F5F5",
                  borderRadius: "5px",
                }}
                borderless
                placeholder={feedbackTranslation.searchStudents}
                styles={{
                  root: {
                    width: "100%",
                  },
                }}
              />
            </Flex>
          )}

          <Flex
            wrap
            style={{ maxHeight: "calc(100vh - 425px)", overflow: "auto" }}
          >
            {filteredStudentListOfSelectedClass?.length ? (
              filteredStudentListOfSelectedClass.map((student, index) => (
                <Flex.Item key={index}>{studentCard({ student })}</Flex.Item>
              ))
            ) : (
              <Flex fill>
                <Flex hAlign="center" fill style={{ marginTop: "90px" }}>
                  <Text weight="semibold" size="large" align="center">
                    {selectedClass
                      ? studentListOfSelectedClass.length
                        ? feedbackTranslation.noStudentsForTextFilter
                        : feedbackTranslation.noStudentOfSelectedClass
                      : feedbackTranslation.pleaseSelectClass}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          tabId={AddMemberTabTypes.addMemberByNameEmail}
          label={feedbackTranslation.addMember.byNameEmail}
          column
        >
          <Flex column>
            {/* <Dropdown
              searchQuery={searchByNameQuery}
              search
              items={isLoadingDataByName ? [] : membersList}
              placeholder={feedbackTranslation.addMember.startTyping}
              noResultsMessage={feedbackTranslation.addMember.notMatchFound}
              a11ySelectedItemsMessage={
                feedbackTranslation.addMember.removeSearch
              }
              onSearchQueryChange={(_, data) => {
                setSearchByNameQuery(data.searchQuery);
                debouncedSearchByNameHandler(data.searchQuery);
              }}
              loading={isLoadingDataByName}
              loadingMessage={`${commonTranslation.loading}...`}
              onChange={(_, { value }) => {
                if (value) {
                  onMemberSelectHandler(value);
                }
                setSearchByNameQuery("");
                debouncedSearchByNameHandler("");
              }}
              autoSize
            /> */}

            <Text>{feedbackTranslation.addMember.startTyping}</Text>
            <NormalPeoplePicker
              selectedItems={[]}
              onEmptyInputFocus={onFilterChanged}
              onResolveSuggestions={onFilterChanged}
              // onEmptyInputFocus={returnMostRecentlyUsed}
              getTextFromItem={getTextFromItem}
              pickerSuggestionsProps={suggestionProps}
              className={"ms-PeoplePicker"}
              key={"list"}
              onValidateInput={validateInput}
              onInputChange={onInputChange}
              styles={{
                root: {
                  width: "100%",
                },
                itemsWrapper: {
                  width: "150px",
                },
                input: {
                  width: "100%",
                },
                text: {
                  maxWidth: "300px",
                  width: "100%",
                  border: "none",
                  backgroundColor: "#f3f2f1",
                  outline: "#6264a7",
                },
              }}
              // resolveDelay={300}
              onItemSelected={(selectedItem) => {
                onMemberSelectHandler(selectedItem);
              }}
            />
          </Flex>
          <Flex
            className="by-name-email-student-wrapper"
            style={{ maxHeight: "calc(100vh - 425px)", overflow: "auto" }}
            wrap
          >
            {selectedStudentByNameEmail?.length ? (
              selectedStudentByNameEmail.map((member, index) => (
                <Flex.Item key={index}>{memberCard({ member })}</Flex.Item>
              ))
            ) : (
              <Flex fill>
                <Flex hAlign="center" fill style={{ marginTop: "90px" }}>
                  <Text weight="semibold" size="large" align="center">
                    {feedbackTranslation.addMember.selectMember}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Tabs>
    </Flex>
  );
};

export default withTranslation()(AddMember);
