import { Button, CloseIcon, Segment, Tooltip } from "@fluentui/react-northstar";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import Chart from "../../Container/Charts";
import { reportTypes } from "../constant";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();

const CategoryReport = (props) => {
  const { user, t } = props;
  const { slug } = user;
  const [chartData, setChartData] = useState({});
  const [filterBy, setFilterBy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date(
      d.getFullYear(),
      d.getMonth() - 3,
      d.getDate(),
      0,
      0,
      0,
      0
    ),
    endDate: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
  });
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [sortOrder, setSortOrder] = useState("desc"); // Default descending

  const toggleSort = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const multiSelectRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sortObject = (data) => {
    // Convert object to array of [key, value] pairs
    const sortedEntries = Object.entries(data).sort((a, b) =>
      sortOrder === "desc" ? b[1] - a[1] : a[1] - b[1]
    );

    // Convert back to object
    const sortedObject = Object.fromEntries(sortedEntries);

    return sortedObject;
  };

  const fetchReportData = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        url: `${url}/api/chart/get-event-chart-by-category${slug}`,
        method: "post",
        data: {
          startDate: dateFilter.startDate,
          endDate: moment.utc(dateFilter.endDate).add(1, "day").toISOString(),
          category: selectedCategory.length ? selectedCategory : null,
        },
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      if (response.data?.result) {
        let { totalEvents, eventData, categories, countsOfEvents } =
          response.data.result;

        setAllCategories(categories?.length ? categories : []);

        let boxData = {
          totalEvents: totalEvents ?? 0,
          totalCategories: categories.length ?? 0,
          totalRegistration: countsOfEvents.totalRegistration ?? 0,
          googleEvents: countsOfEvents.totalGoogleMeetEventCount ?? 0,
          msEvents: countsOfEvents.totalMSTeamsEventCount ?? 0,
          otherEvent: countsOfEvents.otherEvents ?? 0,
        };
        let pieData = {
          pie1: Object.entries(eventData)
            .sort((a, b) => b[1].events - a[1].events)
            .slice(0, 10)
            .reduce((acc, [category, { events }]) => {
              acc[category] = events;
              return acc;
            }, {}),
          pie2: {
            googleEvents: countsOfEvents.totalGoogleMeetEventCount ?? 0,
            msEvents: countsOfEvents.totalMSTeamsEventCount ?? 0,
            otherEvent: countsOfEvents.otherEvents ?? 0,
          },
        };

        let formattedData = Object.entries(eventData).map(
          ([category, data]) => ({
            category,
            ...data,
            otherEvents:
              data.events - data.googleMeetEvents - data.msTeamsEvents,
          })
        );

        setChartData({
          boxData,
          pieData,
          barData: formattedData,
          tableData: formattedData,
        });
      } else {
        setChartData({});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [dateFilter, selectedCategory]);

  const filterByHandler = (option) => {
    try {
      setFilterBy(option);

      setChartData((prevData) => {
        if (!prevData) return prevData; // Handle cases where prevData is undefined/null

        return {
          ...prevData,
          tableData: prevData?.tableData
            ? [...prevData.tableData].sort((a, b) =>
                sortOrder === "desc"
                  ? b[option] - a[option]
                  : a[option] - b[option]
              )
            : [],
          barData: prevData?.barData
            ? [...prevData.barData].sort((a, b) =>
                sortOrder === "desc"
                  ? b[option] - a[option]
                  : a[option] - b[option]
              )
            : [],
          pieData: prevData?.pieData
            ? {
                ...prevData.pieData,
                pie1: prevData?.pieData?.pie1
                  ? sortObject(prevData.pieData.pie1)
                  : {},
                pie2: prevData?.pieData?.pie2
                  ? sortObject(prevData.pieData.pie2)
                  : {},
              }
            : {},
        };
      });

      setTimeout(() => toggleSort(), 0); // Ensure state update before toggling
    } catch (error) {
      console.error("filterByHandler: error", error);
    }
  };

  return (
    <Chart
      mainPath={props.mainPath}
      showControls={true}
      isLoading={isLoading}
      report={reportTypes.eventCategory}
      user={props.user}
      logout={() => props.logout()}
      switchDb={props.switchDb}
      getDb={() => props.getDb()}
      path={props.match.path}
      header={{
        report: t("chart").screens.header.reports[0],
        lable: t("chart").screens.header.lable,
      }}
      startDate={dateFilter.startDate}
      endDate={dateFilter.endDate}
      startChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, startDate: selectedDate });
      }}
      endChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, endDate: selectedDate });
      }}
      showClassType={false}
      headerFilterFields={
        <>
          <div
            className={
              isMobileView
                ? "mobile-tour-filter-by-class"
                : "leftControls tour-filter-by-class"
            }
          >
            <Segment
              content={
                t("chart").screens.events.category.controls.dropdown.category
              }
              className="seg1"
            />
            <Segment
              content={
                <div
                  className={
                    isMobileView ? "mobile-multi-select" : "multi-select"
                  }
                  style={{ marginLeft: isMobileView ? "10px" : "" }}
                >
                  <ReactMultiSelectCheckboxes
                    getDropdownButtonLabel={({
                      placeholderButtonLabel,
                      value,
                    }) => {
                      if (!value) {
                        return placeholderButtonLabel;
                      }

                      if (Array.isArray(value)) {
                        if (value.length === 0) {
                          document
                            .querySelector(".classSelector")
                            .classList.remove("filterSelected");
                          return placeholderButtonLabel;
                        }

                        if (value.length === 1) {
                          return value[0].label;
                        }

                        return "".concat(
                          value.length,
                          ` ${
                            t("chart").screens.events.category.controls.dropdown
                              .categorySelected
                          }`
                        );
                      }
                    }}
                    options={
                      allCategories?.length
                        ? allCategories
                            .map((item) => {
                              return {
                                label: item.title,
                                value: item.title,
                              };
                            })
                            .sort()
                        : []
                    }
                    onChange={(data) => {
                      let selectedValue = data.map(({ value }) => value);
                      setSelectedCategory(selectedValue);
                    }}
                    placeholderButtonLabel={
                      t("chart").screens.events.category.controls.dropdown
                        .typeName
                    }
                    ref={multiSelectRef}
                  />
                  <span
                    className="close_icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      const dropBox = document.getElementsByClassName(
                        "css-1pcexqc-container"
                      );

                      if (dropBox[0]) {
                        dropBox[0].style.display = "none";
                      }
                      multiSelectRef.current.state.value = [];
                      setSelectedCategory([]);
                    }}
                  >
                    <CloseIcon />
                  </span>
                </div>
              }
              className="seg2 classSelector"
            />
          </div>
        </>
      }
      boxData={
        chartData.boxData
          ? {
              [t("chart").screens.events.category.boxs[0]]:
                chartData.boxData.totalEvents,
              [t("chart").screens.events.category.boxs[1]]:
                chartData.boxData.totalCategories,
              [t("chart").screens.events.category.boxs[5]]:
                chartData.boxData.totalRegistration,
              [t("chart").screens.events.category.boxs[2]]:
                chartData.boxData.googleEvents,
              [t("chart").screens.events.category.boxs[3]]:
                chartData.boxData.msEvents,
              [t("chart").screens.events.category.boxs[4]]:
                chartData.boxData.otherEvent,
            }
          : null
      }
      pieData={{
        pie1: {
          lable: t("chart").screens.events.category.pie.pie1.lbl,
          data: chartData.pieData?.pie1,
        },
        pie2: {
          lable: t("chart").screens.events.category.pie.pie2.lbl,
          data: chartData.pieData?.pie2
            ? {
                [t("chart").screens.events.category.pie.pie2.dataLbl[0]]:
                  chartData.pieData.pie2.googleEvents,
                [t("chart").screens.events.category.pie.pie2.dataLbl[1]]:
                  chartData.pieData.pie2.msEvents,
                [t("chart").screens.events.category.pie.pie2.dataLbl[2]]:
                  chartData.pieData.pie2.otherEvent,
              }
            : null,
        },
      }}
      columnData={{
        stacked: false,
        radius: 10,
        title: t("chart").screens.events.category.column.title,
        lableY: t("chart").screens.events.category.column.lableY,
        lableX: t("chart").screens.events.category.column.lableX,
        series: chartData.barData
          ? [
              {
                name: [t("chart").screens.events.category.column.series[0]],
                data: chartData.barData.map((event) => {
                  return event.googleMeetEvents;
                }),
              },
              {
                name: [t("chart").screens.events.category.column.series[1]],
                data: chartData.barData.map((event) => {
                  return event.msTeamsEvents;
                }),
              },
              {
                name: [t("chart").screens.events.category.column.series[2]],
                data: chartData.barData.map((event) => {
                  return event.otherEvents;
                }),
              },
            ]
          : [],
        categories: chartData.barData
          ? chartData.barData.slice(0, 7).map((event) => {
              return document.documentElement.clientWidth < 500
                ? event.category + "..."
                : document.documentElement.clientWidth < 700
                ? [event.category]
                : [event.category];
            })
          : [],
      }}
      tableData={{
        header: {
          key: "Headers",
          items: t("chart").screens.events.category.table.header.map(
            (header, key) => {
              return {
                content:
                  key && key === 1 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color:
                          filterBy === "googleMeetEvents" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("googleMeetEvents")}
                    />
                  ) : key === 2 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color:
                          filterBy === "msTeamsEvents" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("msTeamsEvents")}
                    />
                  ) : key === 3 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "otherEvents" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("otherEvents")}
                    />
                  ) : key === 4 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color:
                          filterBy === "registrations" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("registrations")}
                    />
                  ) : key === 5 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "events" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("events")}
                    />
                  ) : (
                    header
                  ),
                key: "Header-" + header,
              };
            }
          ),
        },
        rows: chartData.tableData
          ? chartData.tableData.map((event, key) => {
              return {
                key: event.category + "key-" + key,
                items: [
                  {
                    content: event.category,
                    key: event.category + "category" + key,
                  },
                  {
                    content: event.googleMeetEvents,
                    key: event.category + "google" + key,
                  },
                  {
                    content: event.msTeamsEvents,
                    key: event.category + "msTeams" + key,
                  },
                  {
                    content: event.otherEvents,
                    key: event.category + "other" + key,
                  },
                  {
                    content: event.registrations,
                    key: event.category + "registrations" + key,
                  },
                  {
                    content: event.events,
                    key: event.category + "Total" + key,
                  },
                ],
              };
            })
          : [],
      }}
      selectedExtension={props.selectedExtension}
      setSelectedExtension={props.setSelectedExtension}
    />
  );
};

export default withTranslation()(CategoryReport);
