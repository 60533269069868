import { useState, useEffect } from "react";
import { CloseIcon, Dialog, Flex } from "@fluentui/react-northstar";
import { PublicClientApplication } from "@azure/msal-browser";
import { Switch } from "antd";
import moment from "moment";
import { withTranslation } from "react-i18next";

import { loginRequest, msalConfig } from "../../../../App/msal-auth/msalAuth";
import { NotificationAlert } from "../../../Notification/Notification";

const MSTeamsLogin = (props) => {
  const {
    event,
    user,
    handleMeeting,
    eventTranslation,
    t,
    googleLoginHandler,
  } = props;
  const [open, setOpen] = useState({
    type: null,
    isOpen: false,
    value: false,
  });

  const translation = t("events");
  const commonTranslation = translation.common;

  // useEffect(() => {
  //   try {
  //     let tokenData = localStorage.getItem("GoogleMeetToken");
  //     if (event && !!event.isGoogleMeetMeeting && !!event.id && tokenData) {
  //       let token = JSON.parse(tokenData);
  //       let expireUNIXTime = token?.tokenObj?.expires_at;
  //       if (token && expireUNIXTime) {
  //         let isTokenExpired = moment
  //           .unix(expireUNIXTime / 1000)
  //           .local()
  //           .isSameOrBefore(new Date());
  //         if (isTokenExpired) {
  //           signIn();
  //         }
  //       }
  //     }
  //   } catch (error) {}
  // }, []);

  // const onSuccess = (res) => {
  //   localStorage.setItem("GoogleMeetToken", JSON.stringify(res));

  //   const payload = { ...event };
  //   payload.isGoogleMeetMeeting = true;
  //   payload.isMSTeamMeeting = false;

  //   payload.availability = null;

  //   handleMeeting(payload);
  // };

  // const { signIn, loaded } = useGoogleLogin({
  //   onSuccess,
  //   clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //   cookiePolicy: "single_host_origin",
  //   // redirectUri,
  //   onError: (error) => {
  //     console.log("error", error);
  //   },
  //   onFailure,
  //   scope: "https://www.googleapis.com/auth/calendar.events",
  // });

  // const googleLoginHandler = () => {
  //   try {
  //     const googleLoginBtn = document.getElementById("google-login-btn");
  //     console.log("googleLoginHandler:called", googleLoginBtn);
  //     if (googleLoginBtn) {
  //       googleLoginBtn.click();
  //     }
  //   } catch (error) {
  //     console.log("errro---------", error);
  //   }
  // };

  const handleMSLogin = (value) => {
    const instance = new PublicClientApplication({ ...msalConfig, cache: {} });
    instance
      .loginPopup(loginRequest)
      .then((data) => {
        localStorage.setItem("MSTeamsToken", JSON.stringify(data));
        let payload = { ...event };
        payload.isGoogleMeetMeeting = false;
        payload.isMSTeamMeeting = true;

        if (!payload.eventType) {
          payload.eventType = "VIDEO_CONFERENCE";
        }
        if (!!payload.availability) {
          payload.availability = "PUBLIC";
        }

        handleMeeting(payload);
      })
      .catch((e) => {
        handleMeeting({
          ...event,
          isMSTeamMeeting: false,
        });
      });
  };

  const switchHandler = (type, value) => {
    if (value) {
      if (type === "isMSTeamMeeting") {
        const isMSLogin = user.authType === "microsoft";
        if (isMSLogin) {
          // let token = localStorage.getItem("MSTeamsToken");

          // if (!token) {
          //   localStorage.setItem("MSTeamsToken", user.accessToken);
          // }
          localStorage.setItem("MSTeamsToken", user.accessToken);
          let payload = { ...event };
          payload.isGoogleMeetMeeting = false;
          payload.isMSTeamMeeting = true;

          if (!payload.eventType) {
            payload.eventType = "VIDEO_CONFERENCE";
          }
          if (!!payload.availability) {
            payload.availability = "PUBLIC";
          }

          handleMeeting(payload);
        } else {
          let token = localStorage.getItem("MSTeamsToken");
          if (token) {
            // let payload = { ...event };
            // payload.isGoogleMeetMeeting = false;
            // payload.isMSTeamMeeting = true;

            // if (!payload.eventType) {
            //   payload.eventType = "VIDEO_CONFERENCE";
            // }

            // handleMeeting(payload);
            handleMSLogin();
          } else {
            setOpen({ type: "isMSTeamMeeting", isOpen: true, value });
          }
        }
      } else if (type === "isGoogleMeetMeeting") {
        const isGoogleLogin = user.authType === "google";
        if (isGoogleLogin) {
          let tokenData = localStorage.getItem("googleToken");
          let token = null;
          try {
            token = JSON.parse(tokenData)?.credential;
          } catch (error) {}
          // signIn();

          googleLoginHandler();

          // if (token) {
          //   localStorage.setItem("GoogleMeetToken", tokenData);
          //   let payload = { ...event };
          //   payload.isGoogleMeetMeeting = true;
          //   payload.isMSTeamMeeting = false;

          //   handleMeeting(payload);
          // } else {
          //   setOpen({ type: "isGoogleMeetMeeting", isOpen: true, value });
          // }
        } else {
          let tokenData = localStorage.getItem("GoogleMeetToken");
          let token = null;

          if (tokenData) {
            try {
              token = JSON.parse(tokenData)?.credential;
            } catch (error) {}
          }

          if (token) {
            // let payload = { ...event };
            // payload.isGoogleMeetMeeting = true;
            // payload.isMSTeamMeeting = false;

            // handleMeeting(payload);
            // signIn();

            googleLoginHandler();
          } else {
            setOpen({ type: "isGoogleMeetMeeting", isOpen: true, value });
          }
        }
      }
    } else {
      let payload = { ...event };
      payload[type] = value;
      if (!payload.isGoogleMeetMeeting) {
        payload.availability = "PUBLIC";
      }

      handleMeeting(payload);
    }
  };

  return (
    <>
      <Dialog
        cancelButton={commonTranslation.cancel}
        confirmButton={eventTranslation.signIn}
        open={open.isOpen}
        onCancel={() => {
          setOpen({ isOpen: false, type: null, value: false });
        }}
        onConfirm={() => {
          setOpen((prevData) => ({ ...prevData, isOpen: false }));
          if (open.type === "isMSTeamMeeting") {
            handleMSLogin({ type: "isMSTeamMeeting", value: true });
          } else if (open.type === "isGoogleMeetMeeting") {
            // signIn();

            googleLoginHandler();
          }
        }}
        closeOnOutsideClick={false}
        content={
          <div>
            <Flex wrap>
              {open.type === "isMSTeamMeeting"
                ? eventTranslation.MSTeamsMeeting.signInConfirmation
                : eventTranslation.googleMeetMeeting.signInConfirmation}
            </Flex>
          </div>
        }
        header={eventTranslation.signIn}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setOpen({ isOpen: false, type: null, value: false });
          },
        }}
      />
      <div style={{ marginBottom: 24 }}>
        <Flex wrap style={{ rowGap: "10px", columnGap: "10px" }}>
          <Flex>
            <Switch
              disabled={props.isLoading}
              checked={!!event.isMSTeamMeeting}
              onChange={(value) => switchHandler("isMSTeamMeeting", value)}
            />{" "}
            &nbsp;
            <span>{eventTranslation.MSTeamsMeeting.header}</span>
            {!!(event.meetingURL && event.isMSTeamMeeting) && (
              <>
                &nbsp; &nbsp; &nbsp;
                <a href={event.meetingURL} target="_blank">
                  {eventTranslation.MSTeamsMeeting.link}
                </a>
              </>
            )}
          </Flex>
          <Flex>
            <Switch
              // disabled={!loaded || props.isLoading}
              disabled={props.isLoading}
              checked={!!event.isGoogleMeetMeeting}
              onChange={(value) => switchHandler("isGoogleMeetMeeting", value)}
            />{" "}
            &nbsp;
            <span>{eventTranslation.googleMeetMeeting.header}</span>
            {!!(event.googleMeetURL && event.isGoogleMeetMeeting) && (
              <>
                &nbsp; &nbsp; &nbsp;
                <a href={event.googleMeetURL} target="_blank">
                  {eventTranslation.MSTeamsMeeting.link}
                </a>
              </>
            )}
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default withTranslation()(MSTeamsLogin);
