import * as microsoftTeams from "@microsoft/teams-js";
import axios from "axios";
import React, { createRef, useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Dialog, Loader } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { dCred } from "./Config";
import { callMsGraph } from "./msal-auth/graph";
import { loginRequest } from "./msal-auth/msalAuth";
import PageRouter from "./Router";

import OnboardingProvider from "../context/OnboardingContext";
import Auth from "../pages/Auth/Auth";
import TabConfig from "../pages/TabConfig";
import ClosePopup from "./msal-auth/components/ClosePopup";
import ConsentPopup from "./msal-auth/components/ConsentPopup";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
// react-slick css
import { useGoogleLogout } from "react-google-login";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Header from "../components/Header/Header";
import SwitchDB from "../pages/Auth/SwitchDB";
import Stud from "../pages/Stud/Stud";
import "./App.css";
import { jwtDecode } from "jwt-decode";
import { ChannelModalProvider } from "../context/ChannelModalContext";
import { GlobalContextProvider } from "../GlobalContextProvider";

const App = () => {
  const { t } = useTranslation();

  const { instance, accounts, inProgress } = useMsal();

  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const [noDbFound, setNoDbFound] = useState(false);
  const [insideTeam, setInsideTeam] = useState(false);
  const [context, setContext] = useState(null);
  const [pop, setPop] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [nonSubscriber, setNonSubscriber] = useState(false);
  const [inactiveParent, setInactiveParent] = useState(false);
  const [db, setDb] = useState("");
  const [preVersion] = React.useState(
    JSON.parse(process.env.REACT_APP_PRE_VERSION)
  );
  const [isSiteEmbeded, setIsSiteEmbede] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [selectedExtension, setSelectedExtension] = useState({});
  const [eventPermissions, setEventPermissions] = useState({
    bulkCreateWithAi: false,
    bulkCreateWithExcel: false,
  });
  const [isInTeams, setIsInTeams] = useState(false);
  const [selectedTab, setSelectedTab] = useState("attendance");
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [openWorkSpace, setOpenWorkSpace] = useState(false);
  const [isShowControl, setIsShowControl] = useState(window.innerWidth > 767);
  const [isMobileViewFiltersOpen, setIsMobileViewFiltersOpen] = useState(false);
  const [isMobileDashboardMenuOpen, setIsMobileDashboardMenuOpen] =
    useState(false);
  const [openIsFilterDialog, setOpenIsFilterDialog] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [addEventCategory, setAddEventCategory] = useState({
    isEdit: false,
    title: "",
  });
  const [addEventsTag, setAddEventsTag] = useState({
    isEdit: false,
    title: "",
  });
  const [addSkillForProfile, setAddSkillForProfile] = useState({
    isEdit: false,
    title: "",
    category: "",
  });
  const [addInterestForProfile, setAddInterestForProfile] = useState({
    isEdit: false,
    title: "",
    category: "",
  });
  const [updateProfileImage, setUpdateProfileImage] = useState("");
  const [isWBFullScreen, setIsWBFullScreen] = useState(false);

  // Select all iframe elements
  const iframes = document.querySelectorAll("iframe");

  // Iterate and remove if they have no class attribute
  iframes.forEach((iframe) => {
    if (!iframe.className || iframe.className.trim() === "") {
      iframe.remove();
    }
  });

  const iframeRef = createRef(null);

  const signOut = () => {
    google.accounts.id.disableAutoSelect(); // Disable auto select after signing out
    // Revoke the Google login token
    google.accounts.id.revoke(localStorage.getItem("googleToken"), () => {
      // After revoking, clear the token from local storage and redirect
      localStorage.removeItem("googleToken");
      window.location.href = "/auth"; // Or wherever you want to redirect
    });
  };

  // get user location
  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const { latitude, longitude } = position.coords;
  //         localStorage.setItem(
  //           "coordinates",
  //           JSON.stringify({ latitude, longitude })
  //         );
  //       },
  //       (err) => {
  //         console.error(err.message);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by your browser");
  //   }
  // }, []);


  useEffect(() => {
    if (accessToken) {
      if (isFirstTime && window.location.pathname !== "/start-up/info") {
        window.location.href = "/start-up/info";
      }
    }
  }, [accessToken, isFirstTime]);

  const setGoogleData = async (res) => {
    const fetchUserProfile = async (accessToken) => {
      const response = await fetch(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }

      const userInfo = await response.json();
      return userInfo; // Returns user info including email, name, picture, etc.
    };

    const accessToken = res.access_token;

    // Fetch user profile using the access token
    const userInfo = await fetchUserProfile(accessToken);
    setGraphData({
      givenName: userInfo.given_name,
      displayName: userInfo.given_name + userInfo.family_name,
      mail: userInfo.email,
      surname: userInfo.family_name,
      userPrincipalName: userInfo.email,
    });
    setAccessToken(accessToken);
  };

  const handleLogin = (type, res) => {
    if (type === "google") {
      localStorage.setItem("googleToken", JSON.stringify(res));
      localStorage.removeItem("MSTeamsToken");
      setGoogleData(res);
    } else {
      localStorage.removeItem("googleToken");
      localStorage.removeItem("GoogleMeetToken");
      if (context && context.hostClientType !== "web") {
        microsoftTeams.authentication.authenticate({
          url: window.location.origin + "/auth-start",
          width: 600,
          height: 535,
          successCallback: (result) => {
            callGraphFromClient(result);
            callMsGraph(result).then((res) => {
              setGraphData(res);
              setAccessToken(result);
            });
          },
          failureCallback: (reason) => {
            console.error(reason);
          },
        });
      } else {
        if (window.parent !== window) {
          instance.loginPopup(loginRequest).catch((e) => {
            console.error(e);
          });
        } else {
          instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
          });
        }
      }
    }
  };

  const token = JSON.parse(localStorage.getItem("googleToken"));

  const handleLogout = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage("clearData", "*");
    }

    if (token) {
      signOut();
    } else {
      if (window.parent !== window) {
        localStorage.clear();
        location.reload();
      } else {
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      }
    }
  };

  const getTeamContext = () => {
    microsoftTeams.getContext((context) => {
      document
        .getElementsByTagName("body")[0]
        .classList.add(context?.theme ? context?.theme : "");
      if (context) {
        setInsideTeam(true);
        setContext(context);
      }
    });
  };

  const getRBACData = () => {
    const slug = user.slug;
    const token = user.accessToken;
    const END_POINT = process.env.REACT_APP_EP_URL;
    if (user?.role?.toLowerCase() === "superadmin") {
      setEventPermissions({
        bulkCreateWithAi: true,
        bulkCreateWithExcel: true,
      });
    } else if (slug) {
      axios
        .get(`${END_POINT}/api/rbac/get-rbac-data${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          if (data?.data?.length) {
            let eventPermissions = {
              bulkCreateWithAi: false,
              bulkCreateWithExcel: false,
            };
            const eventsOptions = data.data.filter(
              (data) => data.section === "events"
            );
            const aiCreationOption = eventsOptions.find(
              (option) => Number(option.id) === 11
            );
            const excelCreationOption = eventsOptions.find(
              (option) => Number(option.id) === 12
            );
            eventPermissions.bulkCreateWithAi =
              aiCreationOption[user.role.toLowerCase()];
            eventPermissions.bulkCreateWithExcel =
              excelCreationOption[user.role.toLowerCase()];
            setEventPermissions(eventPermissions);
          }
        })
        .catch((err) => {
          console.log("error from getRBACData", err);
        });
    }
  };

  useEffect(() => {
    if (user) {
      getRBACData();
    }
  }, [user]);
  useEffect(() => {
    getTeamContext();
    if (token) {
      setGoogleData(token);
    }

    let isEmbeded = iframeCheckHandler();
    setIsSiteEmbede(isEmbeded);
  }, []);

  useEffect(() => {
    // Initialize the Microsoft Teams SDK and check if the app is in Teams
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext((context) => {
        if (context) {
          setIsInTeams(true); // App is running inside Teams
        }
      });
    });
  }, []);

  function iframeCheckHandler() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return false;
    }
  }
  const callGraphFromClient = async (acctoken) => {
    let graphPhotoEndpoint = `https://graph.microsoft.com/v1.0/me/photo/$value`;
    let graphRequestParams = {
      method: "GET",
      headers: {
        "Content-Type": "image/jpg",
        Authorization: `Bearer ${acctoken}`,
      },
    };

    let response = await fetch(graphPhotoEndpoint, graphRequestParams).catch(
      (err) => {
        console.error("Unhandled fetch error: ", err);
      }
    );
    if (!response.ok) {
      console.error("ERROR: ", response);
    }

    let imageBlog = await response.blob().catch((err) => {
      toast.error(err?.response?.data?.message);
      console.error("Unhandled fetch error: ", err);
    }); //Get image data as raw binary data

    setUserProfile(URL.createObjectURL(imageBlog));
    //Convert binary data to an image URL and set the url in state
  };

  const getUserdata = () => {
    if (accounts && accounts.length) {
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenRedirect(accessTokenRequest)
              .then((response) => {
                setAccessToken(response.accessToken);
              })
              .catch((error) => {
                console.error(error);
              });
          } else if (error instanceof BrowserAuthError) {
            instance
              .acquireTokenRedirect(accessTokenRequest)
              .then((response) => {
                setAccessToken(response.accessToken);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    }
  };

  const getPendingGptCreditRequest = async (user) => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_EP_URL}/api/credit/get-credit-request-by-status${user.slug}&TeacherEmailID=${user.mail}&status=pending`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios.request(config);
      if (Object?.keys(response.data.result)?.length) {
        setUser((prev) => ({
          ...prev,
          isRequestedForGptCredit: true,
        }));
      }
    } catch (error) {
      console.log("error from getPendingGptCreditRequest", error.message);
    }
  };

  useEffect(() => {
    if (accessToken) {
      if (accounts.length) {
        setUser({ ...accounts[0], tokenType: "Bearer" });
        getDb({ ...accounts[0], tokenType: "Bearer" });
      } else {
        setUser({
          username: graphData?.mail,
          tokenType: "Bearer",
          tenantId: "12d105e3-e51a-4c7f-843b-50046ad42224",
        });
        getDb({
          username: graphData?.mail,
          tokenType: "Bearer",
          tenantId: "12d105e3-e51a-4c7f-843b-50046ad42224",
        });
      }
    }
  }, [accessToken]);

  const getDb = (userData) => {
    axios
      .get(
        `${process.env.REACT_APP_EP_URL}/api/user/get-user-datbase/${
          userData.username ? userData.username : userData.mail
        }`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(async (res) => {
        const multiDB = res.data.result;
        if (multiDB.length === 0) {
          setDb(false);
          setNonSubscriber(true);
          return;
        }
        const selectedDB = multiDB.find((x) => x.selectedDB === 1);
        if (selectedDB) {
          setDb(false);
          const data = {
            db: selectedDB.database,
            schoolInfo: selectedDB.schoolInfo,
          };
          getDBdata(data);
        } else {
          setDb(true);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.error(err);
        if (err.response?.status === 400) {
          setNonSubscriber(true);
          return;
        }
        setNoDbFound(true);
        setPop({
          head: t("errors")[4],
          msg: t("errors")[5],
        });
      });
  };

  const switchDb = () => {
    setDb(true);
  };

  const switchDBHandler = async (db, mail) => {
    setDb(false);
    const slug = dCred.param + db;

    const res = await axios.get(
      `${process.env.REACT_APP_EP_URL}/api/user/update-selected-db${slug}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    res &&
      getDb({
        username: mail,
        tokenType: "Bearer",
        tenantId: "12d105e3-e51a-4c7f-843b-50046ad42224",
      });
  };

  const getSettingsData = async (slug) => {
    await axios
      .get(`${process.env.REACT_APP_EP_URL}/api/setting${slug}`)
      .then((res) => {
        if (res?.data?.data[0]) {
          setSettings(res.data.data[0]);
        } else {
          setSettings(null);
        }
      })
      .catch((error) => setSettings(null));
  };

  const getDBdata = async (dbData) => {
    const database = dbData?.db || null;
    if (database) {
      let schoolInfo = dbData?.schoolInfo || null;
      const slug = dCred.param + database;

      let userinfo = {};
      getSettingsData(slug);
      await axios
        .get(
          `${process.env.REACT_APP_EP_URL}/api/user/generate-shared-access-signature${slug}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((res) => {
          userinfo = {
            ...userinfo,
            SASToken: "?" + res.data.SASToken,
            slug: dCred.param + database,
            schoolInfo: schoolInfo,
          };

          const msg = JSON.parse(localStorage.getItem("msg"));
          localStorage.setItem(
            "msg",
            JSON.stringify({
              ...msg,
              user: { ...user, SASToken: "?" + res.data.SASToken },
            })
          );
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          console.error(err);
        });

      await axios
        .get(
          `${process.env.REACT_APP_EP_URL}/api/user/check-user-role${slug}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((res) => {
          const { image, credit, isTutor, storage } = res.data;
          if (res?.data?.role.toLowerCase() === "parent") {
            !res?.data?.isActive && setInactiveParent(true);
          }
          const role = {
            role: res.data.role,
            actualRole: res.data.actualRole,
          };
          userinfo = {
            ...userinfo,
            ...role,
            ...res.data.user,
            storage,
            credit,
            isTutor,
            uProfile: image ? image + userinfo?.SASToken : null,
            displayName: res.data.name,
          };
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          if (err.response?.status === 400) {
            setNonSubscriber(true);
            return;
          }
          setNoDbFound(true);
          setPop({
            head: t("errors")[0],
            msg: t("errors")[1],
          });
        });

      await axios
        .post(
          `${process.env.REACT_APP_EP_URL}/api/startup/get-DB-data${slug}`,
          {
            tenantId: "12d105e3-e51a-4c7f-843b-50046ad42224",
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((res) => {
          setDb(false);
          localStorage.setItem(
            "subscription",
            JSON.stringify(res.data.subscription)
          );
          userinfo = {
            ...userinfo,
            dbData: {
              ...res.data.result,
              ...res.data.expDate,
              subscription: res.data.subscription,
              dayRemaining: res.data.dayRemaining,
            },
          };
          setIsFirstTime(res.data.result.isFirstTime);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          console.error(err);
        });

      const res = await axios
        .get(`${process.env.REACT_APP_EP_URL}/api/extensions${slug}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          if (response.data.result && response.data.result.length > 0) {
            setSelectedExtension(response.data.result[0]);
          }
        });
      if (accounts[0]) {
        setUser({
          ...user,
          ...userinfo,
          ...accounts[0],
          authType: "microsoft",
          UrlPhoto: userProfile ? userProfile : userinfo?.uProfile,
        });
      } else {
        setUser({
          ...user,
          ...userinfo,
          name: user?.displayName ? user?.displayName : graphData?.givenName,
          authType: "google",
          UrlPhoto: userProfile ? userProfile : userinfo?.uProfile,
        });
      }
      await getPendingGptCreditRequest(userinfo);
    } else {
      setNoDbFound(true);
      setPop({
        head: t("errors")[2],
        msg: t("errors")[3],
      });
    }
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      getUserdata();
    }
  }, [instance, accounts, inProgress]);

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (body) {
      body.className = `mode ${context?.theme ?? ""}`;
    }
  }, [context?.theme]);

  const refreshUserProfileData = async () => {
    const slug = user.slug;
    await axios
      .get(`${process.env.REACT_APP_EP_URL}/api/user/check-user-role${slug}`, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        const { image, name, user: userData, credit } = res.data;
        if (res?.data?.role.toLowerCase() === "parent") {
          !res?.data?.isActive && setInactiveParent(true);
        }
        const formattedName = name
          ? name
          : userData?.displayName
          ? userData?.displayName
          : user?.name;
        setUser((prev) => ({
          ...prev,
          name: formattedName,
          displayName: formattedName,
          UrlPhoto: image ? image + user?.SASToken : null,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <OnboardingProvider>
      <GlobalContextProvider
        user={{
          ...user,
          accessToken,
          ...context,
          insideTeam,
          mode: context?.theme,
        }}
      >
        <div id="themeDiv" style={{ height: "100%" }}>
          <Router>
            <Dialog
              open={noDbFound}
              onConfirm={() => handleLogout()}
              confirmButton={t("buttons")[0]}
              content={pop?.msg}
              header={pop?.head}
            />
            <Switch>
              <Route exact path="/config" component={TabConfig} />
              {user?.actualRole === "Student" && (
                <Route
                  exact
                  path="/stud"
                  render={() => (
                    <Stud data={{ ...user, accessToken, settings }} />
                  )}
                />
              )}
              {db && (
                <Route
                  exact
                  path="/switch-db"
                  render={() => (
                    <SwitchDB
                      token={accessToken}
                      switchDB={switchDBHandler}
                      handleLogout={handleLogout}
                    />
                  )}
                />
              )}

              {graphData || accounts[0] ? (
                nonSubscriber ? (
                  <Auth
                    login={handleLogin}
                    inactiveParent={false}
                    isNonSubscriber={true}
                    logout={handleLogout}
                    user={user?.name}
                    settings={settings}
                    insideTeam={insideTeam}
                  />
                ) : inactiveParent ? (
                  <Auth
                    login={handleLogin}
                    inactiveParent={true}
                    isNonSubscriber={false}
                    logout={handleLogout}
                    user={user?.name}
                    settings={settings}
                    insideTeam={insideTeam}
                  />
                ) : db ? (
                  <Redirect to="/switch-db" />
                ) : user?.slug ? (
                  <>
                    {selectedTab !== "startup" && (
                    <ChannelModalProvider>
                        <Header
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          user={user}
                          logout={handleLogout}
                          getDb={getDb.bind()}
                          switchDb={switchDb}
                          isEditProfile={isEditProfile}
                          selectedExtension={selectedExtension}
                          openWorkSpace={openWorkSpace}
                          toggleControl={() => {
                            setIsShowControl(!isShowControl);
                          }}
                          handleClickOpen={() => {
                            setIsMobileViewFiltersOpen(true);
                          }}
                          openDashboardMenu={() => {
                            setIsMobileDashboardMenuOpen(true);
                          }}
                          openFilterDialog={() => {
                            setOpenIsFilterDialog(true);
                          }}
                          isNew={isNew}
                          addEventCategory={addEventCategory}
                          addEventsTag={addEventsTag}
                          addSkillForProfile={addSkillForProfile}
                          addInterestForProfile={addInterestForProfile}
                          setOpenWorkSpace={() => {
                            setOpenWorkSpace(false);
                          }}
                          updateProfileImage={updateProfileImage}
                          isWBFullScreen={isWBFullScreen}
                          setIsWBFullScreen={setIsWBFullScreen}
                        />
                    </ChannelModalProvider>
                    )}
                    <PageRouter
                      insideTeam={insideTeam}
                      user={{
                        ...user,
                        accessToken,
                        ...context,
                        insideTeam,
                        mode: context?.theme,
                      }}
                      settings={settings}
                      setUser={setUser}
                      iframeRef={iframeRef}
                      logout={handleLogout}
                      getDb={() => getDb(user)}
                      switchDb={switchDb}
                      isSiteEmbeded={isSiteEmbeded}
                      refreshUserProfileData={refreshUserProfileData}
                      selectedExtension={selectedExtension}
                      setSelectedExtension={setSelectedExtension}
                      eventPermissions={eventPermissions}
                      setEventPermissions={setEventPermissions}
                      isInTeams={isInTeams}
                      isEditProfile={isEditProfile}
                      setIsEditProfile={setIsEditProfile}
                      openWorkSpace={openWorkSpace}
                      setOpenWorkSpace={setOpenWorkSpace}
                      isShowControl={isShowControl}
                      isMobileViewFiltersOpen={isMobileViewFiltersOpen}
                      setIsMobileViewFiltersOpen={setIsMobileViewFiltersOpen}
                      isMobileDashboardMenuOpen={isMobileDashboardMenuOpen}
                      setIsMobileDashboardMenuOpen={
                        setIsMobileDashboardMenuOpen
                      }
                      setOpenIsFilterDialog={setOpenIsFilterDialog}
                      openIsFilterDialog={openIsFilterDialog}
                      isNew={isNew}
                      setIsNew={setIsNew}
                      addEventCategory={addEventCategory}
                      setAddEventCategory={setAddEventCategory}
                      addEventsTag={addEventsTag}
                      setAddEventsTag={setAddEventsTag}
                      addSkillForProfile={addSkillForProfile}
                      setAddSkillForProfile={setAddSkillForProfile}
                      addInterestForProfile={addInterestForProfile}
                      setAddInterestForProfile={setAddInterestForProfile}
                      setSelectedTab={setSelectedTab}
                      setUpdateProfileImage={setUpdateProfileImage}
                      isWBFullScreen={isWBFullScreen}
                      setIsWBFullScreen={setIsWBFullScreen}
                    />
                  </>
                ) : (
                  <Loader size="medium" style={{ paddingTop: "50vh" }} />
                )
              ) : (
                <>
                  <AuthenticatedTemplate>
                    <Loader size="medium" style={{ paddingTop: "50vh" }} />
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <Route exact path="/auth-start" component={ConsentPopup} />
                    <Route exact path="/auth-end" component={ClosePopup} />
                    <Route exact path="/auth">
                      <Auth
                        login={handleLogin}
                        logout={handleLogout}
                        user={user?.name}
                        insideTeam={insideTeam}
                        settings={settings}
                      />
                    </Route>
                    <Route path="/*">
                      <Redirect to="auth" />
                    </Route>
                    <Route path="/*/*">
                      <Redirect to="/auth" />
                    </Route>
                  </UnauthenticatedTemplate>
                </>
              )}
            </Switch>
          </Router>
        </div>
        {preVersion && (
          <div
            className="version-info text-muted-100"
            title={`${user?.actualRole}\n${user?.displayName}\n${user?.mail}\n${user?.dbData?.tenantDB}`}
          >
            {`${preVersion ? "PRE" : "PROD"} — ${
              process.env.REACT_APP_VERSION
            } — ${user?.dbData?.tenantDB}`}
          </div>
        )}
        <ToastContainer
          // position="top-right"
          hideProgressBar={true}
          autoClose={5000}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          rtl={false}
          theme="light"
        />
      </GlobalContextProvider>
    </OnboardingProvider>
  );
};

export default App;
