import {
  Button,
  CloseIcon,
  Dialog,
  Flex,
  Text,
  TextArea,
} from "@fluentui/react-northstar";
import React from "react";
import { useTutorRecommendations } from "../../../../context/TutorRecommendationsContext";
import "./GetRecommendationsDialog.scss";

const MAX_CHARACTERS = 500;

const GetRecommendationsDialog = () => {
  const {
    isOpenGetRecommendationsDialog,
    setIsOpenGetRecommendationsDialog,
    topic,
    setTopic,
    generateRecommendationByTopic,
    isLoadingGenerateRecommendationByTopic,
  } = useTutorRecommendations();

  return (
    <Dialog
      open={isOpenGetRecommendationsDialog}
      className="get-recommendations-dialog"
      header="Get Recommendations"
      content={
        <Flex column gap="gap.medium">
          <TextArea
            fluid
            placeholder="Enter topic you want to learn"
            aria-label="topic"
            value={topic}
            onChange={(_, { value }) => setTopic(value)}
            maxLength={MAX_CHARACTERS}
            rows={12}
          />
          <Text
            size="small"
            color={topic.length >= MAX_CHARACTERS ? "red" : "gray"}
            align="end"
          >
            {topic.length} / {MAX_CHARACTERS}
          </Text>
          <Button
            content="Get Recommendation"
            primary
            style={{
              width: "fit-content",
              alignSelf: "flex-end",
              borderRadius: "5px",
            }}
            loading={isLoadingGenerateRecommendationByTopic}
            disabled={
              isLoadingGenerateRecommendationByTopic || topic.length === 0
            }
            onClick={() => generateRecommendationByTopic(topic)}
          />
        </Flex>
      }
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: () => {
          setIsOpenGetRecommendationsDialog(false);
          setTopic("");
        },
      }}
    />
  );
};

export default GetRecommendationsDialog;
