import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OnDemandChannelsModal } from "../OnDemandChannels";
import { useChannelModal } from "../../../context/ChannelModalContext";

export const AudioChannelOptionModal = ({ user }) => {
  const [selectedChannel, setSelectedChannel] = useState("general");
  const { modals, closeModal, switchModals } = useChannelModal();

  const handleClose = () => {
    closeModal("audioChannel");
  };

  const handleOpenOnDemandChannels = () => {
    switchModals("audioChannel", "onDemand");
  };

  return (
    <>
      <Dialog
        open={modals.audioChannel}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "8px",
            m: 2,
            fontFamily: "Karla, sans-serif",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #E5E7EB",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "1.125rem", fontWeight: 500 }}
          >
            Audio Channels options
          </Typography>
          <IconButton
            edge="end"
            onClick={handleClose}
            aria-label="close"
            sx={{ color: "gray" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 2 }}>
          <Box sx={{ my: 2 }}>
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              Preferred Channel
            </Typography>
            <RadioGroup
              value={selectedChannel}
              onChange={(event) => setSelectedChannel(event.target.value)}
              name="channel-options"
            >
              <FormControlLabel
                value="general"
                control={
                  <Radio
                    sx={{
                      color: "#494B83",
                      "&.Mui-checked": { color: "#494B83" },
                    }}
                  />
                }
                label="General"
              />
              <FormControlLabel
                value="whiteboard"
                control={
                  <Radio
                    sx={{
                      color: "#494B83",
                      "&.Mui-checked": { color: "#494B83" },
                    }}
                  />
                }
                label="Whiteboard"
              />
            </RadioGroup>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "#494B83",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#3d3f6d",
                },
              }}
              onClick={handleOpenOnDemandChannels}
            >
              On-demand Channels
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClose}
              sx={{
                backgroundColor: "#494B83",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#3d3f6d",
                },
              }}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <OnDemandChannelsModal user={user} />
    </>
  );
};
