import { useTranslation } from "react-i18next";
import Tabs from "../Events/Tabs/Tabs";
import { Collection } from "./collections";
import ApproveCollection from "./approveCollection";
import UnPublishedCollection from "./unPublishedCollection";
import RejectedCollections from "./rejectedCollections";
import TobeApproveCollection from "./TobeApproveCollection";
import { FilterProvider } from "./contexts";

export const Collections = ({ user, isTabletOrMobileView }) => {
  const { t } = useTranslation();

  return (
    <FilterProvider>
      <Tabs currentTab={"To be approved"}>
        <div label="To be approved">
          <TobeApproveCollection
            user={user}
            isTabletOrMobileView={isTabletOrMobileView}
          />
        </div>
        <div label="Published">
          <ApproveCollection
            user={user}
            isTabletOrMobileView={isTabletOrMobileView}
          />
        </div>
        <div label="UnPublished">
          <UnPublishedCollection
            user={user}
            isTabletOrMobileView={isTabletOrMobileView}
          />
        </div>
        <div label="Rejected">
          <RejectedCollections
            user={user}
            isTabletOrMobileView={isTabletOrMobileView}
          />
        </div>
        <div label={"All"}>
          <Collection user={user} isTabletOrMobileView={isTabletOrMobileView} />
        </div>
        <div className="d-none"></div>
      </Tabs>
    </FilterProvider>
  );
};
